import {FC} from "react";
import {ISvgProps} from "../../../types/svg";

const BillingIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.49988 17.624C1.49988 18.3202 1.77644 18.9879 2.26872 19.4802C2.76101 19.9725 3.42868 20.249 4.12488 20.249H19.8749C20.5711 20.249 21.2387 19.9725 21.731 19.4802C22.2233 18.9879 22.4999 18.3202 22.4999 17.624V10.4053H1.49988V17.624ZM4.59363 14.0615C4.59363 13.6886 4.74179 13.3309 5.00551 13.0672C5.26923 12.8034 5.62692 12.6553 5.99988 12.6553H8.24988C8.62284 12.6553 8.98052 12.8034 9.24425 13.0672C9.50797 13.3309 9.65613 13.6886 9.65613 14.0615V14.999C9.65613 15.372 9.50797 15.7297 9.24425 15.9934C8.98052 16.2571 8.62284 16.4053 8.24988 16.4053H5.99988C5.62692 16.4053 5.26923 16.2571 5.00551 15.9934C4.74179 15.7297 4.59363 15.372 4.59363 14.999V14.0615Z"
      />
      <path
        d="M19.8749 3.74902H4.12488C3.42868 3.74902 2.76101 4.02559 2.26872 4.51787C1.77644 5.01015 1.49988 5.67783 1.49988 6.37402V7.59277H22.4999V6.37402C22.4999 5.67783 22.2233 5.01015 21.731 4.51787C21.2387 4.02559 20.5711 3.74902 19.8749 3.74902Z"
      />
    </svg>
  );
}

export default BillingIcon;
