import {FC} from "react";
import {ISvgProps} from "../../../types/svg";

const PropertyIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7575 4.87216L6.75746 6.37216C6.3123 6.48345 6 6.88343 6 7.3423V20H14V9.99997V7.99997V5.8423C14 5.19173 13.3886 4.71437 12.7575 4.87216ZM16 9.99997V20H18V11C18 10.4477 17.5523 9.99997 17 9.99997H16ZM20 22H21C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20H20V11C20 9.34312 18.6569 7.99997 17 7.99997H16V5.8423C16 3.89059 14.1658 2.45851 12.2724 2.93187L6.27239 4.43187C4.93689 4.76575 4 5.9657 4 7.3423V20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22H4H6H14H16H18H20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 17C8 16.4477 8.44772 16 9 16H11C11.5523 16 12 16.4477 12 17C12 17.5523 11.5523 18 11 18H9C8.44772 18 8 17.5523 8 17Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13C8 12.4477 8.44772 12 9 12H11C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H9C8.44772 14 8 13.5523 8 13Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9C8 8.44772 8.44772 8 9 8H11C11.5523 8 12 8.44772 12 9C12 9.55228 11.5523 10 11 10H9C8.44772 10 8 9.55228 8 9Z"
      />
    </svg>
  );
}

export default PropertyIcon;
