import { create } from "zustand";

export type TPropertyEditStoreStep = "list" | "disposition" | "parameters" | "photosAndDescription" | "features" | "priceAndRentalTerms" | "preview";
type TPropertyFlow = "create" | "edit";

interface IPropertyEditStore {
  flow: TPropertyFlow;
  setFlow: (newFlow: TPropertyFlow) => void;
  step: TPropertyEditStoreStep;
  setStep: (newStep: TPropertyEditStoreStep) => void;
}

const usePropertyEditStore = create<IPropertyEditStore>((set) => ({
  flow: 'edit',
  setFlow: (newFlow) => set((prevState) => ({
    ...prevState,
    flow: newFlow,
  })),
  step: "disposition",
  setStep: (newStep) => set((prevState) => ({
    ...prevState,
    step: newStep,
  })),
}));

export default usePropertyEditStore;
