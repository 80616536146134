import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const LogoutIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 25}
      height={height || 24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || "#808191"}
      strokeOpacity={opacity || 1}
    >
      <g>
        <path
          d="M12.8435 0C16.6175 0 20.1119 1.674 22.4291 4.4928C22.4952 4.5727 22.5444 4.66507 22.574 4.7644C22.6036 4.86373 22.6129 4.968 22.6014 5.071C22.5898 5.174 22.5577 5.27363 22.5069 5.36396C22.4561 5.45429 22.3876 5.53347 22.3055 5.5968C22.1385 5.72772 21.927 5.78842 21.716 5.76602C21.505 5.74361 21.311 5.63987 21.1751 5.4768C20.1619 4.24947 18.8889 3.26235 17.4479 2.58668C16.0069 1.91101 14.4339 1.56363 12.8423 1.5696C6.94434 1.5696 2.16234 6.24 2.16234 12C2.16234 17.76 6.94434 22.4304 12.8423 22.4304C14.4568 22.4366 16.0519 22.0792 17.5094 21.3847C18.9669 20.6902 20.2493 19.6765 21.2615 18.4188C21.3951 18.254 21.5875 18.1478 21.7981 18.1224C22.0086 18.0971 22.2208 18.1548 22.3895 18.2832C22.4723 18.3454 22.5417 18.4237 22.5937 18.5133C22.6456 18.6029 22.679 18.702 22.6919 18.8048C22.7047 18.9075 22.6968 19.0118 22.6686 19.1115C22.6404 19.2111 22.5924 19.3041 22.5275 19.3848C20.2139 22.2768 16.6727 24 12.8435 24C6.05514 24 0.555542 18.6276 0.555542 12C0.555542 5.3724 6.05634 0 12.8435 0ZM21.0731 8.592L24.3203 11.8404C24.6347 12.1536 24.6407 12.6564 24.3347 12.9636L21.1619 16.1352C21.0114 16.2825 20.8085 16.364 20.5979 16.3618C20.3872 16.3595 20.1861 16.2737 20.0387 16.1232C19.964 16.0503 19.9043 15.9633 19.8631 15.8673C19.822 15.7713 19.8002 15.6682 19.799 15.5637C19.7977 15.4593 19.8171 15.3556 19.856 15.2587C19.8949 15.1618 19.9525 15.0734 20.0255 14.9988L21.8819 13.1424H9.50994C9.40562 13.1437 9.30207 13.1244 9.2052 13.0856C9.10833 13.0469 9.02005 12.9894 8.94539 12.9165C8.87073 12.8437 8.81116 12.7568 8.77007 12.6609C8.72899 12.565 8.7072 12.4619 8.70594 12.3576C8.70594 11.9232 9.06594 11.5716 9.50994 11.5716H21.8327L19.9631 9.702C19.8125 9.55479 19.7264 9.35376 19.724 9.14313C19.7215 8.9325 19.8028 8.72951 19.9499 8.5788C20.1007 8.43164 20.3036 8.35035 20.5143 8.35283C20.7249 8.3553 20.9259 8.44133 21.0731 8.592Z"
        />
      </g>
    </svg>
  );
}

export default LogoutIcon;
