import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";
import { ICategoryFull, ICategorySchema } from "../../../common/types/dto/category";

export const getCategoryList = async () => {
  const response: AxiosResponse<IResponse<ICategoryFull[]>> = await axiosInstance.get("/category/list");
  return response.data.data;
}

export const getCategorySchemaById = async (categoryId: string) => {
  const response: AxiosResponse<IResponse<ICategorySchema>> = await axiosInstance.get(`/category/${categoryId}/schema`);
  return response.data.data;
}
