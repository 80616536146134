import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";

import { getUsersList } from "../../api/services/user/requests";

import { IUser } from "../../common/types/dto/user";

import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalModalStore from "../../common/stores/useGlobalModalStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import Input from "../../common/components/Input/Input";
import Pagination from "../../common/components/Pagination/Pagination";

import Transaction from "../../features/transactions/components/Transaction";
import DecreaseModal from "../../features/transactions/components/modals/DecreaseModal/DecreaseModal";
import IncreaseModal from "../../features/transactions/components/modals/IncreaseModal/IncreaseModal";

import MagnifyingGlass from "../../common/assets/icons/MagnifyingGlass";

const USERS_PER_PAGE = 20;

//todo: make more components?
const TransactionsPage = () => {
  const [page, setPage] = useState(1);
  const [showTransactions, setShowTransactions] = useState<{ [id: string]: boolean }>({});

  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const { control, watch } = useForm<{
    search: string,
  }>({
    defaultValues: {
      search: "",
    }
  });

  const search = watch("search");

  const { data } = useQuery(["agentsList", search.length > 2 ? search : '', page], async () => {
    const payload = {
      role: 'agent',
      ...search.length > 2 && { email: search },
      page,
      limit: USERS_PER_PAGE,
    };

    setLoader(true);
    const data = await getUsersList(payload);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    },
  });

  const toggleShowTransactions = (id: string) => {
    setShowTransactions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const openDecreaseModal = (id: string) => {
    setChildren(<DecreaseModal id={id} />)
    setIsOpen(true)
  };

  const openIncreaseModal = (id: string) => {
    setChildren(<IncreaseModal id={id} />)
    setIsOpen(true)
  };

  const agentsMap = data?.items?.map((agent: IUser) => {
    const { id, name, email, avatar, salePropertiesCount, rentPropertiesCount, balance } = agent
    const isShowing = showTransactions[id];
    return (
      <React.Fragment key={id}>
        <div className="flex items-center gap-[10px]">
          <div className="h-[36px] w-[36px]">
            {avatar?.url ? (
              <img
                src={avatar.url}
                width={36}
                height={36}
                alt="user icon"
                className="h-[36px] w-[36px] rounded-[12px] object-cover"
              />
            ) : (
              <div className="flex-center rounded-[12px] w-[36px] h-[36px] bg-blue text-white">{name?.charAt(0)}</div>
            )}
          </div>
          <div className="leading-[1.2] text-[14px]">
            <div className="text-[#2D3748] font-bold">{name}</div>
            <div className="text-[#718096]">{email}</div>
          </div>
        </div>
        <div className="text-center text-[14px] text-[#666666] leading-[1.2]">
          <div>{salePropertiesCount} {t('for_sell')}</div>
          <div>{rentPropertiesCount} {t('for_rent')}</div>
        </div>
        <div className="flex-center gap-[14px] text-[18px] text-[#2D3748] font-bold">
          <button onClick={() => openDecreaseModal(id)} className="flex-center w-[18px] h-[18px] rounded-full bg-[#006CFB] text-[#fff] pb-[3px]">-</button>
          <div>{balance}</div>
          <button onClick={() => openIncreaseModal(id)} className="flex-center w-[18px] h-[18px] rounded-full bg-[#006CFB] text-[#fff] pl-[1px] pb-[2px]">+</button>
        </div>
        <div className="text-right">
          <button onClick={() => toggleShowTransactions(id)} className="text-[12px] text-[#006CFB] font-bold whitespace-nowrap">{isShowing ? t('hide_transactions') : t('show_transactions')}</button>
        </div>
        {isShowing ? (
          <div className="col-span-4 border border-[#E2E8F0] rounded-[10px] py-[10px] px-[20px]">
            <div className="grid grid-cols-6 gap-2">
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('method')}</div>
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('transaction_id')}</div>
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('date')}</div>
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('amount')}</div>
              <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('Publications')}</div>
              <div />
            </div>
            <Transaction cardType="visa" card="Card Visa ** 4432" id={153231} date="05.10.2024" amount={2138} publications={55} />
            <Transaction cardType="master" card="Card Visa ** 4432" id={153231} date="05.10.2024" amount={2138} publications={55} />
          </div>
        ) : <div className="col-span-4 border-t border-[#E2E8F0]"/>}
      </React.Fragment>
    )
  });

  const totalPages = !!data?.total && data.total > data.limit && Math.ceil(data.total / USERS_PER_PAGE);

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <div className="flex items-center justify-between gap-[20px]">
        <div />
        <div className="relative w-[317px]">
          <Input
            control={control}
            type="text"
            name="search"
            classnames="flex items-center w-full h-[38px] pl-[50px] pr-[10px] rounded-[8px] bg-[#F4F4F4]"
            placeholder={t('search_placeholder')}
            unstyled
          />
          <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2" />
        </div>
      </div>
      <div className="mt-[25px]">
        <CardWrapper className="p-[30px]">
          <div className="text-[#11142d] text-[18px] font-semibold mb-3">{t('transactions')}</div>
          <div className="grid grid-cols-[1fr_130px_130px_130px] gap-x-[50px] gap-y-3 items-center mb-6">
            <div className="text-[10px] text-[#A0AEC0] uppercase font-bold">{t('agent')}</div>
            <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('property_count')}</div>
            <div className="text-[10px] text-[#A0AEC0] uppercase font-bold text-center">{t('manage_balance')}</div>
            <div/>
            <div className="col-span-4 border-t border-[transparent]" />
            {agentsMap}
          </div>
          {totalPages && <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)}/>}
        </CardWrapper>
      </div>
    </div>
  );
}

export default TransactionsPage;
