import { useTranslation } from "react-i18next";

import { addPriceDelimiters } from "../../../common/utils/helpers";

interface IPropertyFieldsColumnProps {
  items?: {
    label: string;
    value: string | Record<string, string>;
    unit?: string;
  }[] | null;
  columnCount?: number;
}

const PropertyFieldsColumn = ({items, columnCount = 1}: IPropertyFieldsColumnProps) => {
  const { i18n } = useTranslation();

  const lang = i18n.language;

  if (!items) return null;
  return (
    <div className={`grid grid-cols-${columnCount} gap-[16px]`}>
      {items.filter(f => !!f.value).map((field, index) => {
        const isIsoDate = typeof field.value === 'string' && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(field.value);
        const isUnit = !!field?.unit;
        const isObject = typeof field.value === 'object' && field.value !== null;

        const formattedValue = isObject ? field.value[lang as keyof typeof field.value] : isIsoDate
          ? new Intl.DateTimeFormat('default', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(new Date(field.value as string))
          : isUnit ? addPriceDelimiters(field.value as string) : field.value;

        const displayValue = typeof formattedValue === 'string' || typeof formattedValue === 'number' ? formattedValue : '';

        return !!displayValue && (
          <div className="flex gap-[8px] leading-[1.2]" key={index}>
            <div className="flex-1 text-[#666]">{field.label}</div>
            <div className="flex-1 text-[#333]">{displayValue} {field.unit}</div>
          </div>
        );
      })}
    </div>
  );
}

export default PropertyFieldsColumn;
