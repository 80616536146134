import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ICategorySchemaGroupItem } from "../../../common/types/dto/category";
import { IPropertyField, ITag, TPropertyFieldSchema } from "../../../common/types/dto/property";

const usePropertyFieldsFromSchema = (fields?: IPropertyField[], schema?: ICategorySchemaGroupItem) => {
  const { i18n } = useTranslation();

  const lang = i18n.language;

  return useMemo(() => {
    if (!fields || !schema) return null;
    const schemaFieldIds = schema.fields.map((f: TPropertyFieldSchema) => f.id);
    const fieldsInSchema = fields.filter((f: IPropertyField) => schemaFieldIds.includes(f.field.id));

    const nonSanitized = fieldsInSchema.map((f: IPropertyField) => {
      switch (f.field.type) {
        case "TAG_SELECT": {
          return {
            label: f.field.label,
            value: f.tag?.label?.[lang as keyof typeof f.tag.label],
            unit: f.field.meta?.unit
          };
        }
        case "TAG_MULTI_SELECT": {
          return {
            label: f.field.label,
            value: f.tags.filter((t) => !!t.label?.[lang as keyof typeof t.label]).map((t) => t.label?.[lang as keyof typeof t.label]).join(", "),
            unit: f.field.meta?.unit
          };
        }
        default:
          return {
            label: f.field.label,
            value: f.value,
            unit: f.field.meta?.unit
          };
      }
    }) || [];

    return nonSanitized.filter(f => !!f.value).map(field => ({
      ...field,
      value: typeof field.value !== 'string' && typeof field.value !== 'object'
        ? String(field.value)
        : field.value,
    }));
  }, [fields, schema, lang]);
}

export default usePropertyFieldsFromSchema;
