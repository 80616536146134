import { FC, useRef } from "react";
import Slider from "react-slick";

import { IImage } from "../../../common/types/image";

import ChevronIcon from "../../../common/assets/icons/ChevronIcon";

const CustomPrevArrow = ({ onClick }: { onClick: (e: any) => void; }) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    onClick(e);
  }
  return (
    <div
      className={`opacity-0 group-hover:opacity-100 transition-opacity duration-500 absolute top-1/2 left-[18px] z-10 transform -translate-y-1/2 flex-center rounded-full cursor-pointer w-[40px] h-[40px] bg-[#8C8C8CD9]`}
      onClick={handleClick}
    >
      <ChevronIcon color="#fff" width={24} height={24} className="mr-0.5" />
    </div>
  );
};

const CustomNextArrow = ({ onClick }: { onClick: (e: any) => void; }) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    onClick(e);
  }
  return (
    <div
      className={`opacity-0 group-hover:opacity-100 transition-opacity duration-500 absolute top-1/2 right-[18px] z-10 transform -translate-y-1/2 flex-center rounded-full cursor-pointer w-[40px] h-[40px] bg-[#8C8C8CD9]`}
      onClick={handleClick}
    >
      <ChevronIcon color="#fff" width={24} height={24} className="transform rotate-180 ml-1" />
    </div>
  );
};

interface IPropertyCarouselProps {
  items?: IImage[];
}

const PropertyCarousel: FC<IPropertyCarouselProps> = ({ items}) => {
  let sliderRef = useRef<Slider | null>(null);

  const sliderSettings = {
    customPaging: (index: number) => !!items ? (
      <div className="h-[50px] w-[50px]">
        <img
          src={items[index]?.url}
          width={50}
          height={50}
          alt={index.toString()}
          className="h-[50px] w-[50px] object-cover"
        />
      </div>
    ) : <div />,
    dots: true,
    dotsClass: "!flex gap-[10px] overflow-auto",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    prevArrow: undefined,
    nextArrow: undefined,
  };

  const prevSlide = () => {
    sliderRef.current?.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current?.slickNext();
  };

  return (
    <div className="relative group slider-container">
      <div className="absolute w-full aspect-[2]">
        <CustomPrevArrow onClick={prevSlide} />
        <CustomNextArrow onClick={nextSlide} />
      </div>
      <Slider
        ref={(slider) => {
          sliderRef.current = slider;
        }}
        {...sliderSettings}
      >
        {items?.map((image) => (
          <div key={image.id} className="relative outline-none aspect-[2]">
            <img src={image.url} alt={image.name} className="w-full h-full object-cover rounded-[10px]"/>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default PropertyCarousel;
