import { FC } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import { getStatisticProperty } from "../../../api/services/statistics/requests";

import { IGetStatisticsProperty } from "../../../api/services/statistics/types";

import { EUserRole } from "../../../common/types/dto/user";

import useAuthStore from "../../../common/stores/useAuthStore";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";

interface IPropertyItemProps {
  title: string;
  count: number;
}

const PropertyItem: FC<IPropertyItemProps> = ({ title, count }) => {
  return (
    <CardWrapper>
      <div className="text-[#808191] text-[14px] font-medium">{title}</div>
      <div className="text-[#11142d] text-[24px] font-bold">{count}</div>
    </CardWrapper>
  )
};

const DashboardPropertyStatistics = () => {
  const { t } = useTranslation();

  const { user } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data = {} } = useQuery(["statistic", user], async () => {
      setLoader(true);

      let data = await getStatisticProperty();

      setLoader(false);
      return data;
    },
    {
      enabled: !!user,
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  const { sale, rent, favorites, chats, views, calls } = data as IGetStatisticsProperty;

  return (
    <div>
      <div className="flex gap-[20px] mb-6">
        <div className="flex-1">
          <PropertyItem
            title={t("properties_for_sale")}
            count={sale}
          />
        </div>
        <div className="flex-1">
          <PropertyItem
            title={t("properties_for_rent")}
            count={rent}
          />
        </div>
      </div>
      {user?.role !== EUserRole.Admin && (
        <div className="flex gap-[20px]">
          <div className="flex-1">
            <PropertyItem
              title={t("total_views")}
              count={views}
            />
          </div>
          <div className="flex-1">
            <PropertyItem
              title={t("total_in_favorites")}
              count={favorites}
            />
          </div>
          <div className="flex-1">
            <PropertyItem
              title={t("total_calls")}
              count={calls}
            />
          </div>
          <div className="flex-1">
            <PropertyItem
              title={t("total_chats")}
              count={chats}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardPropertyStatistics;
