import React, { useRef, MouseEvent } from 'react';
import ReactDOM from 'react-dom';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

const Modal: React.FC = () => {
  const { isOpen, children, onClose } = useGlobalModalStore();

  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent<HTMLDivElement>) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className="fixed top-[70px] md:top-0 left-0 bottom-0 right-0 flex-center bg-[#00000040] z-[6000]"
      onClick={handleClickOutside}
    >
      <div
        ref={modalRef}
        className="relative bg-white md:rounded-[25px] w-full h-full md:w-auto md:h-auto scale-fade-in"
      >
        <div>{children}</div>
      </div>
    </div>,
    document.body,
  );
};

export default Modal;
