import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { getAddressString } from "../../utils/address";
import { addPriceDelimiters } from "../../utils/helpers";

import { IPropertyShort } from "../../types/dto/property";

import PropertyStatistics from "../../../features/property/components/PropertyStatistics";
import PropertyStatus from "../../../features/property/components/PropertyStatus/PropertyStatus";

import LocationIcon from "../../assets/icons/LocationIcon";
import NoImageIcon from "../../assets/icons/NoImageIcon";

interface IPropertyCardProps {
  property?: IPropertyShort;
}

const PropertyCard: FC<IPropertyCardProps> = ({ property }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full relative">
      <div className="absolute top-[10px] right-[10px]">
        <PropertyStatus status={property?.status} />
      </div>
      {property?.mainImage?.url ? (
        <img
          src={property.mainImage.url}
          alt={property.mainImage.name}
          className="rounded-[10px] w-full aspect-[1.75] object-cover"
        />
      ) : (
        <div className="flex-center aspect-[1.75] bg-[#F8F8F8] rounded-[10px]">
          <NoImageIcon width={40} height={40}/>
        </div>
      )}

      <PropertyStatistics property={property} />
      <div className="flex justify-between items-center mt-[4px] gap-[12px]">
        <div className="flex items-center flex-1 min-w-0">
          <div className="w-[18px]">
            <LocationIcon />
          </div>
          {/*todo truncate fix*/}
          <div className="ml-[6px] w-auto truncate text-[14px] text-[#333]">
            {getAddressString(property?.address)}
          </div>
        </div>
        <div>
          <div className="rounded-[4px] px-[5px] py-[7px] bg-[#006CFB0D] text-[16px] font-semibold leading-[1]">
            {property?.price && `Kč ${addPriceDelimiters(property?.price)}`}
            <span className="text-[12px]">{property?.priceType === 'PER_MONTH' ? `/${t('mo')}` : property?.priceType === 'PER_DAY' ? `/${t('day')}` : ''}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;
