import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";

export const getChatRooms = async () => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get("/chat/room");
  return response.data.data;
}

export const getChatRoomMessages = async (id: string, page: number, limit: number) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get(`/chat/room/${id}/message`, {
    params: {
      page,
      limit,
    }
  });
  return response.data.data;
}

export const deleteChatRoom = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.delete(`/chat/room/${id}`);
  return response.data.data;
}
