import React, {ReactNode} from "react";
import classNames from "classnames";

interface ICardWrapperProps {
  className?: string;
  children?: ReactNode;
}

const CardWrapper =
  React.forwardRef<HTMLDivElement, ICardWrapperProps>(({children, className}, ref) => {
    return (
      <div className={classNames("bg-white p-5 rounded-[15px]", className)} ref={ref}>
        {children}
      </div>
    )
  })

export default CardWrapper;
