import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { addPriceDelimiters } from "../../../common/utils/helpers";

import Visa from "../../../common/assets/icons/Visa";
import MasterCard from "../../../common/assets/icons/MasterCard";

interface TransactionProps {
  cardType: string;
  card: string;
  id: number;
  date: string;
  amount: number;
  publications: number;
}

const Transaction: FC<TransactionProps> = ({ cardType, card, id, date, amount, publications }) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-6 gap-x-2 gap-y-[10px] items-center text-center mt-[10px]">
      <div className="flex items-center gap-[24px]">
        <div>
          {cardType === 'visa' ? <Visa width={36} height={36} /> : <MasterCard width={36} height={36} />}
        </div>
        <div className="whitespace-nowrap">{card}</div>
      </div>
      <div>{id}</div>
      <div>{date}</div>
      <div>{addPriceDelimiters(amount)} Kč</div>
      <div>{publications}</div>
      <div className="text-right">
        <button className="text-[12px] text-[#006CFB] font-bold whitespace-nowrap">{t('download_invoice')}</button>
      </div>
    </div>
  )
}

export default Transaction;
