import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EUserRole } from "../../../common/types/dto/user";

import useAuthStore from "../../../common/stores/useAuthStore";

import LangSwitcher from "../../../common/components/LangSwitcher/LangSwitcher";

import MenuItems from "./MenuItems";
import MenuDocumentationBlock from "./MenuDocumentationBlock";

import Logo from "../../../common/assets/images/Logo";

const Menu = () => {
  const { t } = useTranslation();

  const { user, impersonationToken } = useAuthStore();

  return (
    <div className="h-full w-full pl-[20px] pr-[42px] py-[24px]">
      <Link to="/" className="flex-center">
        <Logo width={140} height={49}/>
      </Link>
      <div className="mt-[28px]">
        <MenuItems/>
      </div>
      {(user?.role === EUserRole.Agent || impersonationToken) && (
        <>
          <div className="mt-[28px] text-white mb-[28px]">
            <MenuDocumentationBlock />
          </div>
          <Link to="/documents">
            <div className="text-[10px] text-[#26203B] text-center">{t('link_terms')}</div>
          </Link>
        </>
      )}
      <div className="mt-7 mb-[90px]">
        <LangSwitcher />
      </div>
    </div>
  );
}

export default Menu;
