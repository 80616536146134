import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getProduct, getProductAvailableCount } from "../../api/services/product/requests";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";

import PublicationsPlanCard from "../../features/products/components/PublicationsPlanCard";
import BalanceHistory from "../../features/products/components/BalanceHistory";

//todo: make components
const ProductsPage: React.FC = () => {
  const [showMore, setShowMore] = useState(false);

  const { i18n, t } = useTranslation();

  const lang = i18n.language;

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data = [] } = useQuery(["products"], async () => {
      setLoader(true);

      const data = await getProduct();

      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  const { data: { publications } = {} } = useQuery(["publications"], async () => {
      setLoader(true);

      const data = await getProductAvailableCount();

      setLoader(false);
      return data;
    },
    {
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  const productsMap = data.map((p: any) => {

    return <PublicationsPlanCard key={p.id} product={p} />
  });

  return (
    <div className="p-[18px_25px_26px_25px]">
      <div className="py-5 px-[38px] rounded-[15px] bg-white">
        <div className="mb-6">
          <h2 className="text-[18px] text-[#11142D] font-semibold mb-[10px] font-Manrope">{t('available_products')}:</h2>
          <div className="flex items-center gap-[10px] font-Manrope">
            <span>{t('Publications')}:</span>
            <span className="text-[#006CFB]">{publications}</span></div>
        </div>

        <div className="mb-6">
          <div className="mb-6">
            <h2 className="text-[18px] text-[#11142D] font-semibold mb-[10px] font-Manrope">{t('buy_publications')}</h2>
            <div className="text-[#666666]">{t('buy_publications_desc')}</div>
          </div>

          <div className="flex-center gap-[25px] mb-[22px]">
            {productsMap.slice(0,5)}
          </div>
          {showMore && (
            <div className="flex-center flex-wrap gap-[25px] mb-[22px]">
              {productsMap.slice(5)}
            </div>
          )}
          {productsMap.length > 5 && (
            <div>
              <button
                className="flex-center w-[180px] h-[48px] border border-blue rounded-[8px] font-bold text-blue mx-auto mb-1"
                onClick={() => setShowMore(prevShowMore => !prevShowMore)}
              >
                {showMore ? 'Hide' : 'Show more'}
              </button>
            </div>
          )}
        </div>

        <BalanceHistory />
      </div>
    </div>
  )
}

export default ProductsPage;
