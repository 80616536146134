import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useFormContext } from "react-hook-form";

import { getCategoryList } from "../../../api/services/category/requests";

import { IPropertyShort } from "../../../common/types/dto/property";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import { collectLeafCategoriesIdsFull } from "../helpers/category";

export const usePropertyFilters = ({ properties }: { properties?: IPropertyShort[] }) => {
  const [citiesOptions, setCitiesOptions] = useState<any[]>([]);

  const { setValue, watch } = useFormContext();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const status = watch("status");
  const city = watch("city");
  const categoriesIds = watch("categoriesIds");

  const selectedCategoriesCount = categoriesIds?.length ? ` (${categoriesIds.length})` : '';

  const { data: categories } = useQuery(["category"], async () => {
    setLoader(true);
    const data = await getCategoryList();
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    }
  });

  const handleCategorySelection = (selectedCategories: any) => {
    const leafCategoriesIds = collectLeafCategoriesIdsFull(categories, selectedCategories);
    setValue("categoriesIds", leafCategoriesIds);
  };

  useEffect(() => {
    if (!status?.value && !city?.value && !categoriesIds?.length) {
      const citiesAll = properties?.filter(p => !!p.address?.city).map(p => p.address?.city);
      setCitiesOptions([...new Set(citiesAll)].map(c => ({
        label: c,
        value: c,
      })) || []);
    }
  }, [properties]);

  return {
    selectedCategoriesCount,
    status,
    city,
    categories,
    citiesOptions,
    handleCategorySelection,
  };
};
