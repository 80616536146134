import { ICategoryFull } from "../../../common/types/dto/category";

export const initCategoriesIndexFromCategoryTree = (array: number[], category: ICategoryFull): number[] => {
  if (category.children.length > 0) return initCategoriesIndexFromCategoryTree([...array, 0], category.children[0])
  return array;
}

export const getCategoryFromCategoriesIndex = (categories: ICategoryFull[], array: number[]): ICategoryFull => {
  let category: ICategoryFull | null = null;
  for (let index of array) {
    if (!category) category = categories[index]
    else category = category.children[index]
  }
  return category!;
}

const collectLeafCategoryIds = (category: ICategoryFull, checkedIds: string[]): string[] => {
  let result = [];
  if (category.children.length === 0 && checkedIds.includes(category.id)) {
    result.push(category.id);
  }

  for (const child of category.children) {
    result = result.concat(collectLeafCategoryIds(child, checkedIds));
  }

  return result;
}

export const collectLeafCategoriesIdsFull = (categories: ICategoryFull[] | undefined, selectedCategories: any): string[] => {
  if (!categories) return [];

  let resultFull: string[] = [];

  const checkedIds = Object.keys(selectedCategories).filter(id => selectedCategories[id].checked);

  for (const category of categories) {
    const currentArray: string[] = collectLeafCategoryIds(category, checkedIds);
    resultFull.push(...currentArray);
  }

  return resultFull;
}
