import { FC } from "react";

import { ISvgProps } from "../../types/svg";

const LocationIcon: FC<ISvgProps> = ({ color= "black", height = 36, width= 36 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.5879 14.2988C23.91 14.0518 23.1934 13.9278 22.4719 13.9328C20.1386 13.9328 18.4907 15.1082 18.479 16.7955C18.4644 18.0351 19.65 18.7336 20.5469 19.1478C21.4671 19.5736 21.7748 19.8419 21.7719 20.2226C21.7661 20.8015 21.0369 21.064 20.3588 21.064C19.4269 21.064 18.9179 20.9342 18.1348 20.609L17.8446 20.4734L17.5136 22.4159C18.0809 22.6521 19.1032 22.8548 20.159 22.8723C22.6396 22.8723 24.2613 21.7042 24.2802 19.909C24.3007 18.9188 23.659 18.1707 22.3086 17.5523C21.4875 17.1513 20.9786 16.883 20.9786 16.4761C20.9786 16.1159 21.4146 15.7309 22.3261 15.7309C22.9327 15.7162 23.5355 15.83 24.095 16.0648L24.3138 16.1626L24.6448 14.2857L24.5879 14.2988ZM30.6429 14.0903H28.82C28.2527 14.0903 27.8255 14.2463 27.5775 14.8092L24.0717 22.7513H26.5523L27.0482 21.449L30.0757 21.4519C30.1471 21.7567 30.3659 22.7513 30.3659 22.7513H32.5534L30.6429 14.0903ZM15.1234 14.0173H17.4873L16.0086 22.6828H13.6475L15.1234 14.0144V14.0173ZM9.12232 18.7934L9.36732 19.9965L11.6773 14.0903H14.1813L10.4611 22.7396H7.96295L5.92128 15.4159C5.88821 15.2951 5.81056 15.1914 5.70399 15.1257C4.96812 14.7455 4.18841 14.4571 3.38232 14.2667L3.41441 14.0844H7.2192C7.73545 14.1048 8.15107 14.2667 8.28962 14.818L9.12087 18.7978L9.12232 18.7934ZM27.7263 19.6771L28.6684 17.2534C28.6567 17.2796 28.8623 16.7532 28.9819 16.428L29.1438 17.1761L29.6907 19.6757H27.7248L27.7263 19.6771Z" fill={color}/>
    </svg>
  );
}

export default LocationIcon;
