import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IPagination, IResponse } from "../../../common/types/response";

import { IGetNotificationOwnedPayload, IPatchNotificationReadPayload } from "./types";

export const getNotificationOwned = async (payload: IGetNotificationOwnedPayload) => {
  const response: AxiosResponse<IResponse<IPagination<any>>> = await axiosInstance.get('/notification/owned', {
    params: payload
  });
  return response.data.data;
}

export const patchNotificationRead = async (payload: IPatchNotificationReadPayload) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.patch('/notification/read', payload);
  return response.data.data;
}
