import React, { FC } from "react";

import { IPropertyShort } from "../../../common/types/dto/property";

import EyeIcon from "../../../common/assets/icons/EyeIcon";
import PhoneIcon from "../../../common/assets/icons/PhoneIcon";
import MessageIcon from "../../../common/assets/icons/MessageIcon";
import HeartIcon from "../../../common/assets/icons/HeartIcon";

interface IPropertyStatisticsProps {
  property?: IPropertyShort;
}

const PropertyStatistics: FC<IPropertyStatisticsProps> = ({ property }) => !!property && (
  <div className="font-Manrope flex items-center gap-4 mt-[4px] text-[#666] text-[12px]">
    <div className="flex items-center gap-[6px]">
      <EyeIcon />
      <div>{property.viewCount}</div>
    </div>
    <div className="flex items-center gap-[6px]">
      <PhoneIcon />
      <div>{property.callCount}</div>
    </div>
    <div className="flex items-center gap-[6px]">
      <MessageIcon/>
      <div>{property.chatCount}</div>
    </div>
    <div className="flex items-center gap-[6px]">
      <HeartIcon/>
      <div>{property.favoriteCount}</div>
    </div>
  </div>
)

export default PropertyStatistics;
