import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";

import { postPropertyModeration } from "../../../api/services/property/requests";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

interface IFormValues {
  duration: {
    label: string;
    value: number;
  };
}

const defaultValues = {
  duration: {
    label: "1 month",
    value: 1,
  }
}

const usePublishModalForm = ({ id }: { id: string; }) => {
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const {
    control,
    handleSubmit,
    watch,
  } = useForm<IFormValues>({
    mode: "onBlur",
    defaultValues,
  });

  const onSubmit: SubmitHandler<IFormValues> = async (value) => {
    try {
      const payload = {
        publicationCount: value.duration.value,
        autoBuyMissingPublications: false,
      }
      setLoader(true);
      const data = await postPropertyModeration(id, payload);
      setLoader(false);
      return data;
    } catch (error: any) {
      setError(error?.response?.data?.message);
      setLoader(false);
    }
  }

  return {
    control,
    watch,
    handleSubmit: handleSubmit(onSubmit),
  };
}

export default usePublishModalForm;
