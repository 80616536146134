import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import Button from "../../../../../common/components/Button/Button";
import Input from "../../../../../common/components/Input/Input";

import useFormChangePassword from "../../../hooks/useFormChangePassword";

import PasswordStrength from "../../../../create-account/components/PasswordStrength";

const ChangePasswordModalForm: React.FC = () => {
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    newPassword,
    isValid,
  } = useFormChangePassword();

  return (
      <form onSubmit={handleSubmit} className="grid gap-6 p-[25px] w-[572px]">
        <div>
          <h2 className="text-[#333] text-[18px] leading-5 font-medium mb-[10px]">{t('change_password')}</h2>
          <div className="text-[14px] text-[#666]">{t('update_password_description')}</div>
        </div>
        <Input
          type="password"
          label={t('current_password')}
          name="currentPassword"
          control={control}
          placeholder={t('enter_current_password')}
        />
        <Input
          type="password"
          label={t('new_password')}
          name="newPassword"
          control={control}
          placeholder={t('enter_new_password')}
        />
        <Input
          type="password"
          label={t('confirm_password')}
          name="confirmNewPassword"
          control={control}
          placeholder={t('enter_password_again')}
        />
        <PasswordStrength
          password={newPassword}
          setValidPassword={setValidPassword}
        />
        <Button
          withCooldown
          className="flex-center w-[249px] h-[48px] rounded-[8px] text-[16px] font-bold p-0 ml-auto"
          type="submit"
          disabled={!isValid || !validPassword}
        >{t('update_password')}</Button>
      </form>
  );
};

export default ChangePasswordModalForm;
