import React from "react";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { postAuthLogin } from "../../../api/services/auth/requests";

import { ILogin } from "../types/form";

import { loginFormSchema } from "../validations/schema";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useAuthStore from "../../../common/stores/useAuthStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";

import NotVerifiedEmailModal from "../components/modals/NotVerifiedEmailModal";

const useFormLogin = () => {
  const { t } = useTranslation();

  const { setAccessToken, setRefreshToken } = useAuthStore((state) => state);
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const { mutateAsync } = useMutation((payload: ILogin) => postAuthLogin(payload));

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm<ILogin>({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      isAdminPanel: true,
    },
    resolver: zodResolver(loginFormSchema),
  })

  const onSubmit = async (value: ILogin) => {
    try {
      setLoader(true);
      const { accessToken, refreshToken } = await mutateAsync({
        ...value,
        isAdminPanel: true,
      });
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
    } catch (error: any) {
      const needConfirmEmail = error?.response?.data?.data?.name === "EmailConfirmException";

      if(needConfirmEmail) {
        setChildren(React.createElement(NotVerifiedEmailModal, { email: value.email }));
        setIsOpen(true);
      } else {
        setError(error?.response?.data?.message || t('request_failed'));
      }
    }
    finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
}

export default useFormLogin;
