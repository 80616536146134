import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useMenuItems from "../hooks/useMenuItems";

import MenuItem from "./MenuItem";

const Menu = () => {
  let { pathname } = useLocation();
  const { t } = useTranslation();

  const { menuItems } = useMenuItems();

  return (
    <div className="flex flex-col gap-[20px] font-Manrope">
      {menuItems.map((item) => (
        <MenuItem
          key={item.label}
          icon={item.icon}
          label={t(item.label)}
          href={item.href}
          selected={item.href === pathname}
        />
      ))}
    </div>
  );
}

export default Menu;
