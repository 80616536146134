import React from 'react';
import ReactDOM from 'react-dom/client';

import I18nProvider from "./I18nProvider";

import App from "./App";

import './main.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <I18nProvider>
      <App />
    </I18nProvider>
  </React.StrictMode>
);
