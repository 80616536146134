import React, { useEffect, useState } from 'react';

import { ICategoryFull } from "../../types/dto/category";

import CheckIcon from "../../assets/icons/CheckIcon";

interface TreeSelectProps {
  data: ICategoryFull[];
  onSelected?: (state: ITreeNodeState) => void;
}

interface ITreeNodeState {
  [key: string]: {
    checked: boolean;
  };
}

const setChildrenCheckedState = (node: ICategoryFull, checked: boolean, updatedState: ITreeNodeState) => {
  updatedState[node.id] = {
    ...updatedState[node.id],
    checked,
  };

  if (node.children) {
    node.children.forEach(child => setChildrenCheckedState(child, checked, updatedState));
  }
};

const setParentCheckedState = (nodeId: string, data: ICategoryFull[], checked: boolean, updatedState: ITreeNodeState) => {
  const recurse = (parentNodeId: string, nodes: ICategoryFull[]) => {
    for (const node of nodes) {
      if (node.children?.some(child => child.id === parentNodeId)) {
        const hasCheckedChildren = node.children.some(child => updatedState[child.id]?.checked);

        updatedState[node.id] = {
          ...updatedState[node.id],
          checked: hasCheckedChildren,
        };

        recurse(node.id, data);

      } else if (node.children) {
        recurse(parentNodeId, node.children);
      }
    }
  };

  recurse(nodeId, data);
};

const TreeSelect: React.FC<TreeSelectProps> = ({ data, onSelected }) => {
  const initialTreeState = data.reduce((acc, node) => {
    const recurse = (node: ICategoryFull) => {
      acc[node.id] = {
        checked: false,
      };
      node.children?.forEach(recurse);
    };
    recurse(node);
    return acc;
  }, {} as ITreeNodeState);

  const [treeState, setTreeState] = useState<ITreeNodeState>(initialTreeState);

  const handleCheckboxChange = (node: ICategoryFull) => {
    const isChecked = treeState[node.id]?.checked || false;
    const updatedState: ITreeNodeState = { ...treeState };

    setChildrenCheckedState(node, !isChecked, updatedState);

    setParentCheckedState(node.id, data, !isChecked, updatedState);

    setTreeState(updatedState);
  };

  const renderTree = (nodes: ICategoryFull[], depth: number = 0) => {
    return (
      <ul>
        {nodes.map(node => (
          <li key={node.id}>
            <div className="grid grid-cols-[1fr_auto] gap-2 items-center h-[34px] border-b border-[#F7F7F7] px-4 text-[12px] text-[#333]" style={{ paddingLeft: `${depth * 12 + 16}px` }}>
              {node.title}
              <div className="relative w-4 h-4">
                <input
                  type="checkbox"
                  className="appearance-none w-4 h-4 border border-blue rounded-[2px] bg-transparent shrink-0 cursor-pointer checked:rounded-[4px] checked:bg-blue"
                  checked={treeState[node.id]?.checked || false}
                  onChange={() => handleCheckboxChange(node)}
                />
                <CheckIcon className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none" height={10} width={10} color="white" strokeWidth="2.5" />
              </div>
            </div>
            <div>
              {node.children && renderTree(node.children, depth + 1)}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    if (onSelected) {
      onSelected(treeState)
    }
  },[treeState])

  if (!data) return <></>

  return <div className="pt-1">{renderTree(data)}</div>;
};

export default TreeSelect;