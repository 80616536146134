import React, { useState, useRef, useEffect } from 'react';

interface DropdownProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
}

const BurgerDropdown: React.FC<DropdownProps> = ({ children, title = 'Toggle Dropdown', className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`relative ${className || ''}`}>
      <button onClick={() => setIsOpen(!isOpen)} className="flex-center w-full h-full cursor-pointer">
        {title}
      </button>
      <div className={`absolute top-full right-0 z-[1000] mt-3 ${isOpen ? '' : 'hidden'}`}>
        {children}
      </div>
    </div>
  );
};

export default BurgerDropdown;
