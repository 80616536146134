import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import { PROPERTY_STATUS_SELECT } from "../../../common/constants/property";

import { ICategoryFull } from "../../../common/types/dto/category";
import { IPropertyShort } from "../../../common/types/dto/property";

import Select from "../../../common/components/Select/Select";
import Dropdown from "../../../common/components/Dropdown/Dropdown";
import TreeSelect from "../../../common/components/TreeSelect/TreeSelect";
// import Input from "../../../common/components/Input/Input";

import { usePropertyFilters } from "../hooks/usePropertyFilters";

// import MagnifyingGlass from "../../../common/assets/icons/MagnifyingGlass";

interface IPropertyFiltersProps {
  properties?: IPropertyShort[];
}

const PropertyFilters: FC<IPropertyFiltersProps> = ({ properties }) => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const { categories, selectedCategoriesCount, citiesOptions, handleCategorySelection } = usePropertyFilters({ properties });

  return (
    <div className="flex items-center justify-between gap-[25px]">
      <div className="flex items-center gap-[25px]">
        <div className="w-[200px]">
          <Dropdown
            className="max-w-[248px] rounded-[6px]"
            title={selectedCategoriesCount.length ? `${t('categories')} ${selectedCategoriesCount}` : t('all_categories')}
            buttonClass='font-semibold text-[14px] text-[#666]'
          >
            {categories ? (
              <TreeSelect
                data={categories as ICategoryFull[]}
                onSelected={handleCategorySelection}
              />
            ) : <div />
            }
          </Dropdown>
        </div>
        <div className="w-[166px]">
          <Select
            control={control}
            classNames={{
              container: () => 'w-full h-[38px] bg-white rounded-[6px] border border-[#E2E8F0] leading-[18px]',
              singleValue: () => 'font-semibold text-[14px] text-[#666]',
              placeholder: () => 'font-semibold text-[14px] text-[#666]',
              dropdownIndicator: () => 'text-[#666]',
            }}
            name="status"
            options={PROPERTY_STATUS_SELECT}
            placeholder={t('all_status')}
            withAll={t('all_status')}
          />
        </div>
        <div className="w-[166px]">
          <Select
            control={control}
            classNames={{
              container: () => 'w-full h-[38px] bg-white rounded-[6px] border border-[#E2E8F0] leading-[18px]',
              singleValue: () => 'font-semibold text-[14px] text-[#666]',
              placeholder: () => 'font-semibold text-[14px] text-[#666]',
              dropdownIndicator: () => 'text-[#666]',
            }}
            name="city"
            options={citiesOptions}
            placeholder={t('all_city')}
            withAll={t('all_city')}
          />
        </div>
      </div>
      {/*<div>*/}
      {/*  <div className="relative w-[317px]">*/}
      {/*    <Input*/}
      {/*      control={control}*/}
      {/*      type="text"*/}
      {/*      name="search"*/}
      {/*      classnames="flex items-center w-full h-[38px] pl-[50px] pr-[10px] rounded-[8px] bg-[#F4F4F4]"*/}
      {/*      placeholder={t('search_placeholder')}*/}
      {/*      unstyled*/}
      {/*    />*/}
      {/*    <MagnifyingGlass className="absolute top-1/2 left-[20px] transform -translate-y-1/2" />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default PropertyFilters;
