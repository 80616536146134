import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const PhoneIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M12.417 9.67949L12.0758 10.0192C12.0758 10.0192 11.2635 10.8262 9.04725 8.62274C6.831 6.41923 7.64325 5.61223 7.64325 5.61223L7.85775 5.39773C8.388 4.87123 8.43825 4.02523 7.9755 3.40723L7.0305 2.14498C6.4575 1.37998 5.35125 1.27873 4.695 1.93123L3.5175 3.10123C3.19275 3.42523 2.97525 3.84373 3.0015 4.30873C3.069 5.49898 3.6075 8.05873 6.6105 11.0452C9.79575 14.2117 12.7845 14.3377 14.0063 14.2237C14.3933 14.1877 14.7293 13.9912 15 13.7212L16.065 12.6622C16.785 11.9475 16.5825 10.7212 15.6615 10.221L14.229 9.44173C13.6245 9.11398 12.8895 9.20999 12.417 9.67949Z"
        />
      </g>
    </svg>
  );
}

export default PhoneIcon;
