import React from 'react';
import { useTranslation } from 'react-i18next';

import { postOtpEmailConfirmSend } from '../../../../api/services/otp/requests';

import useGlobalErrorStore from '../../../../common/stores/useGlobalErrorStore';
import useGlobalLoaderStore from "../../../../common/stores/useGlobalLoaderStore";
import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import ModalTemplate from '../../../../common/components/Modal/ModalTemplate';
import ConfirmEmailModal from "../../../../common/components/modals/ConfirmEmailModal";
import Button from "../../../../common/components/Button/ButtonSite";

import EmailIcon from '../../../../common/assets/icons/EmailIcon';

const ModalBody = ({ email }: { email: string }) => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const handleSendOTPCode = async () => {
    try {
      setLoader(true);

      await postOtpEmailConfirmSend({email:email});

      setChildren(React.createElement(ConfirmEmailModal, {email: email}));
      setIsOpen(true);
    } catch (error: any) {
      setError(error?.response?.data?.message || t('login_failed'));
    } finally {
      setLoader(false);
    }
  };

  return (
    <div className="flex items-center justify-end">
      <Button variant="primary-outlined" onClick={handleSendOTPCode}>{t('send_code')}</Button>
    </div>
  );
};

const NotVerifiedEmailModal = ({ email }: { email: string}) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<EmailIcon />}
      title={t('verify_email_address')}
      description={t('email_not_verified', { email: email })}
      body={<ModalBody email={email} />}
    />
  );
};

export default NotVerifiedEmailModal;
