import React, { CSSProperties, useState } from 'react';
import ReactPinInput from 'react-pin-input';
import { Controller, ControllerProps } from 'react-hook-form';
import classNames from 'classnames';

import './PinInput.css';

type TInputType = 'numeric' | 'custom';

interface IPinInputProps<TFormData extends Record<string, any>>
  extends Pick<
    ControllerProps<TFormData>,
    'control' | 'name' | 'defaultValue'
  > {
  length: number;
  type?: TInputType;
  inputMode?: string;
  secret?: boolean;
  secretDelay?: number;
  disabled?: boolean;
  focus?: boolean;
  onChange?: (value: string, index: number) => void;
  onComplete?: (value: string, index: number) => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  inputFocusStyle?: CSSProperties;
  validate?: (value: string) => string;
  autoSelect?: boolean;
  regexCriteria?: any;
  ariaLabel?: string;
  placeholder?: string;
  error?: boolean;
}

function PinInput<TFormData extends Record<string, any>>({
                                                           name,
                                                           control,
                                                           defaultValue,
                                                           onChange,
                                                           error,
                                                           ...props
                                                         }: IPinInputProps<TFormData>): React.ReactElement {
  const [values, setValues] = useState<string[]>(Array(props.length).fill(''));

  const handleInputChange = (value: string, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    onChange?.(value, index);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const isError = error ?? !!fieldState.error;

        return (
          <div
            className={classNames({
              'error-wrapper': isError,
              'pin-1-filled': values[1] && !isError,
              'pin-2-filled': values[2] && !isError,
              'pin-3-filled': values[3] && !isError,
              'pin-4-filled': values[4] && !isError,
            })}
          >
            <ReactPinInput
              {...props}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
              inputStyle={{
                height: '80px',
                width: '80px',
                borderRadius: '7px',
                border: '',
                backgroundColor: 'transparent',
                fontSize: '36px',
                lineHeight: '24px',
                fontWeight: 700,
                color: '#605E76',
                margin: 0,
                caretColor: '#F2F8FF',
              }}
              initialValue={field.value}
              onChange={(value: string, index: number) => {
                field.onChange(value);
                handleInputChange(value, index);
              }}
              onComplete={(value: string, index: number) => {
                field.onChange(value);
                handleInputChange(value, index);
              }}
            />
          </div>
        );
      }}
    />
  );
}

export default PinInput;
