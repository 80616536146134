import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import useWebsocketChatStore from "../../../messages/stores/useWebsocketChatStore";

import Button from "../../../../common/components/Button/Button";

import CrossIcon from "../../../../common/assets/icons/CrossIcon";

const TransactionFailedModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { onClose } = useGlobalModalStore();

  const { setOpenSupport } = useWebsocketChatStore();

  const handleSupportRedirect = () => {
    setOpenSupport(true);
    onClose();
    navigate('/messages');
  };

  return (
    <div className="grid gap-5 w-[422px] p-[30px]">
      <div className="flex-center flex-col gap-2.5">
        <div className="flex-center w-[80px] h-[80px] bg-[#E53E3E] rounded-full"><CrossIcon width={29} height={29} color="#fff" /></div>
        <div className="text-[25px] text-[#333333] font-semibold font-Manrope">{t('something_went_wrong')}</div>
        <div className="text-[#5D5D5D] font-Manrope">{t('transaction_failed_error')}</div>
      </div>
      <div className="flex-center gap-5">
        <Button onClick={handleSupportRedirect} className="flex-center w-[180px] h-[40px] text-[16px] font-semibold font-Manrope text-[#2C66F6] bg-[#EAEFFE] border-0">{t('support')}</Button>
        <Button onClick={onClose} className="flex-center w-[180px] h-[40px] text-[16px] font-semibold font-Manrope">{t('try_again')}</Button>
      </div>
    </div>
  );
}

export default TransactionFailedModal;
