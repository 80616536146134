import * as zod from 'zod';
import i18next from 'i18next';

const createMinLengthErrorMessage = (length: number) => i18next.t('error_min_length', { length });

const zodStringWithMin = (length: number) => zod.string().min(length, createMinLengthErrorMessage(length));

export const createAccountBaseFormSchema = zod.object({
  name: zodStringWithMin(1),
  email: zod
    .string()
    .email(i18next.t('error_enter_valid_email')),
  password: zod
    .string()
    .min(8, createMinLengthErrorMessage(8))
    .regex(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/, i18next.t('error_latin_letters_only')),
  confirmPassword: zodStringWithMin(8),
  phone: zodStringWithMin(1),
  legalName: zod.string().optional(),
  address: zod.string().optional(),
  ic: zod.string().optional(),
  companyPhone: zod.string().optional(),
}).refine(
  (values) => {
    return values.password === values.confirmPassword;
  },
  {
    message: i18next.t('error_passwords_must_match'),
    path: ["confirmPassword"],
  }
);
