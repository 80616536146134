import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import Router from "./router";

import GlobalLoader from "./common/components/GlobalLoader/GlobalLoader";
import Notification from "./common/components/Notification/Notification";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <>
        <GlobalLoader/>
        <Router/>
        <Notification />
      </>
    </QueryClientProvider>
  );
}

export default App;
