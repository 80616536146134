import { Outlet } from 'react-router-dom';

import Modal from "../../common/components/Modal/Modal";
import Container from "../../common/components/Container/Container";

import Logo from "../../common/assets/images/Logo";

const AuthLayout = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="block md:hidden pb-[70px]">
        <div className="fixed w-full bg-primary z-[1000]">
          <Container>
            <div className="flex items-center h-[70px]">
              <Logo width={97} height={33} color="white"/>
            </div>
          </Container>
        </div>
      </div>
      <div className="flex flex-1 md:p-[32px] bg-blue">
        <div className="flex-1 md:rounded-[20px] bg-white py-[25px] md:px-[25px]">
          <Container>
            <div className="flex flex-col h-full">
              <Outlet/>
            </div>
          </Container>
        </div>
        <Modal />
      </div>
    </div>
  )
}

export default AuthLayout;
