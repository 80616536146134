import { create } from "zustand";

interface INotification {
  id: string;
  message: string;
  type: "warning" | "info" | "success";
}

interface INotificationStore {
  notifications: INotification[];
  addNotification: (message: string, type: "warning" | "info" | "success") => void;
  removeNotification: (id: string) => void;
}

const generateId = () => Math.random().toString(36).substr(2, 9);

const useNotificationStore = create<INotificationStore>((set) => ({
  notifications: [],
  addNotification: (message, type) =>
    set((state) => ({
      notifications: [...state.notifications, { id: generateId(), message, type }],
    })),
  removeNotification: (id) =>
    set((state) => ({
      notifications: state.notifications.filter((notification) => notification.id !== id),
    })),
}));

export default useNotificationStore;