import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EUserRole } from "../../../common/types/dto/user";

import useAuthStore from "../../../common/stores/useAuthStore";

import NoPropertiesImage from '../../../common/assets/icons/NoPropertiesImage';

const NoProperties = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user, impersonationToken } = useAuthStore();

  const handleCreateProperty = () => {
    navigate('/property/create');
  };

  return <div className="flex-center py-[100px]">
    <div className="w-[343px] flex flex-col items-center gap-[25px]">
      <div>
        <NoPropertiesImage />
      </div>
      <div className="text-[18px] text-[#333333] font-bold text-center">{t('you_have_no_properties')}</div>
      {(user?.role === EUserRole.Agent || impersonationToken) && (
        <div className="w-full">
          <button
            onClick={handleCreateProperty}
            className="w-full h-[40px] flex-center bg-[#006CFB] rounded-[8px] text-[16px] text-[#ffffff] font-bold uppercase"
          >
            {t('add_first_property')}
          </button>
        </div>
      )}
    </div>
  </div>
}

export default NoProperties;
