export const allowedLanguages = ["cz", "en"];

export const allowedLanguagesWithLabels = [
  {
    language: "cz",
    label: "czech",
  }, {
    language: "en",
    label: "english",
  },
]