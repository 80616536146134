import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const TransactionsIcon: FC<ISvgProps> = ({ width = 24, height = 24, color = '#666666'}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 17.625C1.5 18.3212 1.77656 18.9889 2.26884 19.4812C2.76113 19.9734 3.42881 20.25 4.125 20.25H19.875C20.5712 20.25 21.2389 19.9734 21.7312 19.4812C22.2234 18.9889 22.5 18.3212 22.5 17.625V10.4062H1.5V17.625ZM4.59375 14.0625C4.59375 13.6895 4.74191 13.3319 5.00563 13.0681C5.26935 12.8044 5.62704 12.6562 6 12.6562H8.25C8.62296 12.6562 8.98064 12.8044 9.24437 13.0681C9.50809 13.3319 9.65625 13.6895 9.65625 14.0625V15C9.65625 15.373 9.50809 15.7306 9.24437 15.9944C8.98064 16.2581 8.62296 16.4062 8.25 16.4062H6C5.62704 16.4062 5.26935 16.2581 5.00563 15.9944C4.74191 15.7306 4.59375 15.373 4.59375 15V14.0625Z" fill={color} />
      <path d="M19.875 3.74805H4.125C3.42881 3.74805 2.76113 4.02461 2.26884 4.51689C1.77656 5.00917 1.5 5.67685 1.5 6.37305V7.5918H22.5V6.37305C22.5 5.67685 22.2234 5.00917 21.7312 4.51689C21.2389 4.02461 20.5712 3.74805 19.875 3.74805Z" fill={color} />
    </svg>
  );
}

export default TransactionsIcon;
