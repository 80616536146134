import { create } from 'zustand';

interface IForgotPasswordStore {
  email: string;
  pin: string;
  pinError: boolean;
  setEmail: (newEmail: string) => void;
  setPin: (newPin: string) => void;
  setPinError: (newPinError: boolean) => void;
}

const useForgotPasswordStore = create<IForgotPasswordStore>((set) => ({
  email: '',
  pin: '',
  pinError: false,
  setEmail: (newEmail) =>
    set((prevState) => ({
      ...prevState,
      email: newEmail,
    })),
  setPin: (newPin) =>
    set((prevState) => ({
      ...prevState,
      pin: newPin,
    })),
  setPinError: (newPinError) =>
    set((prevState) => ({
      ...prevState,
      pinError: newPinError,
    })),
}));

export default useForgotPasswordStore;
