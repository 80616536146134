import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const HeartIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M5.23422 2.30514C5.89802 2.19204 6.57874 2.22935 7.2262 2.41432C7.87366 2.59929 8.47135 2.92721 8.97522 3.37389L9.00297 3.39864L9.02847 3.37614C9.50937 2.95412 10.0747 2.63949 10.6869 2.45325C11.299 2.26702 11.9438 2.21346 12.5782 2.29614L12.7627 2.32314C13.5625 2.46122 14.31 2.81301 14.9261 3.34125C15.5422 3.86948 16.004 4.5545 16.2626 5.32377C16.5211 6.09304 16.5669 6.91791 16.3949 7.71105C16.2229 8.50418 15.8397 9.23605 15.2857 9.82914L15.1507 9.96789L15.1147 9.99864L9.52722 15.5329C9.39828 15.6605 9.22745 15.7371 9.04639 15.7484C8.86532 15.7597 8.68629 15.705 8.54247 15.5944L8.47197 15.5329L2.85222 9.96639C2.25689 9.38714 1.8335 8.6544 1.62894 7.84934C1.42439 7.04428 1.44665 6.19832 1.69326 5.40513C1.93988 4.61194 2.40122 3.90249 3.02619 3.35535C3.65117 2.8082 4.41539 2.44472 5.23422 2.30514Z"
        />
      </g>
    </svg>
  );
}

export default HeartIcon;
