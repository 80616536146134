import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";

import {
  IPostPaymentCheckout,
  IPostPaymentProductPurchase,
  IGetPaymentInternalTransactions,
  IGetPaymentPaymentSessions
} from "./types";

export const postPaymentCheckout = async (payload: IPostPaymentCheckout) => {
  const response: AxiosResponse<IResponse<string>> = await axiosInstance.post('/payment/checkout', payload);
  return response.data.data;
}

export const postPaymentProductPurchase = async (id: string, payload: IPostPaymentProductPurchase) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post(`/payment/product/${id}/purchase`, payload);
  return response.data.data;
}

export const getPaymentInternalTransactions = async (payload: IGetPaymentInternalTransactions) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get('/payment/internal-transactions', {
    params: payload
  });
  return response.data.data;
}

export const getPaymentPaymentSessions = async (payload: IGetPaymentPaymentSessions) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get('/payment/payment-sessions', {
    params: payload
  });
  return response.data.data;
}

