import { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "../../../common/components/Input/InputSite";
import Button from "../../../common/components/Button/ButtonSite";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInputSite";

import useFormCreateAccountBase from "../hooks/useFormCreateAccountBase";

import PasswordStrength from "./PasswordStrength";

const CreateAccountBaseForm = () => {
  const [validPassword, setValidPassword] = useState(false);

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    password,
    isValid,
  } = useFormCreateAccountBase();

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex-center flex-col md:flex-row gap-[40px] mb-[40px] md:gap-[55px] md:mb-[55px]">
        <div className="w-full md:w-[400px] p-[20px] bg-[#F2F8FF] rounded-[15px]">
          <h3 className="text-heading-3 text-heading mb-[20px]">{t('company_information')}</h3>
          <div className="grid gap-[25px]">
            <Input
              label={t('legal_name')}
              name="legalName"
              control={control}
              placeholder={t('enter_legal_name')}
            />
            <Input
              label={t('ic')}
              name="ic"
              control={control}
              placeholder={t('enter_ic')}
            />
            <Input
              label={t('company_address')}
              name="address"
              control={control}
              placeholder={t('enter_address')}
            />
            <PhoneInput
              label={t('company_phone_number')}
              name="companyPhone"
              control={control}
              defaultCountry="CZ"
              placeholder={t('enter_phone_number')}
            />
          </div>
        </div>
        <div className="w-full md:w-[751px]">
          <h3 className="text-heading-3 text-heading">{t('personal_information')}</h3>
          <div className="text-body-default text-body mb-[20px] md:mb-[46px]">{t('personal_information_description')}</div>
          <div className="grid md:grid-cols-2 gap-y-[24px] gap-x-[35px]">
            <div className="md:order-1">
              <Input
                label={t('full_name')}
                name="name"
                control={control}
                placeholder={t('enter_full_name')}
              />
            </div>
            <div className="md:order-3">
              <Input
                label={t('email')}
                name="email"
                control={control}
                placeholder={t('enter_email')}
              />
            </div>
            <div className="md:order-5">
              <PhoneInput
                label={t('phone_number')}
                name="phone"
                control={control}
                defaultCountry="CZ"
                placeholder={t('enter_phone_number')}
              />
            </div>
            <div className="md:order-2">
              <Input
                type="password"
                label={t('password')}
                name="password"
                control={control}
                placeholder={t('enter_new_password')}
              />
            </div>
            <div className="md:order-4">
              <PasswordStrength
                password={password}
                setValidPassword={setValidPassword}
              />
            </div>
            <div className="md:order-6">
              <Input
                type="password"
                label={t('confirm_password')}
                name="confirmPassword"
                control={control}
                placeholder={t('enter_password_again')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-center">
        <Button
          withCooldown
          type="submit"
          disabled={!isValid || !validPassword}
          className="w-full md:w-[420px]"
        >
          {t('sign_up')}
        </Button>
      </div>
    </form>
  );
};

export default CreateAccountBaseForm;
