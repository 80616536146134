import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const PhoneIcon: FC<ISvgProps> = ({ color = 'black'}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.57143 12H21.4286V18C21.4286 18.7956 21.1577 19.5587 20.6754 20.1213C20.1932 20.6839 19.5391 21 18.8571 21H5.14286C4.46087 21 3.80682 20.6839 3.32458 20.1213C2.84235 19.5587 2.57143 18.7956 2.57143 18V12ZM20.9091 9L17.736 3.446C17.6578 3.3089 17.5518 3.19645 17.4274 3.11865C17.303 3.04084 17.164 3.00009 17.0229 3H6.97714C6.83599 3.00009 6.69703 3.04084 6.57262 3.11865C6.44821 3.19645 6.3422 3.3089 6.264 3.446L3.09086 9H20.9091ZM24 10.816V18C24 19.5913 23.4582 21.1174 22.4937 22.2426C21.5292 23.3679 20.2211 24 18.8571 24H5.14286C3.77889 24 2.47078 23.3679 1.50631 22.2426C0.541835 21.1174 0 19.5913 0 18V10.816C0.000110639 9.63149 0.300738 8.47351 0.864 7.488L4.12457 1.782C4.43757 1.23397 4.86171 0.784567 5.35933 0.473702C5.85696 0.162837 6.41267 0.000121044 6.97714 4.47976e-08H17.0229C17.5872 -9.84771e-05 18.1428 0.162312 18.6404 0.47282C19.138 0.783329 19.5622 1.23234 19.8754 1.78L23.136 7.488C23.6993 8.47351 23.9999 9.63149 24 10.816ZM14.1429 18C14.4838 18 14.8109 17.842 15.052 17.5607C15.2931 17.2794 15.4286 16.8978 15.4286 16.5C15.4286 16.1022 15.2931 15.7206 15.052 15.4393C14.8109 15.158 14.4838 15 14.1429 15H9.85714C9.51615 15 9.18912 15.158 8.94801 15.4393C8.70689 15.7206 8.57143 16.1022 8.57143 16.5C8.57143 16.8978 8.70689 17.2794 8.94801 17.5607C9.18912 17.842 9.51615 18 9.85714 18H14.1429Z" fill={color} />
    </svg>
  );
}

export default PhoneIcon;
