import React, { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Button.module.css";

interface IButton {
  children?: ReactNode;
  type?: "button" | "submit";
  color?: "blue" | "white" | "red";
  size?: "small" | "medium" | "large";
  className?: string;
  fullWidth?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  withCooldown?: boolean;
}

const COOLDOWN_TIME = 2000;

const Button =
  ({
     children,
     type = "button",
     color = "blue",
     size = "medium",
     className,
     fullWidth,
     onClick,
     disabled,
     withCooldown = false,
     ...props
   }: IButton) => {
    const [cooldown, setCooldown] = useState(false);

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      if (cooldown) return;

      if (onClick) {
        onClick(e);
      }

      setCooldown(withCooldown);
    };

    useEffect(() => {
      let timeout: NodeJS.Timeout;

      if (cooldown) {
        timeout = setTimeout(() => {
          setCooldown(false);
        }, COOLDOWN_TIME);
      }

      return () => clearTimeout(timeout);
    }, [cooldown]);

    return (
      <button
        type={type}
        className={classNames(
          styles["button"],
          styles[`button-${color}`],
          styles[`button-${size}`],
          {
            "w-full": fullWidth,
            [`${className}`]: !!className,
            "opacity-60": cooldown,
          }
        )}
        onClick={handleClick}
        disabled={disabled}
        {...props}
      >
        {children}
      </button>
    )
  }

export default Button;
