import React from 'react';
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import { deletePropertyById } from "../../../../../api/services/property/requests";

import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";
import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";

import Button from "../../../../../common/components/Button/Button";

interface DeleteModalFormProps {
  id: string;
}

const DeleteModalForm: React.FC<DeleteModalFormProps> = ({ id }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const deleteProperty = async () => {
    try {
      setLoader(true)
      await deletePropertyById(id);
      onClose();
      navigate('/properties')
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return (
    <div className="grid gap-[25px] p-[25px] w-[428px]">
      <div>
        <div className="text-[#333] text-[18px] leading-5 font-bold mb-2">{t('delete_property')}</div>
        <div>{t('sure_delete_property')}</div>
      </div>
      <div className="flex items-center justify-end gap-[30px]">
        <div onClick={onClose} className="text-[16px] text-[#006CFB] font-medium cursor-pointer">{t('not_now')}</div>
        <Button withCooldown className="w-[198px] h-[48px] rounded-[8px] text-[16px] font-bold" onClick={deleteProperty}>{t('delete_property')}</Button>
      </div>
    </div>
  );
};

export default DeleteModalForm;
