import { FC } from 'react';

import { ISvgProps } from "../../types/svg";

const ArrowRight: FC<ISvgProps> = ({ height = 16, width = 16, className }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.98584 1.73828L14.3315 8.08393L7.98584 14.4296"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3315 8.08399L0.831543 8.08398"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRight;
