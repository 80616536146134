import { useDropzone } from "react-dropzone";

import AddImageIcon from "../../../common/assets/icons/AddImageIcon";

const ImagesDropzone = ({ onDrop }: { onDrop: any }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp', '.gif'],
    },
  });

  return (
    <div {...getRootProps()} className="aspect-[1] rounded-[10px] bg-[#f5f5f5] flex-center cursor-pointer">
      <input {...getInputProps()}/>
      <AddImageIcon/>
    </div>
  )
}

export default ImagesDropzone;
