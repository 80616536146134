import React from 'react';
import { useTranslation } from "react-i18next";

import { postPaymentProductPurchase } from "../../../../../api/services/payment/requests";

import { addPriceDelimiters } from "../../../../../common/utils/helpers";

import { IPostPaymentProductPurchase } from "../../../../../api/services/payment/types";

import useGlobalLoaderStore from "../../../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";

interface IBillingModalFormProps {
  id: string;
  price: number;
  cost: number;
  title: string;
}

const ProductModalForm: React.FC<IBillingModalFormProps> = ({ id, price, cost, title }) => {
  const { t } = useTranslation();

  const { loader, setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { onClose } = useGlobalModalStore();

  const handleSubmit = async () => {
    if (loader) return;
    try {
      setLoader(true);

      const payload: IPostPaymentProductPurchase = {
        quantity: 1,
      }

      await postPaymentProductPurchase(id, payload);

      onClose();
      location?.reload();
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <div className="grid gap-[25px] p-[25px] w-[572px]">
      <div>
        <div className="text-[#333] text-[18px] leading-5 font-bold mb-[10px]">{t('buy')} {title}</div>
        <div className="text-[14px]">{t('buy_publications_desc')}</div>
      </div>
      <div className="text-right text-[#A0AFC4]">
        <div>{t('cost_per_1')} - <span>{cost}</span> Kč</div>
      </div>
      <div className="flex justify-end items-center gap-[30px]">
        <div className="text-[24px] text-[#006CFB] font-medium">{addPriceDelimiters(price)} Kč</div>
        <Button withCooldown onClick={handleSubmit} className="flex-center w-[249px] h-[48px] rounded-[8px] text-[16px] font-bold p-0" type="submit">{t('buy')}</Button>
      </div>
    </div>
  );
};

export default ProductModalForm;
