import axiosInstance from "../../axios";
import {AxiosResponse} from "axios";
import {
  IPostOtpEmailConfirmSendPayload,
  IPostOtpPasswordResetCheckPayload,
  IPostOtpPasswordResetCheckResponse,
  IPostOtpPasswordResetSendPayload,
  IPostOtpEmailChangeSendPayload
} from "./types";
import {IResponse} from "../../../common/types/response";

export const postOtpEmailConfirmSend = async (payload: IPostOtpEmailConfirmSendPayload) => {
  const response: AxiosResponse<string> = await axiosInstance.post("/otp/email-confirm/send", payload)
  return response.data;
}

export const postOtpEmailChangeSend = async (payload: IPostOtpEmailChangeSendPayload) => {
  const response: AxiosResponse<string> = await axiosInstance.post("/otp/email-change/send", payload)
  return response.data;
}

export const postOtpPasswordResetSend = async (payload: IPostOtpPasswordResetSendPayload) => {
  const response: AxiosResponse<string> = await axiosInstance.post("/otp/password-reset/send", payload)
  return response.data;
}

export const postOtpPasswordResetCheck = async (payload: IPostOtpPasswordResetCheckPayload) => {
  const response: AxiosResponse<IResponse<IPostOtpPasswordResetCheckResponse>> = await axiosInstance.post("/otp/password-reset/check", payload)
  return response.data;
}
