import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';

import { postAuthPasswordReset } from '../../../api/services/auth/requests';

import { IForgotPasswordChange } from '../types/form';

import { forgotPasswordChangeFormSchema } from '../validations/schema';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import useForgotPasswordStore from '../stores/useForgotPasswordStore';

import ForgotPasswordSuccessModal from '../components/modals/ForgotPasswordSuccessModal';

const useFormForgotPasswordChange = () => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setChildren } = useGlobalModalStore();

  const { email, pin, setEmail, setPin, setPinError } =
    useForgotPasswordStore();

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<IForgotPasswordChange>({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(forgotPasswordChangeFormSchema),
  });

  const password = watch('password');

  const onSubmit = async ({ password }: IForgotPasswordChange) => {
    try {
      setLoader(true);
      await postAuthPasswordReset({
        email,
        newPassword: password,
        otp: pin,
      });
      setChildren(React.createElement(ForgotPasswordSuccessModal));
      setEmail('');
      setPin('');
      setPinError(false);
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'));
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
    password,
  };
};

export default useFormForgotPasswordChange;
