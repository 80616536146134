import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const MessageIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.9231 2.76953H2.07692C0.9 2.76953 0 3.66953 0 4.84645V13.1541C0 14.3311 0.9 15.2311 2.07692 15.2311H15.9231C17.1 15.2311 18 14.3311 18 13.1541V4.84645C18 3.66953 17.1 2.76953 15.9231 2.76953ZM16.4769 13.4311L11.0769 9.55415L9 10.9388L6.85385 9.55415L1.52308 13.4311L5.88462 8.93107L0.553846 4.77722L9 9.34645L17.3769 4.84645L12.1154 9.0003L16.4769 13.4311Z"
        />
      </g>
    </svg>
  );
}

export default MessageIcon;
