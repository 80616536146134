import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import classNames from "classnames";

interface IOption {
  label: string;
  value: string;
}

interface IButtonsRadio {
  options: IOption[];
  control: Control;
  name: string;
}

const ButtonsRadio: FC<IButtonsRadio> = ({options, control, name}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState}) => (
        <div className="flex gap-[10px] flex-wrap">
          {options?.map((o) => (
            <div
              key={o.value}
              className={classNames("flex-center cursor-pointer rounded-[8px] min-w-[170px] h-[50px] font-bold",
                {
                  "bg-[#f5f5f5] text-[#333]": field.value !== o.value,
                  "bg-blue text-white": field.value === o.value,
                  "min-w-[50px]": o.label.length <= 2,
                },
              )}
              onClick={() => field.onChange(o.value)}
            >
              {o.label}
            </div>
          ))}
        </div>
      )}
    />
  )
}

export default ButtonsRadio;
