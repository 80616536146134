import React from "react";
import { useTranslation } from "react-i18next";

import useGlobalModalStore from "../../common/stores/useGlobalModalStore";

import Input from "../../common/components/Input/Input";
import PhoneInput from "../../common/components/PhoneInput/PhoneInput";
import Button from "../../common/components/Button/Button";
import Textarea from "../../common/components/Textarea/Textarea";

import useFormEditAccount from "../../features/account/hooks/useFormEditAccount";

import AvatarDropzone from "../../features/account/components/AvatarDropzone";
import ChangePasswordModalForm from "../../features/account/components/modals/ChangePasswordModalForm/ChangePasswordModalForm";
import ChangeEmailModalForm from "../../features/account/components/modals/ChangeEmailModalForm/ChangeEmailModalForm";

const AccountPage = () => {
  const { t } = useTranslation();

  const { setIsOpen, setChildren } = useGlobalModalStore();

  const {
    control,
    handleSubmit,
    isValid,
    avatar,
    setAvatar,
  } = useFormEditAccount();

  const openChangePasswordModal = (e: any) => {
    e.preventDefault();
    setChildren(<ChangePasswordModalForm />);
    setIsOpen(true);
  }

  const openChangeEmailModal = (e: any) => {
    e.preventDefault();
    setChildren(<ChangeEmailModalForm />);
    setIsOpen(true);
  }

  return (
    <div className="p-[18px_25px_26px_25px]">
      <form onSubmit={handleSubmit} className="grid gap-[24px] h-full p-5 pr-[57px] rounded-[15px] overflow-x-auto bg-white">
        <div className="grid grid-cols-[auto_1fr] gap-[24px]">
          <AvatarDropzone avatar={avatar} setAvatar={setAvatar} />
          <div className="grid grid-cols-[400px_400px] gap-[24px]">
            <Input
              label={t('full_name')}
              name="name"
              control={control}
              placeholder={t('enter_full_name')}
            />
            <PhoneInput
              label={t('phone_number')}
              name="phone"
              control={control}
              defaultCountry="CZ"
              placeholder={t('enter_phone_number')}
            />
            <Input
              label={t('email')}
              name="email"
              control={control}
              disabled
              placeholder={t('enter_email')}
            />
          </div>
        </div>
        <div className="flex items-center gap-6">
          <button onClick={openChangePasswordModal} className="flex-center w-[170px] h-[48px] border border-[#006CFB] rounded-[8px] font-bold text-[14px] text-[#006CFB]">{t('change_password')}</button>
          <button onClick={openChangeEmailModal} className="flex-center w-[170px] h-[48px] border border-[#006CFB] rounded-[8px] font-bold text-[14px] text-[#006CFB]">{t('change_email')}</button>
        </div>
        <div className="grid grid-cols-[400px_400px] gap-[24px]">
          <Input
            label={t('legal_name')}
            name="legalName"
            control={control}
            placeholder={t('enter_legal_name')}
          />
          <Input
            label={t('address')}
            name="address"
            control={control}
            placeholder={t('enter_address')}
          />
          <Input
            label={t('ic')}
            name="ic"
            control={control}
            placeholder={t('enter_ic')}
          />
          {/*<Input*/}
          {/*  label="City"*/}
          {/*  name="city"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter city"*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  label="DIČ"*/}
          {/*  name="dic"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter DIČ"*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  label="Postal code"*/}
          {/*  name="postalCode"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter postal code"*/}
          {/*/>*/}
          {/*<Input*/}
          {/*  label="Email"*/}
          {/*  name="companyEmail"*/}
          {/*  control={control}*/}
          {/*  placeholder="Enter email"*/}
          {/*/>*/}
          <PhoneInput
            label={t('company_phone_number')}
            name="companyPhone"
            control={control}
            defaultCountry="CZ"
            placeholder={t('enter_company_phone_number')}
          />
        </div>
        <div>
          <Textarea
            label={t('company_description')}
            name="description"
            control={control}
            placeholder={t('write_about_agency')}
            className="bg-[#fff]"
          />
        </div>
        <div className="grid grid-cols-[400px_400px] gap-[24px]">
          <Input
            label={t('web')}
            name="web"
            control={control}
            placeholder="Enter your website"
          />
        </div>
        <div className="grid grid-cols-[1fr_1fr_1fr_1fr] gap-[24px]">
          <Input
            label={t('instagram')}
            name="instagram"
            control={control}
            placeholder="@"
          />
          <Input
            label={t('twitter')}
            name="twitter"
            control={control}
            placeholder="@"
          />
          <Input
            label={t('facebook')}
            name="facebook"
            control={control}
            placeholder="@"
          />
          <Input
            label={t('tik_tok')}
            name="tikTok"
            control={control}
            placeholder="@"
          />
        </div>
        <div className="flex justify-end">
          <Button withCooldown className="w-[358px] h-[48px] text-[16px] font-bold" disabled={!isValid} type="submit">{t('update_profile')}</Button>
        </div>
      </form>
    </div>
  )
}

export default AccountPage;
