import { FC } from "react";

import { ISvgProps } from "../../../types/svg";

const ProductsIcon: FC<ISvgProps> = ({height = 24, width = 24, color = "#5D5D5D" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4436_5349)">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.875 3.37547C11.0821 3.37547 11.25 3.54335 11.25 3.75047V10.8755C11.25 11.0826 11.0821 11.2505 10.875 11.2505H3.75C3.54288 11.2505 3.375 11.0826 3.375 10.8755V3.75047C3.375 3.54335 3.54288 3.37547 3.75 3.37547H10.875ZM9.65625 4.96922H4.96875V9.65672H9.65625V4.96922ZM21.2187 7.02436C21.3652 7.1708 21.3652 7.40824 21.2187 7.55468L16.9761 11.7973C16.8296 11.9438 16.5922 11.9438 16.4458 11.7973L12.2031 7.55468C12.0567 7.40824 12.0567 7.1708 12.2031 7.02436L16.4458 2.7817C16.5922 2.63527 16.8296 2.63527 16.9761 2.7817L21.2187 7.02436ZM19.23 7.28951L16.7109 4.77047L14.1919 7.28953L16.7109 9.80859L19.23 7.28951ZM10.875 12.7505C11.0821 12.7505 11.25 12.9184 11.25 13.1255V20.2505C11.25 20.4576 11.0821 20.6255 10.875 20.6255H3.75C3.54288 20.6255 3.375 20.4576 3.375 20.2505V13.1255C3.375 12.9184 3.54288 12.7505 3.75 12.7505H10.875ZM9.65625 14.3442H4.96875V19.0317H9.65625V14.3442ZM20.25 12.7505C20.4571 12.7505 20.625 12.9184 20.625 13.1255V20.2505C20.625 20.4576 20.4571 20.6255 20.25 20.6255H13.125C12.9179 20.6255 12.75 20.4576 12.75 20.2505V13.1255C12.75 12.9184 12.9179 12.7505 13.125 12.7505H20.25ZM19.0312 14.3442H14.3438V19.0317H19.0312V14.3442Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_4436_5349">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ProductsIcon;
