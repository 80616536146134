import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const CheckIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {
  return (
    <svg
      width={width || 21}
      height={height || 21}
      viewBox="0 0 21 21"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1667 7.75C10.8297 7.75 11.4656 8.01339 11.9344 8.48223C12.4033 8.95107 12.6667 9.58696 12.6667 10.25C12.6667 10.913 12.4033 11.5489 11.9344 12.0178C11.4656 12.4866 10.8297 12.75 10.1667 12.75C9.50363 12.75 8.86774 12.4866 8.3989 12.0178C7.93006 11.5489 7.66667 10.913 7.66667 10.25C7.66667 9.58696 7.93006 8.95107 8.3989 8.48223C8.86774 8.01339 9.50363 7.75 10.1667 7.75ZM10.1667 4C14.3333 4 17.8917 6.59167 19.3333 10.25C17.8917 13.9083 14.3333 16.5 10.1667 16.5C6 16.5 2.44167 13.9083 1 10.25C2.44167 6.59167 6 4 10.1667 4ZM2.81667 10.25C3.49021 11.6253 4.53609 12.784 5.8354 13.5944C7.13471 14.4048 8.63533 14.8344 10.1667 14.8344C11.698 14.8344 13.1986 14.4048 14.4979 13.5944C15.7972 12.784 16.8431 11.6253 17.5167 10.25C16.8431 8.87474 15.7972 7.71604 14.4979 6.90562C13.1986 6.0952 11.698 5.66557 10.1667 5.66557C8.63533 5.66557 7.13471 6.0952 5.8354 6.90562C4.53609 7.71604 3.49021 8.87474 2.81667 10.25Z"
      />
    </svg>
  );
}

export default CheckIcon;
