import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { TPropertyStatus } from "../../../../common/types/dto/property";

import styles from "./PropertyStatus.module.scss";

interface IPropertyStatusProps {
  status?: TPropertyStatus;
}

const PropertyStatus: FC<IPropertyStatusProps> = ({ status}) => {
  const { t } = useTranslation();

  if (!status) return null;

  const text = useMemo(() => {
    switch (status) {
      case 'DRAFT':
        return t("status_draft");
      case 'ACTIVE':
        return t("status_active");
      case 'IN_MODERATION':
        return t("status_in_moderate");
      case 'UNPAID':
        return t("status_unpaid");
      case 'ARCHIVED':
        return t("status_archive");
      case 'REJECTED':
        return t("status_rejected");
      default:
        return '';
    }
  }, [status, t]);

  const classNameText = status.replaceAll("_", "-").toLowerCase();

  return (
    <span
      className={
        classNames(
          "h-[24px] min-w-[70px] inline-flex justify-center items-center px-[12px] py-[6px] rounded-[6px] text-white text-[12px] font-bold",
          styles[`property-status--${classNameText}`]
        )}
    >
      {text}
    </span>
  );
}

export default PropertyStatus;
