import {FC} from "react";
import {ISvgProps} from "../../../types/svg";

const ParametersIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill={color || "#333333"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 11.25H20V18.75H0V1.25H17.5V6.25H18.75V11.25ZM1.25 17.5H6.25V6.25H16.25V2.5H1.25V17.5ZM7.5 17.5H12.5V11.25H17.5V7.5H7.5V17.5ZM18.75 17.5V12.5H13.75V17.5H18.75Z"
      />
    </svg>
  );
}

export default ParametersIcon;
