import React from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

interface ITextareaInputProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label?: string;
  placeholder?: string;
  className?: string;
}

const TextareaInput = <T extends FieldValues>({ control, name, label, placeholder, className }: ITextareaInputProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <div className="grid">
          {label && (
            <label htmlFor={String(name)} className="text-sm text-[#333] mb-1.5">
              {label}
            </label>
          )}
          <textarea
            value={field.value}
            name={String(name)}
            className={`h-[150px] rounded-[8px] px-[16px] pt-[16px] pb-[12px] outline-none text-[14px] h-[162px] resize-none border border-[#E2E8F0] ${className || ''}`}
            onChange={(event) => {
              field.onChange(event.target.value);
            }}
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
};

export default TextareaInput;
