export const addPriceDelimiters = (price?: number | string ) => !!price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0;

export const capitalizeFirstLetter = (str: string | number) => String(str).charAt(0).toUpperCase() + String(str).slice(1);

export const formatPhoneNumber = (phone: string) => {
  if (!phone) return '';
  const cleaned = phone.replace(/\s+/g, '');

  if (cleaned.startsWith('+420') || cleaned.startsWith('420')) {
    const prefix = cleaned.startsWith('+420') ? '+420' : '420';
    const number = cleaned.slice(prefix.length);

    const groups = number.match(/.{1,3}/g);

    return `${prefix} ${groups ? groups.join(' ') : ''}`.trim();
  }

  return phone;
}

export const formatDate = (date?: string) => {
  if (!date || typeof date !== 'string') return;
  return new Intl.DateTimeFormat('default', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date));
}