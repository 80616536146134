import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { postOtpEmailConfirmSend } from '../../../api/services/otp/requests';

import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';

import ModalTemplate from '../../../common/components/Modal/ModalTemplate';
import PinInput from '../../../common/components/PinInputSite/PinInput';

import useConfirmEmailPin from "../../../common/hooks/useConfirmEmailPin";

import EmailIcon from '../../../common/assets/icons/EmailIcon';

const ModalBody = ({ email }: { email: string }) => {
  const [counter, setCounter] = useState(60);

  const { t } = useTranslation();

  const { pinError, setError } = useGlobalErrorStore();

  const {
    control,
  } = useConfirmEmailPin();

  const startCounter = () => {
    const intervalId = setInterval(() => {
      if (counter <= 0) clearInterval(intervalId);
      setCounter((c) => c - 1);
    }, 1000);
  };

  useEffect(() => {
    startCounter();
  }, []);

  const resendCode = async () => {
    if (counter > 0) return;
    setCounter(60);
    try {
      await postOtpEmailConfirmSend({ email });
      startCounter();
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'));
    }
  };

  return (
    <div className="flex flex-col items-center gap-[20px]">
      <PinInput
        length={4}
        defaultValue={''}
        name="pin"
        control={control}
        error={pinError}
      />
      <div
        className={`font-bold ${counter <= 0 ? 'cursor-pointer text-accent' : 'text-[#9997A8]'}`}
        onClick={resendCode}
      >
        {t('resend_code')} {counter >= 0 && counter}
      </div>
    </div>
  );
};

const ConfirmEmailModal = ({ email }: { email: string}) => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<EmailIcon />}
      title={t('verify_email_address')}
      description={t('verify_email_address_description', { email: email })}
      body={<ModalBody email={email} />}
    />
  );
};

export default ConfirmEmailModal;
