import React from 'react';

import useGlobalModalStore from "../../../../../common/stores/useGlobalModalStore";

import Button from "../../../../../common/components/Button/Button";

import CheckIcon from "../../../../../common/assets/icons/CheckIcon";

const ChangePasswordSuccessModal: React.FC = () => {
  const { onClose } = useGlobalModalStore();

  const handleClick = () => {
    onClose();
  }

  return (
      <div className="flex-center w-[572px] h-[528px]">
        <div className="flex flex-col items-center">
          <div className="flex-center w-[80px] h-[80px] bg-[#03C15B] rounded-full mb-2.5">
            <CheckIcon width={26} height={26} color="white" />
          </div>
          <div className="font-Manrope text-[26px] text-[#666] font-bold mb-2.5">Congratulations!</div>
          <div className="text-[12px] text-[#333] font-medium mb-5">Password updated</div>
          <Button onClick={handleClick} className="flex-center w-[249px] h-[48px] rounded-[8px] text-[16px] font-bold p-0">Back to profile</Button>
        </div>
      </div>
  );
};

export default ChangePasswordSuccessModal;
