import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"

import { changeEmailFormSchema } from "../validations/schema";

import { postOtpEmailChangeSend } from "../../../api/services/otp/requests";

import { IChangeEmail } from "../types/form";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../../common/stores/useAuthStore";

import useChangeEmailStore from "../stores/useChangeEmailStore";

const useFormChangeEmail = (openOTPModal: () => void) => {
  const { user } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { setEmail } = useChangeEmailStore()

  const defaultValues = {
    email: '',
  }

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm<IChangeEmail>({
    mode: 'onBlur',
    defaultValues,
    resolver: zodResolver(changeEmailFormSchema),
  });

  const onSubmit = async (value: IChangeEmail) => {
    try {
      setLoader(true)
      setEmail(value.email);
      await postOtpEmailChangeSend({ newEmail: value.email })
      openOTPModal();
    } catch (error: any) {
      setError(error?.response?.data?.message || '');
    } finally {
      setLoader(false)
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
}

export default useFormChangeEmail;
