import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const LocationIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg className="inline mr-1 mb-1" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3 9.90014C14.3 10.7754 13.9524 11.6147 13.3335 12.2336C12.7146 12.8525 11.8753 13.2001 11 13.2001C10.1248 13.2001 9.28546 12.8525 8.66659 12.2336C8.04772 11.6147 7.70004 10.7754 7.70004 9.90014C7.70004 9.02492 8.04772 8.18556 8.66659 7.56669C9.28546 6.94782 10.1248 6.60014 11 6.60014C11.8753 6.60014 12.7146 6.94782 13.3335 7.56669C13.9524 8.18556 14.3 9.02492 14.3 9.90014ZM13.2 9.90014C13.2 9.31666 12.9683 8.75708 12.5557 8.3445C12.1431 7.93192 11.5835 7.70014 11 7.70014C10.4166 7.70014 9.85698 7.93192 9.4444 8.3445C9.03182 8.75708 8.80004 9.31666 8.80004 9.90014C8.80004 10.4836 9.03182 11.0432 9.4444 11.4558C9.85698 11.8684 10.4166 12.1001 11 12.1001C11.5835 12.1001 12.1431 11.8684 12.5557 11.4558C12.9683 11.0432 13.2 10.4836 13.2 9.90014ZM16.445 15.3506C17.8892 13.9057 18.7005 11.9464 18.7005 9.90344C18.7005 7.86051 17.8892 5.90119 16.445 4.45624C15.73 3.74115 14.8811 3.1739 13.9469 2.78689C13.0126 2.39988 12.0113 2.20068 11 2.20068C9.98879 2.20068 8.98745 2.39988 8.05319 2.78689C7.11894 3.1739 6.27006 3.74115 5.55504 4.45624C4.11084 5.90119 3.29956 7.86051 3.29956 9.90344C3.29956 11.9464 4.11084 13.9057 5.55504 15.3506L7.22814 16.9995L9.47544 19.183L9.62174 19.3128C10.4742 20.0036 11.7227 19.9596 12.5257 19.183L15.2042 16.576L16.445 15.3506ZM6.33054 5.23174C6.9435 4.61809 7.6714 4.13129 8.47263 3.79914C9.27386 3.467 10.1327 3.29605 11 3.29605C11.8674 3.29605 12.7262 3.467 13.5274 3.79914C14.3287 4.13129 15.0566 4.61809 15.6695 5.23174C16.8732 6.43701 17.5652 8.06037 17.6013 9.76338C17.6374 11.4664 17.0147 13.1176 15.8631 14.3727L15.6695 14.5751L14.2164 16.0095L11.7634 18.3954L11.66 18.4834C11.4695 18.6261 11.2379 18.7031 10.9999 18.7029C10.7618 18.7027 10.5303 18.6253 10.34 18.4823L10.2377 18.3943L6.95974 15.1988L6.33054 14.5751L6.13694 14.3738C4.98536 13.1187 4.36269 11.4675 4.39876 9.76448C4.43483 8.06147 5.12685 6.43701 6.33054 5.23174Z" fill="#666666"/>
    </svg>
  );
}

export default LocationIcon;
