import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { postOtpPasswordResetSend } from '../../../api/services/otp/requests';

import { forgotPasswordEmailFormSchema } from '../validations/schema';

import { IForgotPasswordEmail } from '../types/form';

import useGlobalLoaderStore from '../../../common/stores/useGlobalLoaderStore';
import useGlobalErrorStore from '../../../common/stores/useGlobalErrorStore';
import useGlobalModalStore from '../../../common/stores/useGlobalModalStore';

import useForgotPasswordStore from '../stores/useForgotPasswordStore';

import ForgotPasswordPinModal from '../components/modals/ForgotPasswordPinModal';

const useFormResetPasswordEmail = () => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();
  const { setChildren } = useGlobalModalStore();

  const { setEmail } = useForgotPasswordStore();

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<IForgotPasswordEmail>({
    mode: 'onTouched',
    defaultValues: { email: '' },
    resolver: zodResolver(forgotPasswordEmailFormSchema),
  });

  const onSubmit = async (value: IForgotPasswordEmail) => {
    try {
      setLoader(true);
      await postOtpPasswordResetSend({ email: value.email });
      setEmail(value.email);
      setChildren(React.createElement(ForgotPasswordPinModal));
    } catch (error: any) {
      setError(error?.response?.data?.message || t('request_failed'));
    } finally {
      setLoader(false);
    }
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
  };
};

export default useFormResetPasswordEmail;
