import { useTranslation } from "react-i18next";

import usePropertyEditStore, { TPropertyEditStoreStep } from "../stores/usePropertyEditStore";

import ChevronIcon from "../../../common/assets/icons/ChevronIcon";

const PropertyEditList = ({ requiredFieldsCount }: { requiredFieldsCount: any}) => {
  const { t } = useTranslation();

  const { setStep } = usePropertyEditStore();

  const list: { title: string; description: string; step: TPropertyEditStoreStep }[] = [
    {
      title: t('disposition'),
      description: t('disposition_description'),
      step: 'disposition',
    },
    {
      title: t('photos_and_description'),
      description: t('photos_and_description_description'),
      step: 'photosAndDescription',
    },
    {
      title: t('parameters'),
      description: t('parameters_description'),
      step: 'parameters',
    },
    {
      title: t('features'),
      description: t('features_description'),
      step: 'features',
    },
    {
      title: t('price_and_terms'),
      description: t('price_and_terms_description'),
      step: 'priceAndRentalTerms',
    },
  ];

  const handleClick = (step: TPropertyEditStoreStep) => {
    setStep(step);
  };

  const listMap = list.map(({ title, description, step}) => (
    <div key={step} onClick={() => handleClick(step)} className="w-full h-[60px] flex justify-between items-center cursor-pointer">
      <div className="h-full flex flex-col justify-between">
        <div className="text-[20px] text-[#333] font-medium">{title}</div>
        <div className="text-[#666]">{description}</div>
      </div>
      <div className="flex items-center gap-[25px]">
        {!!requiredFieldsCount[step] && (
          <div className="text-[#C73434]">
            {t('fill_required_items', { count: requiredFieldsCount[step] })}
          </div>
        )}
        <ChevronIcon width={20} height={20} color="black" className="transform rotate-180" />
      </div>
    </div>
  ));

  return (
    <div className="flex flex-col gap-10">
      {listMap}
    </div>
  )
}

export default PropertyEditList;
