import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { addPriceDelimiters } from "../../../common/utils/helpers";

import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";

import ProductModalForm from "./modals/ProductModalForm/ProductModalForm";

type TProductType = "PUBLICATION";

interface Product {
  id: string;
  type: TProductType;
  internalPrice: number;
  quantity: number;
  meta: any;
  createdAt: string;
  updatedAt: string;
  title: string;
  description: string;
}

interface PublicationsPlanCardProps {
  product: Product;
}

const PublicationsPlanCard: FC<PublicationsPlanCardProps> = ({ product }) => {
  const { id, internalPrice, quantity, title, description } = product;
  const { t } = useTranslation();

  const { setIsOpen, setChildren } = useGlobalModalStore();
  
  const cost = internalPrice / quantity

  const openModal = () => {
    setChildren(<ProductModalForm  id={id} price={internalPrice} cost={cost} title={title} />);
    setIsOpen(true);
  }

  return (
    <div className="flex flex-col justify-between w-[180px] h-[188px] py-5 px-3 rounded-[12px] bg-blue text-white leading-[1.2]">
      <div>
        <div className="whitespace-nowrap mb-[5px]">
          {title}
        </div>
        <div className="text-[12px]">{description}</div>
      </div>
      <div>
        <div className="text-[18px] font-semibold whitespace-nowrap mb-2.5">
          {addPriceDelimiters(internalPrice)} Kč
        </div>
        <div>
          <button onClick={openModal} className="flex-center w-full h-[29px] rounded-[8px] bg-white text-blue">{t('pay')}</button>
        </div>
      </div>
    </div>
  )
}

export default PublicationsPlanCard;
