import { FC } from "react";
import classNames from "classnames";

interface ISelectFieldProps {
  title?: string;
  description?: string;
  options: any[];
  setField: (layer: number, index: number, childrenLength: number) => void;
  layer: number;
  selectIndex: number;
  currentLayer: number;
}

const PropertyRadioCategoryField: FC<ISelectFieldProps> = ({
                                              title,
                                              description,
                                              options,
                                              setField,
                                              layer,
                                              selectIndex,
                                              currentLayer
                                                           }) => {
  return (
    <div className="flex flex-col gap-[15px]">
      {title && <div className="text-[20px] text-[#333] font-semibold">{title}</div>}
      {/*{description && <div className="text-[#666] leading-[1.6]">{description}</div>}*/}
      {!!options?.length && (
        <div className="flex gap-[10px] flex-wrap">
          {options?.map((o, index) => (
            <div
              key={o.id}
              className={classNames("flex-center cursor-pointer rounded-[8px] w-[170px] h-[50px] font-bold bg-[#F5F5F5] text-[#333]",
                {
                  "bg-blue text-white": currentLayer > layer && selectIndex === index,
                }
              )} onClick={() => setField(layer, index, o.children.length)}
            >
              {o.title}
            </div>
          ))}
        </div>)}
    </div>
  );
}

export default PropertyRadioCategoryField;
