import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

function getClasses(variant: string, size: string, fullWidth: boolean): string {
  return classNames({
    'flex-center gap-[8px] whitespace-nowrap transition-all duration-500': true,
    'w-full': fullWidth,
    'bg-button-primary text-white font-semibold hover:bg-button-primary-hover disabled:bg-disabled disabled:text-white':
      variant === 'primary-master',
    'bg-transparent border border-button-primary text-button-primary font-normal hover:border-button-primary-hover hover:text-button-primary-hover disabled:border-disabled disabled:text-disabled':
      variant === 'primary-outlined',
    'bg-button-secondary text-heading font-semibold hover:bg-button-secondary-hover disabled:bg-disabled disabled:text-white':
      variant === 'secondary-master',
    'bg-transparent border border-button-secondary text-button-secondary font-normal hover:border-button-secondary-hover hover:text-button-secondary-hover disabled:border-disabled disabled:text-disabled':
      variant === 'secondary-outlined',
    'h-[66px] px-[36px] text-[18px] leading-[18px] rounded-[20px]':
      size === 'default',
    'h-[54px] px-[24px] text-[16px] leading-[18px] rounded-[15px]':
      size === 'small',
    'h-[44px] px-[18px] text-[12px] leading-[14px] rounded-[15px]':
      size === 'smaller',
    'h-[76px] px-[56px] text-[20px] leading-[20px] rounded-[23px]':
      size === 'large',
  });
}

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary-master'
    | 'primary-outlined'
    | 'secondary-master'
    | 'secondary-outlined';
  size?: 'default' | 'small' | 'smaller' | 'large' | 'custom';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  withCooldown?: boolean;
  fullWidth?: boolean;
}

const COOLDOWN_TIME = 2000;

const Button: React.FC<IButtonProps> = ({
  children = '',
  variant = 'primary-master',
  size = 'default',
  className = '',
  onClick,
  withCooldown = false,
  fullWidth = false,
  ...props
}) => {
  const classes = getClasses(variant, size, fullWidth);

  const [cooldown, setCooldown] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (cooldown) return;

    if (onClick) {
      onClick(e);
    }

    setCooldown(withCooldown);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (cooldown) {
      timeout = setTimeout(() => {
        setCooldown(false);
      }, COOLDOWN_TIME);
    }

    return () => clearTimeout(timeout);
  }, [cooldown]);

  return (
    <button
      className={classNames(classes, className)}
      onClick={handleClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
