import React, {ComponentType, FC} from "react";
import {ISvgProps} from "../../../common/types/svg";
import {Link} from "react-router-dom";
import classNames from "classnames";

interface IMenuItemProps {
  icon: ComponentType<ISvgProps>;
  label: string;
  href: string;
  selected: boolean;
}

const MenuItem: FC<IMenuItemProps> = ({icon: Icon, label, href, selected}) => {
  return (
    <Link to={href}>
      <div
        className={classNames(
          "flex items-center gap-[8px] px-[15px] py-[8px] rounded-[12px] text-[14px] font-medium",
          {
            "bg-blue text-[#fcfcfc]": selected,
            "bg-[#fcfcfc] text-[#666666]": !selected,
          })
        }
      >
        <Icon color={selected ? "#fcfcfc" : "#666666"} />
        <div>
          {label}
        </div>
      </div>
    </Link>
  );
}

export default MenuItem;
