import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";

import Button from "../../../../common/components/Button/Button";

import CheckIcon from "../../../../common/assets/icons/CheckIcon";

const TransactionSuccessModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { onClose } = useGlobalModalStore();

  const handleProductsRedirect = () => {
    onClose();
    navigate('/products');
  };

  return (
    <div className="grid gap-5 w-[422px] p-[30px]">
      <div className="flex-center flex-col gap-2.5">
        <div className="flex-center w-[80px] h-[80px] bg-[#03C15B] rounded-full"><CheckIcon width={24} height={24} color="#fff" /></div>
        <div className="text-[25px] text-[#333333] font-semibold font-Manrope">{t('balance_updated')}</div>
        <div className="text-[#5D5D5D] font-Manrope">{t('funds_added_to_account')}</div>
      </div>
      <div className="flex-center gap-5">
        <Button onClick={handleProductsRedirect} className="flex-center w-[180px] h-[40px] text-[16px] font-semibold font-Manrope">{t('go_to_store')}</Button>
        <Button onClick={onClose} className="flex-center w-[180px] h-[40px] text-[16px] font-semibold font-Manrope text-[#2C66F6] bg-[#EAEFFE] border-0">{t('continue')}</Button>
      </div>
    </div>
  );
}

export default TransactionSuccessModal;
