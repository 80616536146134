import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useCreateAccountStore from "../../features/create-account/stores/useCreateAccountStore";

import CreateAccountBaseForm from "../../features/create-account/components/CreateAccountBaseForm";

import Logo from "../../common/assets/images/Logo";
import ArrowRight from "../../common/assets/icons/ArrowRight";

const CreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { resetStore } = useCreateAccountStore();

  const handleBackClick = () => {
    navigate("/");
  }

  useEffect(() => {
    resetStore()
  }, [])

  return (
    <div className="relative flex-1 flex flex-col justify-between items-center gap-[80px]">
      <button className="absolute top-0 md:top-[7px] left-0 flex-center w-[42px] h-[42px]" onClick={handleBackClick}>
        <ArrowRight className="text-heading transform rotate-180"/>
      </button>
      <div className="hidden md:block">
        <Link to="/">
          <Logo/>
        </Link>
      </div>
      <div className="w-full flex-center flex-col">
        <h3 className="text-heading-3 text-heading text-center mb-[40px] md:mb-[55px]">
          <span className="block md:hidden leading-[42px]">{t('registration')}</span><span className="hidden md:block">{t('agent_registration')}</span></h3>
        <div>
          <CreateAccountBaseForm/>
        </div>
      </div>
      <div className="max-w-[260px] text-[#9C9AA5] text-[12px] text-center font-semibold">
        {t('login_text_accept')}{' '}
        <Link to="/documents">
          <span className="text-heading text-[12px] text-center font-semibold">{t('link_terms')}</span>
        </Link>
      </div>
    </div>
  );
}

export default CreateAccountPage;
