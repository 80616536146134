import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';

interface IDropdownSimpleProps {
  children: React.ReactNode;
  title: React.ReactNode;
  className?: string;
  buttonClass?: string;
  menuClass?: string;
  maxHeight?: number;
}

const DropdownSimple = forwardRef(({ children, title = '', className = '', buttonClass = '', menuClass = '', maxHeight = 300 }: IDropdownSimpleProps, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useImperativeHandle(ref, () => ({
    closeDropdown: () => setIsOpen(false),
    openDropdown: () => setIsOpen(true),
    toggleDropdown: () => setIsOpen((prev) => !prev),
  }));

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className={`relative inline-flex ${className}`}>
      <button onClick={() => setIsOpen(!isOpen)} className={`w-full h-full cursor-pointer ${buttonClass}`}>
        <div className="truncate">{title}</div>
      </button>
      <div className={`absolute top-full left-0 z-[1000] ${isOpen ? '' : 'hidden'} min-w-full bg-white p-0 mt-[15px] rounded-[8px] shadow-[0px_0px_25px_0px_#00000012] overflow-hidden ${menuClass}`}>
        <div className="overflow-auto" style={{ maxHeight }}>
          {children}
        </div>
      </div>
    </div>
  );
});

export default DropdownSimple;

