import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"

import { editAccountFormSchema } from "../validations/schema";

import { patchUpdateAgent } from "../../../api/services/user/requests";
import { IPatchUpdateAgentPayload } from "../../../api/services/user/types";
import { postMediaUploadImage } from "../../../api/services/media/requests";

import { IEditAccount } from "../types/form";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../../common/stores/useAuthStore";
import useNotificationStore from "../../../common/stores/useNotificationStore";

const useFormEditAccount = () => {
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { user, setUser } = useAuthStore();
  const { addNotification } = useNotificationStore();

  const [avatar, setAvatar] = useState(user?.avatar as any || null)

  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    businessType: user?.agentProfile?.businessType || 'enterpreter',
    legalName: user?.agentProfile?.legalName || '',
    address: user?.agentProfile?.address || '',
    // city: user?.agentProfile?.city || '',
    // postalCode: user?.agentProfile?.postalCode || '',
    ic: user?.agentProfile?.ic || '',
    // dic: user?.agentProfile?.dic || '',
    // companyEmail: user?.agentProfile?.email || '',
    companyPhone: user?.agentProfile?.phone || '',
    description:user?.agentProfile?.description || '',
    web: user?.agentProfile?.web || '',
    instagram: user?.agentProfile?.instagram || '',
    twitter: user?.agentProfile?.twitter || '',
    facebook: user?.agentProfile?.facebook || '',
    tikTok: user?.agentProfile?.tikTok || '',
  }

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
  } = useForm<IEditAccount>({
    mode: 'onBlur',
    defaultValues,
    resolver: zodResolver(editAccountFormSchema),
  });

  const onSubmit = async (value: IEditAccount) => {
    setLoader(true);
    let avatarData;
    if(avatar?.toUpload) {
      try {
        avatarData = await postMediaUploadImage({image: avatar.file})
      } catch (error: any) {
        setError(error.response.data.message);
        setLoader(false);
        return;
      }
    } else {
      avatarData = avatar
    }

    const payload: IPatchUpdateAgentPayload = {
      name: value.name,
      avatar: avatarData?.id || null,
      phone: value.phone,
      profile: {
        businessType: value.businessType,
        legalName: value.legalName,
        address: value.address,
        // city: value.city,
        // postalCode: value.postalCode,
        ic: value.ic,
        // dic: value.dic,
        // email: value.companyEmail,
        phone: value.companyPhone,
        description: value.description,
        web: value?.web?.length ? value.web : undefined,
        instagram: value.instagram,
        twitter: value.twitter,
        facebook: value.facebook,
        tikTok: value.tikTok,
      },
    }

    try {
      setLoader(true)
      const user = await patchUpdateAgent(payload);
      setUser(user);
      addNotification('Update successful!', "success");
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    isValid,
    avatar,
    setAvatar,
  };
}

export default useFormEditAccount;
