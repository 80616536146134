import React, { FC, ReactNode } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import ChevronIcon from "../../assets/icons/ChevronIcon";

interface IPageHeaderProps {
  backButton?: string;
  start?: ReactNode;
  end?: ReactNode;
  className?: string;
}

const PageHeader: FC<IPageHeaderProps> = ({backButton, start, end, className}) => {
  return (
    <div className={classNames("flex justify-between items-center gap-[16px]", className)}>
      <div className="flex items-center gap-[24px] text-black text-[24px] font-bold">
        {/*todo fix border*/}
        {backButton && (
          <Link to={backButton}>
            <div className="bg-white rounded-[10px] w-[40px] h-[40px] flex-center border border-[#ccc]">
              <ChevronIcon color="black"/>
            </div>
          </Link>
        )}
        {start}
      </div>
      <div>
        {end}
      </div>
    </div>
  );
}

export default PageHeader;
