import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const ArrowBackIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.9594 0.771532C18.0003 0.66931 18.0103 0.557327 17.9882 0.449466C17.9661 0.341605 17.9128 0.24261 17.8349 0.164753C17.757 0.0868953 17.658 0.0336002 17.5502 0.0114743C17.4423 -0.0106516 17.3303 -0.000635168 17.2281 0.0402818L0.862742 6.58666H0.861617L0.353117 6.78916C0.256806 6.82757 0.172988 6.89187 0.110922 6.97493C0.0488559 7.05799 0.0109572 7.15659 0.00141206 7.25984C-0.00813305 7.36309 0.011047 7.46697 0.0568335 7.56001C0.10262 7.65304 0.173231 7.73161 0.260867 7.78703L0.722117 8.07953L0.723242 8.08178L6.34262 11.657L9.91787 17.2764L9.92012 17.2787L10.2126 17.7399C10.2682 17.8272 10.3468 17.8975 10.4398 17.943C10.5328 17.9885 10.6365 18.0074 10.7395 17.9978C10.8426 17.9881 10.941 17.9502 11.0239 17.8883C11.1068 17.8263 11.1709 17.7426 11.2094 17.6465L17.9594 0.771532ZM15.8972 2.89778L7.46649 11.3285L7.22462 10.9483C7.1803 10.8785 7.12115 10.8194 7.05137 10.775L6.67112 10.5332L15.1019 2.10241L16.4271 1.57253L15.8972 2.89778Z" fill="#006CFB"/>
    </svg>
  );
}

export default ArrowBackIcon;
