import { create } from "zustand";

const initialValues = {
  gallery: [],
  mainImage: null,
};

interface IPropertyImagesStore {
  gallery: any[];
  setGallery: (newGallery: any) => void;
  addGalleryImages: (newImage: any[]) => void;
  deleteGalleryImage: (image: any) => void;

  mainImage: any;
  setMainImage: (newMainImage: any) => void;

  resetStore: () => void;
}

const usePropertyImagesStore = create<IPropertyImagesStore>((set) => ({
  ...initialValues,
  setGallery: (newGallery) => set(() => ({
    gallery: newGallery,
  })),
  addGalleryImages: (newImages) =>
    set((prevState) => ({
      gallery: [...prevState.gallery, newImages],
    })),
  deleteGalleryImage: (id) =>
    set((prevState) => ({
      gallery: prevState.gallery.filter(uI => uI.id !== id),
    })),

  setMainImage: (newMainImage) => set(() => ({
    mainImage: newMainImage,
  })),

  resetStore: () => set(() => ({
    ...initialValues,
  })),
}));

export default usePropertyImagesStore;
