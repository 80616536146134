import { useTranslation } from "react-i18next"

import { patchUpdateAgent } from "../../../api/services/user/requests";

import { allowedLanguagesWithLabels } from "../../constants/language";

import useGlobalErrorStore from "../../stores/useGlobalErrorStore";

import Dropdown from "../Dropdown/DropdownSimple";

import GlobusIcon from "../../assets/icons/GlobusIcon";

const LangSwitcher = () => {
  const { i18n, t } = useTranslation();

  const { setError } = useGlobalErrorStore();

  const lang = i18n.language;
  const languageString = allowedLanguagesWithLabels.find(l => l.language === lang)?.label || '';

  const buttonComponent = (
    <div className="flex items-center gap-2 text-[#11142D]">
      <GlobusIcon color="#11142D" />
      <div>{t(languageString)}</div>
    </div>
  );

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng).then(async () => {
      localStorage.setItem('i18nLocale', lng);
      try {
        await patchUpdateAgent({
          lang: lng,
        })
      } catch (error) {
        setError(error.response?.data?.message || 'Unknown error');
      }
      window?.location?.reload();
    });
  };

  return (
    <div className="flex-center">
      <Dropdown title={buttonComponent} menuClass="w-auto left-1/2 transform -translate-x-1/2">
        <div className="flex flex-col gap-[15px] px-5 py-2.5">
          {allowedLanguagesWithLabels.map(({ label, language }) => {
            return lang === language ? (
              <div key={language} className="text-[12px] text-[#006CFB]">{t(label)}</div>
            ) : (
              <div key={language} onClick={() => changeLanguage(language)} className="text-[12px] text-[#666666] cursor-pointer">{t(label)}</div>
            )
          })}
        </div>
      </Dropdown>
    </div>
  )
}

export default LangSwitcher;
