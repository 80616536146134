import * as zod from 'zod';
import i18next from 'i18next';

const createMinLengthErrorMessage = (length: number) => i18next.t('error_min_length', { length });

const zodStringWithMin = (length: number) => zod.string().min(length, createMinLengthErrorMessage(length));

export const editAccountFormSchema = zod.object({
  name: zodStringWithMin(1),
  // email: zod.string().email(i18next.t('error_enter_valid_email')),
  phone: zodStringWithMin(1),
  businessType: zod.string(),
  legalName: zod.string(),
  address: zod.string(),
  ic: zod.string(),
  // city: zod.string(),
  // dic: zod.string(),
  // postalCode: zod.number(),
  companyPhone: zod.string(),
  description: zod.string(),
  web: zod.string(),
  instagram: zod.string(),
  twitter: zod.string(),
  facebook: zod.string(),
  tikTok: zod.string(),
});

export const changePasswordFormSchema = zod.object({
  currentPassword: zod.string(),
  newPassword: zod
    .string()
    .min(8, createMinLengthErrorMessage(8))
    .regex(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]*$/, i18next.t('error_latin_letters_only')),
  confirmNewPassword: zodStringWithMin(8),
}).refine(
  (values) => {
    return values.newPassword === values.confirmNewPassword;
  },
  {
    message: i18next.t('error_passwords_must_match'),
    path: ["confirmNewPassword"],
  }
);

export const changeEmailFormSchema = zod.object({
  email: zod.string().email(i18next.t('error_enter_valid_email')),
});