import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import { IUser } from "../../../common/types/dto/user";

import Input from "../../../common/components/Input/Input";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInput";
import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";

const UserProfile = ({ user }: { user: IUser }) => {
  const { t } = useTranslation();

  const defaultValues = {
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
  }

  const {
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  return (
    <CardWrapper className="p-[25px] min-h-[calc(100vh-180px)]">
      <div className="grid grid-cols-[400px_400px] gap-[24px]">
        <Input
          label={t('full_name')}
          name="name"
          control={control}
          disabled
        />
        <PhoneInput
          label={t('phone_number')}
          name="phone"
          control={control}
          defaultCountry="CZ"
          disabled
        />
        <Input
          label={t('email')}
          name="email"
          control={control}
          disabled
        />
      </div>
    </CardWrapper>
  )
}

export default UserProfile;
