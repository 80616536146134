import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const AddImageIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill={color || "#006cfb"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42 30V36H48V40H42V46H38V40H32V36H38V30H42ZM42.016 6C43.112 6 44 6.89 44 7.986V26H40V10H8V37.998L28 18L34 24V29.658L28 23.658L13.654 38H28V42H5.984C5.45763 41.9995 4.95299 41.79 4.58098 41.4176C4.20896 41.0452 4 40.5404 4 40.014V7.986C4.00366 7.46076 4.2138 6.95803 4.58503 6.58644C4.95625 6.21484 5.45876 6.00419 5.984 6H42.016ZM16 14C17.0609 14 18.0783 14.4214 18.8284 15.1716C19.5786 15.9217 20 16.9391 20 18C20 19.0609 19.5786 20.0783 18.8284 20.8284C18.0783 21.5786 17.0609 22 16 22C14.9391 22 13.9217 21.5786 13.1716 20.8284C12.4214 20.0783 12 19.0609 12 18C12 16.9391 12.4214 15.9217 13.1716 15.1716C13.9217 14.4214 14.9391 14 16 14Z"
      />

    </svg>
  );
}

export default AddImageIcon;
