import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const PinIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 13}
      height={height || 13}
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || "#666666"}
      opacity={opacity || 1}
    >
      <path
        d="M10.8302 5.16035L11.3551 4.63539L7.64256 0.959961L7.15473 1.48529L7.59504 1.9256L3.32857 5.53457L2.6915 4.89787L2.16654 5.41503L4.26673 7.52264L0.959961 10.829L1.48343 11.3551L4.79169 8.0476L6.90005 10.1478L7.41721 9.62284L6.78013 8.98576L10.3902 4.72004L10.8302 5.16035Z"
      />
    </svg>
  );
}

export default PinIcon;
