import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const EyeIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.3125 9C7.3125 8.55245 7.49029 8.12323 7.80676 7.80676C8.12323 7.49029 8.55245 7.3125 9 7.3125C9.44755 7.3125 9.87678 7.49029 10.1932 7.80676C10.5097 8.12323 10.6875 8.55245 10.6875 9C10.6875 9.44755 10.5097 9.87678 10.1932 10.1932C9.87678 10.5097 9.44755 10.6875 9 10.6875C8.55245 10.6875 8.12323 10.5097 7.80676 10.1932C7.49029 9.87678 7.3125 9.44755 7.3125 9Z"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M1.5 9C1.5 10.23 1.81875 10.6432 2.45625 11.472C3.729 13.125 5.8635 15 9 15C12.1365 15 14.271 13.125 15.5438 11.472C16.1813 10.644 16.5 10.2292 16.5 9C16.5 7.77 16.1813 7.35675 15.5438 6.528C14.271 4.875 12.1365 3 9 3C5.8635 3 3.729 4.875 2.45625 6.528C1.81875 7.3575 1.5 7.77075 1.5 9ZM9 6.1875C8.25408 6.1875 7.53871 6.48382 7.01126 7.01126C6.48382 7.53871 6.1875 8.25408 6.1875 9C6.1875 9.74592 6.48382 10.4613 7.01126 10.9887C7.53871 11.5162 8.25408 11.8125 9 11.8125C9.74592 11.8125 10.4613 11.5162 10.9887 10.9887C11.5162 10.4613 11.8125 9.74592 11.8125 9C11.8125 8.25408 11.5162 7.53871 10.9887 7.01126C10.4613 6.48382 9.74592 6.1875 9 6.1875Z"/>
      </g>
    </svg>
);
}

export default EyeIcon;
