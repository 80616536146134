import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";

import { IPropertyAddress } from "../../../common/types/dto/property";

import { patchPropertyById } from "../../../api/services/property/requests";
import { IPatchPropertyByIdPayload } from "../../../api/services/property/types";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

const usePropertyDispositionForm = (propertyId: string, defaultValue?: IPropertyAddress) => {
  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { mutateAsync } = useMutation(async ({ propertyId, payload }: {
    propertyId: string,
    payload: IPatchPropertyByIdPayload
  }) => {
    setLoader(true);
    const data = await patchPropertyById(propertyId, payload);
    setLoader(false);
    return data;
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<{ address: IPropertyAddress | null }>({
    defaultValues: {
      address: defaultValue,
    }
  });

  const houseNumber = watch('address')?.houseNumber;

  const onSubmit = async (value: { address: IPropertyAddress | null }) => {
    if (!value.address?.houseNumber) {
      throw new Error(t('warn_select_building'))
    }
    await mutateAsync({
        propertyId,
        payload: { address: value.address! },
      }, {
        onError: (error: any) => {
          setError(error.response.data.message);
          setLoader(false);
        }
      });
  };

  useEffect(() => {
    reset({ address: defaultValue });
  }, [defaultValue]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    requiredFieldsCount: houseNumber ?? 0 ? 0 : 1,
  }
}

export default usePropertyDispositionForm;
