import {FC} from "react";
import {ISvgProps} from "../../../types/svg";

const DescriptionIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill={color || "#333"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 3V5H2V3H18ZM12 7V9H2V7H12ZM18 7V9H14V7H18ZM8 11V13H2V11H8ZM18 11V13H10V11H18ZM14 15V17H2V15H14Z"
      />
    </svg>
  );
}

export default DescriptionIcon;
