import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";
import { IImage } from "../../../common/types/image";
import { IPostMediaUploadImage } from "./types";

export const postMediaUploadImage = async (payload: IPostMediaUploadImage) => {
  const formData = new FormData();
  formData.append("image", payload.image);
  const response: AxiosResponse<IResponse<IImage>> = await axiosInstance.post("/media/upload/image", payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data.data;
}
