import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const LocationIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill={color || "#333333"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
        <g >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
                d="M15 8.00441C15 9.42437 14.5075 10.7292 13.684 11.7572H13.6875C13.6875 11.7572 11.6022 14.5497 9.98682 16.527C9.47145 17.1579 8.52874 17.1576 8.01367 16.5265C6.40341 14.5535 4.32083 11.7632 4.32083 11.7632L4.31597 11.7572C3.49254 10.7292 3 9.42437 3 8.00441C3 4.68827 5.68629 2 9 2C12.3137 2 15 4.68827 15 8.00441ZM11.2404 7.97842C11.2404 9.21659 10.2374 10.2203 9.0001 10.2203C7.76284 10.2203 6.75984 9.21659 6.75984 7.97842C6.75984 6.74025 7.76284 5.73651 9.0001 5.73651C10.2374 5.73651 11.2404 6.74025 11.2404 7.97842Z"
          />
        </g>
    </svg>
  );
}

export default LocationIcon;
