import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const generatePages = (): (number | string)[] => {
    const startPage = Math.max(2, currentPage - 1);
    const endPage = Math.min(totalPages - 1, currentPage + 1);

    const visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, idx) => startPage + idx);

    const pages: (number | string)[] = [
      1,
      ...(currentPage > 3 ? ['...'] : []),
      ...visiblePages,
      ...(currentPage < totalPages - 2 ? ['...'] : []),
      totalPages,
    ];

    return pages;
  };

  const pages = generatePages();

  return (
    <nav className="flex justify-center w-full" aria-label="Pagination">
      <ul className="flex space-x-2 p-[10px]">
        {pages.map((page, index) =>
          typeof page === 'number' ? (
            <li key={index}>
              <button
                className={`flex-center w-[32px] h-[32px] border rounded-[4px] bg-[#fff] text-[14px] font-bold ${page === currentPage ? 'border-[#006CFB] text-[#006CFB]' : 'border-[#DFE3E8] text-[#212B36] hover:border-[#006CFB99] hover:text-[#006CFB99]'}`}
                onClick={() => onPageChange(page)}
                disabled={page === currentPage}
              >
                {page}
              </button>
            </li>
          ) : (
            <li key={index} className="px-3 py-1">
              {page}
            </li>
          )
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
