import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const PhoneIcon: FC<ISvgProps> = ({ color = "#152242"}) => {
  return (
    <svg width="39" height="45" viewBox="0 0 39 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.625 15.795V13.125C32.625 9.64403 31.2422 6.30564 28.7808 3.84422C26.3194 1.38281 22.981 0 19.5 0C16.019 0 12.6806 1.38281 10.2192 3.84422C7.75781 6.30564 6.375 9.64403 6.375 13.125V15.795C4.70505 16.5238 3.28367 17.7235 2.28469 19.2473C1.2857 20.7711 0.752397 22.5529 0.75 24.375V35.625C0.752977 38.1105 1.74165 40.4933 3.49916 42.2508C5.25667 44.0083 7.63951 44.997 10.125 45H28.875C31.3605 44.997 33.7433 44.0083 35.5008 42.2508C37.2583 40.4933 38.247 38.1105 38.25 35.625V24.375C38.2476 22.5529 37.7143 20.7711 36.7153 19.2473C35.7163 17.7235 34.2949 16.5238 32.625 15.795ZM10.125 13.125C10.125 10.6386 11.1127 8.25403 12.8709 6.49587C14.629 4.73772 17.0136 3.75 19.5 3.75C21.9864 3.75 24.371 4.73772 26.1291 6.49587C27.8873 8.25403 28.875 10.6386 28.875 13.125V15H10.125V13.125ZM34.5 35.625C34.5 37.1168 33.9074 38.5476 32.8525 39.6025C31.7976 40.6574 30.3668 41.25 28.875 41.25H10.125C8.63316 41.25 7.20242 40.6574 6.14752 39.6025C5.09263 38.5476 4.5 37.1168 4.5 35.625V24.375C4.5 22.8832 5.09263 21.4524 6.14752 20.3975C7.20242 19.3426 8.63316 18.75 10.125 18.75H28.875C30.3668 18.75 31.7976 19.3426 32.8525 20.3975C33.9074 21.4524 34.5 22.8832 34.5 24.375V35.625Z"
        fill={color} />
      <path
        d="M19.5 26.2495C19.0027 26.2495 18.5258 26.4471 18.1742 26.7987C17.8225 27.1503 17.625 27.6272 17.625 28.1245V31.8745C17.625 32.3718 17.8225 32.8487 18.1742 33.2003C18.5258 33.552 19.0027 33.7495 19.5 33.7495C19.9973 33.7495 20.4742 33.552 20.8258 33.2003C21.1775 32.8487 21.375 32.3718 21.375 31.8745V28.1245C21.375 27.6272 21.1775 27.1503 20.8258 26.7987C20.4742 26.4471 19.9973 26.2495 19.5 26.2495Z"
        fill={color} />
    </svg>
  );
}

export default PhoneIcon;
