import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from 'react-tooltip';

import { ICategorySchema, ICategorySchemaGroupItem } from "../../../../common/types/dto/category";
import { IProperty, TPropertyStatus } from "../../../../common/types/dto/property";

import useGlobalModalStore from "../../../../common/stores/useGlobalModalStore";
import useGlobalErrorStore from "../../../../common/stores/useGlobalErrorStore";

import usePropertyEditStore from "../../stores/usePropertyEditStore";

import PublishModalForm from "../modals/PublishModalForm/PublishModalForm";
import ArchiveModalForm from "../modals/ArchiveModalForm/ArchiveModalForm";
import ActivateModalForm from "../modals/ActivateModalForm/ActivateModalForm";
import DeleteModalForm from "../modals/DeleteModalForm/DeleteModalForm";

import EditIcon from "../../../../common/assets/icons/EditIcon";
import CrossIcon from "../../../../common/assets/icons/CrossIcon";
import PublishIcon from "../../../../common/assets/icons/PublishIcon";
import ArchiveIcon from "../../../../common/assets/icons/ArchiveIcon";
import WalletIcon from "../../../../common/assets/icons/WalletIcon";
import ThrashCan from "../../../../common/assets/icons/ThrashCan";

interface IPropertyActionsAgentProps {
  id?: string;
  status: TPropertyStatus;
  property: IProperty;
  categorySchema?: ICategorySchema;
}

const getFilledRequiredFieldsFromSchema = (property: IProperty, schema?: ICategorySchemaGroupItem ) => {
  const requiredFieldsNames = schema?.fields?.filter(field => field.meta?.isRequired).map(field => field.name);
  const filledFieldNames = [
    ...(property?.fields?.filter(item => !!item.value || !!item.tag).map(item => item.field.name) || []),
  ];

  return requiredFieldsNames?.filter(fieldName => !filledFieldNames?.includes(fieldName)).length || 0;
}

const PropertyActionsAgent: React.FC<IPropertyActionsAgentProps> = ({ id, status, property, categorySchema }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setIsOpen, setChildren } = useGlobalModalStore();
  const { setError } = useGlobalErrorStore();

  const { setFlow } = usePropertyEditStore();

  if (!id) return <></>;

  const parametersSchema = useMemo(() => categorySchema?.groups?.find((g) => g.name === "parameters"), [categorySchema]);
  const featuresSchema = useMemo(() => categorySchema?.groups?.find((g) => g.name === "features"), [categorySchema]);
  const priceAndRentalTermsSchema = useMemo(() => categorySchema?.groups?.find((g) => g.name === "priceAndRentalTerms"), [categorySchema]);

  const allRequiredFieldsFilled = useMemo(() => {
    const hardcodedRequiredFieldsPriceAndRentalTerms = (!!property?.contactPhone ? 0 : 1) + (!!property?.price ? 0 : 1);

    const galleryCount = property?.gallery?.length || 0;
    const requiredFieldsPhotos = (!!property?.mainImage ? 0 : 1) + Math.max(0, 3 - galleryCount);

    const requiredFieldsDescription = property?.description || {};
    const requiredFieldDescription = Object.values(requiredFieldsDescription).some((value) => (value as string).length >= 50) ? 0 : 1;

    const requiredFieldsCount = {
      disposition: property.address?.houseNumber ?? 0 ? 0 : 1,
      parameters: getFilledRequiredFieldsFromSchema(property, parametersSchema ),
      features: getFilledRequiredFieldsFromSchema(property, featuresSchema),
      priceAndRentalTerms: getFilledRequiredFieldsFromSchema(property, priceAndRentalTermsSchema) + hardcodedRequiredFieldsPriceAndRentalTerms,
      photosAndDescription: requiredFieldsPhotos + requiredFieldDescription,
    };

    return Object.values(requiredFieldsCount).every(value => !value);
  }, [property, parametersSchema, featuresSchema, priceAndRentalTermsSchema])

  const editProperty = useCallback(() => {
    setFlow('edit');
    navigate(`/property/${id}/edit`);
  }, [id]);

  const unArchiveProperty = useCallback(() => {
    const { hasTimeToPublishEnd } = property;

    if (!allRequiredFieldsFilled) {
      setError(t('not_all_required_fields'));
      return;
    }

    if (hasTimeToPublishEnd) {
      setChildren(<ActivateModalForm id={id} />)
      setIsOpen(true);
    } else {
      setChildren(<PublishModalForm id={id} />)
      setIsOpen(true);
    }
  }, [property, id])

  const publishProperty = useCallback(() => {
    if (!allRequiredFieldsFilled) {
      setError(t('not_all_required_fields'));
      return;
    }

    setChildren(<PublishModalForm id={id} />)
    setIsOpen(true);
  }, [id]);

  const archiveProperty = useCallback(() => {
    setChildren(<ArchiveModalForm id={id} status={status} />)
    setIsOpen(true);
  }, [id, status]);

    const deleteProperty = useCallback(() => {
        setChildren(<DeleteModalForm id={id} />);
        setIsOpen(true);
    }, [id]);

  switch (status) {
      case 'DRAFT':
        return (
          <div className="flex items-center gap-[10px]">
            <button
              onClick={publishProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="draft-publish"
              data-tooltip-content={t('publish_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <PublishIcon width={24} height={24} />
            </button>
            <Tooltip id="draft-publish" />
            <button
              onClick={editProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="draft-edit"
              data-tooltip-content={t('edit_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <EditIcon width={24} height={24}/>
            </button>
            <Tooltip id="draft-edit" />
          </div>
        )
      case 'IN_MODERATION':
        return (
          <div className="flex items-center gap-[10px]">
            <button
              onClick={editProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="moderation-edit"
              data-tooltip-content={t('edit_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <EditIcon width={24} height={24}/>
            </button>
            <Tooltip id="moderation-edit" />
            <button
              onClick={archiveProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="moderation-archive"
              data-tooltip-content={t('move_to_archive')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <CrossIcon width={24} height={24} color="black" />
            </button>
            <Tooltip id="moderation-archive" />
          </div>
        )
      case 'UNPAID':
        return (
          <div className="flex items-center gap-[10px]">
            <button
              onClick={publishProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="unpaid-publish"
              data-tooltip-content={t('publish_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <WalletIcon width={24} height={24} />
            </button>
            <Tooltip id="unpaid-publish" />
            <button
              onClick={editProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="unpaid-edit"
              data-tooltip-content={t('edit_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <EditIcon width={24} height={24}/>
            </button>
            <Tooltip id="unpaid-edit" />
          </div>
        )
      case 'ACTIVE':
        return (
          <div className="flex items-center gap-[10px]">
            <button
              onClick={archiveProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="active-archive"
              data-tooltip-content={t('move_to_archive')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <ArchiveIcon width={24} height={24} color="black" />
            </button>
            <Tooltip id="active-archive" />
            <button
              onClick={editProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="active-edit"
              data-tooltip-content={t('edit_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <EditIcon width={24} height={24}/>
            </button>
            <Tooltip id="active-edit" />
          </div>
        )
      case 'ARCHIVED':
        return (
          <div className="flex items-center gap-[10px]">
              <button
                  onClick={deleteProperty}
                  className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
                  data-tooltip-id="archive-delete"
                  data-tooltip-content={t('delete_property')}
                  data-tooltip-place="top-end"
                  data-tooltip-class-name="!rounded-[8px] !bg-blue"
              >
                  <ThrashCan width={24} height={24} color="orange" />
              </button>
              <Tooltip id="archive-delete" />
              <button
              onClick={unArchiveProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="archived-publish"
              data-tooltip-content={t('publish_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <PublishIcon width={24} height={24} />
            </button>
            <Tooltip id="archived-publish" />
            <button
              onClick={editProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="archived-edit"
              data-tooltip-content={t('edit_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <EditIcon width={24} height={24}/>
            </button>
            <Tooltip id="archived-edit" />
          </div>
        )
      case 'REJECTED':
        return (
          <div className="flex items-center gap-[10px]">
            <button
              onClick={archiveProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="rejected-archive"
              data-tooltip-content={t('move_to_archive')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <ArchiveIcon width={24} height={24} color="black" />
            </button>
            <Tooltip id="rejected-archive" />
            <button
              onClick={editProperty}
              className="bg-white rounded-[10px] w-[42px] h-[42px] flex-center"
              data-tooltip-id="rejected-edit"
              data-tooltip-content={t('edit_property')}
              data-tooltip-place="top-end"
              data-tooltip-class-name="!rounded-[8px] !bg-blue"
            >
              <EditIcon width={24} height={24}/>
            </button>
            <Tooltip id="rejected-edit" />
          </div>
        )
      default:
        return <></>
    }
}

export default PropertyActionsAgent;
