import { create } from "zustand";

import { ICreateAccountBase } from "../types/form";

interface ICreateAccountStore {
  base: ICreateAccountBase,
  handleBaseStep: (baseData: ICreateAccountBase) => void;
  resetStore: () => void;
}

const useCreateAccountStore = create<ICreateAccountStore>((set) => ({
  base: {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    legalName: '',
    address: '',
    ic: '',
    companyPhone: '',
  },
  handleBaseStep: (baseData: ICreateAccountBase) => set((prevState) => ({
    ...prevState,
    base: baseData,
  })),
  resetStore: () => set (() => ({
    base: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      legalName: '',
      address: '',
      ic: '',
      companyPhone: '',
    },
  })),
}))


export default useCreateAccountStore;
