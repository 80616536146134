import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const ArrowBackIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill={color || "#000"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"/>
    </svg>
  );
}

export default ArrowBackIcon;
