import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { postMediaUploadImage } from "../../../api/services/media/requests";
import { putPropertyByIdGallery } from "../../../api/services/property/requests";

import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";
import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

import usePropertyImagesStore from "../stores/usePropertyImagesStore";

import ImagesDropzone from "./ImagesDropzone";

import PinIcon from "../../../common/assets/icons/PinIcon";

interface IPropertyEditPhotosProps {
  propertyId?: string;
}

const PropertyEditPhotos: FC<IPropertyEditPhotosProps> = ({ propertyId }) => {
  const { t } = useTranslation();

  const { setError } = useGlobalErrorStore();
  const { setLoader } = useGlobalLoaderStore();

  const {
    gallery,
    setGallery,
    addGalleryImages,
    deleteGalleryImage,
    mainImage,
    setMainImage,
  } = usePropertyImagesStore();

  const onDrop = useCallback(async (files: File[]) => {
    if(!propertyId) return;
    let uploadErrors: any[] = [];

    if (FileReader && files && files.length) {
      const uploadPromises = files.map((file) => {
        return new Promise<any>((resolve, reject) => {
          if (file.size >= 5242880) {
            uploadErrors.push(t('image_file_too_big', { fileName: file.name }))
            reject();
            return;
          }

          const fr = new FileReader();
          fr.onload = async function () {
            try {
              const image = await postMediaUploadImage({ image: file });
              resolve(image);
            } catch (error: any) {
              uploadErrors.push(error?.response?.data?.message || t('unknown_error'));
              reject();
            }
          };
          fr.readAsDataURL(file);
        });
      });

      try {
        setLoader(true);
        const results = await Promise.allSettled(uploadPromises);

        const images = results
          .filter(result => result.status === 'fulfilled')
          .map(result => (result as PromiseFulfilledResult<any>).value);
        setError(uploadErrors);

        if (images.length > 0) {
          const mainImageId = mainImage?.id || images[0]?.id || null;
          const collateralImages = gallery.concat(images).filter(image => image.id !== mainImageId);
          const imagePayload: {
            mainImage: string | undefined;
            images: { id: string }[] | undefined;
          } = {
            mainImage: mainImageId,
            images: collateralImages.map((image) => ({
              id: image.id
            }))
          };

          const newProperty = await putPropertyByIdGallery(propertyId, imagePayload);
          const galleryImages = newProperty.gallery?.map((g: any) => g.image);
          setGallery(galleryImages || []);
          setMainImage(newProperty.mainImage);
        }
      } catch (error: any) {
        uploadErrors.push(error?.response?.data?.message || t('unknown_error'))
        setError(uploadErrors);
      } finally {
        setLoader(false);
      }
    }
  }, [mainImage, gallery, propertyId]);

  const handleMainImageChange = (image: any) => {
    if(!image) return;
    if (mainImage) {
      addGalleryImages(mainImage);
    }
    setMainImage(image);
    deleteGalleryImage(image.id);
  }

  const handleGalleryDeleteClick = (id: string) => {
    deleteGalleryImage(id);
  }

  const handleMainDeleteClick = () => {
    setMainImage(null);
  }

  return (
    <div className="grid grid-cols-[auto_1fr] gap-[24px]">
      <div className="w-[170px] h-[170px]">
        <ImagesDropzone key={propertyId} onDrop={onDrop} />
      </div>
      <div className="grid grid-cols-4 gap-[24px]">
        {!!mainImage && (
          <div className="relative aspect-[2] group">
            <div
              className="absolute top-[6px] left-[6px] bg-[#eeeeee] text-[11px] text-[#666] font-medium px-[4px] py-[2px] rounded-[5px] flex-center gap-[4px] h-[21px]">
              <PinIcon/>
              <div>{t('main_photo')}</div>
            </div>
            <div
              className="absolute top-[6px] right-[6px] text-[18px] text-[red] font-extrabold hidden group-hover:block cursor-pointer leading-[1]"
              onClick={() => handleMainDeleteClick()}
            >x
            </div>
            <img
              src={mainImage.src || mainImage.url}
              alt="property image"
              className="aspect-[2] rounded-[10px] object-cover w-full"
            />
          </div>
        )}
        {gallery.map((image, index) => {
          const { id } = image;
          return (
            <div key={`${id}_${index}`} className="relative aspect-[2] group">
              <div
                className="absolute top-[6px] left-[6px] bg-[#eeeeee] text-[11px] text-[#666] font-medium px-[4px] py-[2px] rounded-[5px] hidden group-hover:flex justify-center items-center cursor-pointer h-[21px]"
                onClick={() => handleMainImageChange(image)}
              >
                <PinIcon/>
              </div>

              <div
                className="absolute top-[6px] right-[6px] text-[18px] text-[red] font-extrabold hidden group-hover:block cursor-pointer leading-[1]"
                onClick={() => handleGalleryDeleteClick(id)}
              >x
              </div>

              <img
                src={image.src || image.url}
                alt="property image"
                className="aspect-[2] rounded-[10px] object-cover w-full"
              />
            </div>
          )
        })}
      </div>
    </div>
  );
}

export default PropertyEditPhotos;
