import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const PhoneIcon: FC<ISvgProps> = ({ color = "#374957"}) => {
  return (
    <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.6994 14.4777C41.8202 13.4645 40.7335 12.652 39.513 12.0952C38.2924 11.5385 36.9665 11.2505 35.625 11.2508H28.1456L28.7756 7.42395C28.9985 6.07632 28.7296 4.69346 28.0179 3.52759C27.3061 2.36172 26.199 1.49054 24.8985 1.07294C23.5979 0.655338 22.1906 0.719144 20.9332 1.25272C19.6758 1.7863 18.6521 2.75409 18.0487 3.97958L14.46 11.2508H9.375C6.88951 11.2538 4.50667 12.2425 2.74916 14C0.991654 15.7575 0.00297723 18.1403 0 20.6258L0 30.0008C0.00297723 32.4863 0.991654 34.8692 2.74916 36.6267C4.50667 38.3842 6.88951 39.3729 9.375 39.3758H34.3125C36.569 39.3666 38.7472 38.5477 40.4509 37.068C42.1545 35.5884 43.2705 33.5463 43.5956 31.3133L44.9175 21.9383C45.1037 20.6085 45.002 19.2541 44.6193 17.9669C44.2366 16.6798 43.5818 15.4899 42.6994 14.4777ZM3.75 30.0008V20.6258C3.75 19.134 4.34263 17.7032 5.39752 16.6484C6.45242 15.5935 7.88316 15.0008 9.375 15.0008H13.125V35.6258H9.375C7.88316 35.6258 6.45242 35.0332 5.39752 33.9783C4.34263 32.9234 3.75 31.4927 3.75 30.0008ZM41.1956 21.4115L39.8719 30.7865C39.6783 32.1252 39.0107 33.35 37.9905 34.2381C36.9702 35.1262 35.6651 35.6186 34.3125 35.6258H16.875V14.5021C17.0517 14.3481 17.198 14.1624 17.3062 13.9546L21.4106 5.63895C21.5645 5.36132 21.7821 5.12418 22.0456 4.94701C22.309 4.76984 22.6106 4.6577 22.9258 4.61981C23.241 4.58193 23.5607 4.61937 23.8585 4.72907C24.1564 4.83877 24.424 5.01758 24.6394 5.25083C24.8236 5.46504 24.9583 5.71727 25.0338 5.98952C25.1093 6.26177 25.1238 6.54733 25.0763 6.82583L24.0863 12.8258C24.0428 13.0939 24.0581 13.3682 24.1311 13.6298C24.2041 13.8914 24.3331 14.134 24.5091 14.3409C24.6851 14.5477 24.9039 14.7139 25.1504 14.8278C25.3969 14.9418 25.6653 15.0008 25.9369 15.0008H35.625C36.4301 15.0007 37.2259 15.1735 37.9585 15.5074C38.6912 15.8413 39.3436 16.3285 39.8717 16.9363C40.3998 17.544 40.7913 18.258 41.0197 19.0301C41.2481 19.8021 41.3081 20.6142 41.1956 21.4115Z"
        fill={color} />
    </svg>

  );
}

export default PhoneIcon;
