import { FC, ReactNode } from "react";

interface IPropertyWrapperProps {
  children?: ReactNode;
  label?: string;
  icon?: ReactNode;
}

const PropertyFieldWrapper: FC<IPropertyWrapperProps> = ({ children, label, icon }) => {
  return (
    <div>
      <div className="flex items-center gap-[5px]">
        {icon}
        <div className="text-[#333] text-[18px] leading-5 font-bold">{label}</div>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}

export default PropertyFieldWrapper;
