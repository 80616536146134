import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getPropertyOwned } from "../../../api/services/property/requests";

import { TPropertyStatus } from "../../../common/types/dto/property";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../../common/stores/useAuthStore";

import CardWrapper from "../../../common/components/CardWrapper/CardWrapper";
import PropertyCard from "../../../common/components/PropertyCard/PropertyCard";

import NoProperties from "../../../features/property/components/NoProperties";

import ChevronIcon from "../../../common/assets/icons/ChevronIcon";

const PROPERTIES_PER_PAGE = 6;

const DashboardActiveProperties = () => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const { user } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data: properties } = useQuery(["properties", user, page], async () => {
      setLoader(true);

      const payload = {
        page: page,
        limit: PROPERTIES_PER_PAGE,
        status: 'ACTIVE' as TPropertyStatus,
      }

      let data = await getPropertyOwned(payload);

      setLoader(false);
      return data;
    },
    {
      enabled: !!user,
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  const totalPages = !!properties?.total && properties.total > properties.limit && Math.ceil(properties.total / PROPERTIES_PER_PAGE) || 0;

  const next = () => {
    if (page >= totalPages) return;

    setPage(page + 1);
  };

  const previous = () => {
    if (page <= 1) return;

    setPage(page - 1);
  };

  return (
    <CardWrapper>
      <div className="flex justify-between items-center mb-5">
        <div className="text-[#11142d] text-[18px] font-semibold">{t("active_listings")}</div>
        {totalPages > 1 && (
          <div className="flex items-center gap-5">
            <button
              className="flex-center w-[38px] h-[38px] rounded-full bg-[#FCFCFC] shadow-[0px_6px_11px_0px_#857B7B1A]"
              onClick={previous}>
              <ChevronIcon width={24} height={24} color={page <= 1 ? '#ccc' : '#000'}/>
            </button>
            <button
              className="flex-center w-[38px] h-[38px] rounded-full bg-[#FCFCFC] shadow-[0px_6px_11px_0px_#857B7B1A]"
              onClick={next}>
              <ChevronIcon width={24} height={24} color={page >= totalPages ? '#ccc' : '#000'} className="transform rotate-180"/>
            </button>
          </div>
        )}
      </div>
      <div className="grid grid-cols-3 gap-x-[56px] gap-y-5 pb-7">
        {properties?.items.map((property) => (
          <div key={property.id}>
            <Link to={`/property/${property.id}`}>
              <PropertyCard property={property}/>
            </Link>
          </div>
        ))}
      </div>
      {properties?.items.length === 0 && (
        <div>
          <NoProperties />
        </div>
      )}
    </CardWrapper>
  );
}

export default DashboardActiveProperties;
