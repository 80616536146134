import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const ChatNoMessagesImage: FC<ISvgProps> = () => {
  return (
    <svg width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M500 382.4H0V382.65H500V382.4Z" fill="#EBEBEB"/>
      <path d="M449.9 398.49H416.78V398.74H449.9V398.49Z" fill="#EBEBEB"/>
      <path d="M301.44 398.62H292.75V398.87H301.44V398.62Z" fill="#EBEBEB"/>
      <path d="M415.77 389.21H354.33V389.46H415.77V389.21Z" fill="#EBEBEB"/>
      <path d="M225.14 395.11H212.45V395.36H225.14V395.11Z" fill="#EBEBEB"/>
      <path d="M203.55 395.11H166.72V395.36H203.55V395.11Z" fill="#EBEBEB"/>
      <path d="M77.89 390.89H52.46V391.14H77.89V390.89Z" fill="#EBEBEB"/>
      <path d="M158.78 390.89H83.8896V391.14H158.78V390.89Z" fill="#EBEBEB"/>
      <path d="M237 337.8H43.91C42.3973 337.797 40.9475 337.195 39.8788 336.124C38.8102 335.053 38.21 333.603 38.21 332.09V60.66C38.2231 59.156 38.8292 57.718 39.8964 56.6582C40.9637 55.5985 42.4059 55.0026 43.91 55H237C238.514 55 239.967 55.6016 241.038 56.6724C242.108 57.7433 242.71 59.1956 242.71 60.71V332.09C242.71 333.604 242.108 335.057 241.038 336.128C239.967 337.198 238.514 337.8 237 337.8ZM43.91 55.2C42.4636 55.2026 41.0774 55.7791 40.0556 56.8027C39.0338 57.8264 38.46 59.2136 38.46 60.66V332.09C38.46 333.536 39.0338 334.924 40.0556 335.947C41.0774 336.971 42.4636 337.547 43.91 337.55H237C238.447 337.547 239.834 336.971 240.858 335.948C241.881 334.924 242.457 333.537 242.46 332.09V60.66C242.457 59.2127 241.881 57.8255 240.858 56.8021C239.834 55.7787 238.447 55.2026 237 55.2H43.91Z" fill="#EBEBEB"/>
      <path d="M453.31 337.8H260.21C258.696 337.797 257.246 337.195 256.175 336.125C255.105 335.054 254.503 333.604 254.5 332.09V60.66C254.516 59.1551 255.124 57.7171 256.193 56.6576C257.262 55.5981 258.705 55.0025 260.21 55H453.31C454.812 55.0052 456.252 55.6022 457.317 56.6617C458.382 57.7212 458.987 59.1578 459 60.66V332.09C459 333.601 458.401 335.05 457.335 336.121C456.268 337.191 454.821 337.795 453.31 337.8ZM260.21 55.2C258.763 55.2026 257.375 55.7787 256.352 56.8021C255.329 57.8255 254.753 59.2127 254.75 60.66V332.09C254.753 333.537 255.329 334.924 256.352 335.948C257.375 336.971 258.763 337.547 260.21 337.55H453.31C454.757 337.547 456.145 336.971 457.168 335.948C458.191 334.924 458.767 333.537 458.77 332.09V60.66C458.767 59.2127 458.191 57.8255 457.168 56.8021C456.145 55.7787 454.757 55.2026 453.31 55.2H260.21Z" fill="#EBEBEB"/>
      <path d="M190.78 249.51L433.82 249.51V71.9798L190.78 71.9798L190.78 249.51Z" fill="#E6E6E6"/>
      <path d="M180.04 249.51L431.33 249.51V71.9798L180.04 71.9798L180.04 249.51Z" fill="#F0F0F0"/>
      <path d="M416.28 234.47V87.0195L195.07 87.0195V234.47H416.28Z" fill="#FAFAFA"/>
      <path d="M362.51 234.46L312.15 87.0195H264.06L314.42 234.46H362.51Z" fill="white"/>
      <path d="M304.09 234.46L253.73 87.0195H234.99L285.35 234.46H304.09Z" fill="white"/>
      <path d="M196.99 234.47V87.0195H195.08V234.47H196.99Z" fill="#E6E6E6"/>
      <path d="M64.5697 201.22H150.39L150.39 79.5497L64.5697 79.5497L64.5697 201.22Z" fill="#E0E0E0"/>
      <path d="M58.5 201.22H146.96L146.96 79.5497L58.5 79.5497L58.5 201.22Z" fill="#F5F5F5"/>
      <path d="M138.23 191.62V89.1299H67.2305V191.62H138.23Z" fill="white"/>
      <path d="M97.9301 286.4C104.3 270.68 119.45 264.83 124.69 254.9C131.54 241.9 131.5 222.39 125.69 215.36C120.61 209.24 103.81 250.6 101.92 255.22C96.7349 267.689 93.4906 280.879 92.3001 294.33C92.4401 284.62 92.5701 275.7 92.6801 268.82C93.0401 246.38 93.4801 239.16 93.6801 236.94C94.3801 235.85 95.2101 234.59 95.6801 233.94C99.7501 228.21 108.77 221.38 113.28 213.05C119.86 200.88 118.8 186.32 114.18 180.9C109.35 175.23 100.82 188.63 96.2901 201.9C91.7601 215.17 93.6901 227.9 92.4201 238.9C92.1601 241.06 91.8801 249.27 91.6301 260.67C90.2501 248.41 87.5101 240.2 82.4401 232.54C76.2001 223.13 70.8101 211.67 65.6701 213.15C60.5301 214.63 63.2901 229.8 67.3601 240.44C71.0001 249.92 88.8501 259.38 91.4801 267C91.1701 282.44 90.8901 302 90.7001 320.23C89.2901 310.82 90.2601 310.03 84.4801 295.62C81.0501 287.06 67.0001 276.5 65.3001 282.38C63.6001 288.26 66.1701 299.38 71.4801 307.18C75.7701 313.46 85.2801 313.45 87.4801 317.35C87.9901 318.26 89.6901 321.75 90.6601 323.75C90.4301 345.99 90.3301 365.63 90.4401 372.32L91.3601 372.39C91.3601 370.66 91.4301 359.39 91.6101 344.39C93.9201 336.39 109.47 331.78 114.27 323.49C119.27 314.82 122.91 304.7 120.75 297.93C118.95 292.28 104.75 299.93 99.0401 309.32C95.2101 315.56 92.8701 322.21 91.7801 331.15C91.9101 320.95 92.0601 309.99 92.2001 299.65C93.3701 297.05 96.0001 291.11 97.9301 286.4Z" fill="#E6E6E6"/>
      <path d="M107.06 382.4H74.3796C73.516 382.399 72.6792 382.1 72.0105 381.553C71.3419 381.007 70.8824 380.246 70.7096 379.4L68.3896 368H113.06L110.74 379.43C110.557 380.271 110.093 381.024 109.423 381.564C108.754 382.104 107.92 382.399 107.06 382.4Z" fill="#F0F0F0"/>
      <path d="M114 372.39H67.45C66.9108 372.388 66.3909 372.189 65.989 371.83C65.5871 371.47 65.3312 370.976 65.27 370.44L64.27 361.99C64.2363 361.683 64.2675 361.373 64.3617 361.079C64.4558 360.785 64.6107 360.514 64.8163 360.284C65.0218 360.054 65.2735 359.87 65.555 359.743C65.8365 359.617 66.1414 359.551 66.45 359.55H114.89C115.199 359.549 115.505 359.614 115.787 359.74C116.069 359.866 116.322 360.05 116.527 360.281C116.733 360.511 116.888 360.783 116.982 361.077C117.075 361.372 117.105 361.683 117.07 361.99L116.07 370.44C116.012 370.957 115.771 371.437 115.392 371.794C115.013 372.151 114.52 372.362 114 372.39Z" fill="#F0F0F0"/>
      <path d="M405.63 375.11H415.62V267.62H405.63V375.11Z" fill="#F0F0F0"/>
      <path d="M202.06 382.27H411.79V375.11H202.06V382.27Z" fill="#F0F0F0"/>
      <path d="M405.64 267.62H198.23V375.11H405.64V267.62Z" fill="#F5F5F5"/>
      <path d="M407.99 267.62H418.31V260.84H407.99V267.62Z" fill="#E0E0E0"/>
      <path d="M407.99 260.84H195.54V267.62H407.99V260.84Z" fill="#EBEBEB"/>
      <path d="M390.75 314H213.11C210.989 314 208.954 313.157 207.453 311.657C205.953 310.157 205.11 308.122 205.11 306V283C205.11 280.878 205.953 278.843 207.453 277.343C208.954 275.843 210.989 275 213.11 275H390.75C392.872 275 394.907 275.843 396.407 277.343C397.908 278.843 398.75 280.878 398.75 283V306C398.75 308.122 397.908 310.157 396.407 311.657C394.907 313.157 392.872 314 390.75 314ZM213.11 277C211.519 277 209.993 277.632 208.868 278.757C207.742 279.883 207.11 281.409 207.11 283V306C207.11 307.591 207.742 309.117 208.868 310.243C209.993 311.368 211.519 312 213.11 312H390.75C392.342 312 393.868 311.368 394.993 310.243C396.118 309.117 396.75 307.591 396.75 306V283C396.75 281.409 396.118 279.883 394.993 278.757C393.868 277.632 392.342 277 390.75 277H213.11Z" fill="white"/>
      <path d="M390.75 362.19H213.11C210.989 362.19 208.954 361.348 207.453 359.847C205.953 358.347 205.11 356.312 205.11 354.19V331.19C205.11 329.069 205.953 327.034 207.453 325.534C208.954 324.033 210.989 323.19 213.11 323.19H390.75C392.872 323.19 394.907 324.033 396.407 325.534C397.908 327.034 398.75 329.069 398.75 331.19V354.19C398.75 356.312 397.908 358.347 396.407 359.847C394.907 361.348 392.872 362.19 390.75 362.19ZM213.11 325.11C211.519 325.11 209.993 325.743 208.868 326.868C207.742 327.993 207.11 329.519 207.11 331.11V354.11C207.11 355.702 207.742 357.228 208.868 358.353C209.993 359.478 211.519 360.11 213.11 360.11H390.75C392.342 360.11 393.868 359.478 394.993 358.353C396.118 357.228 396.75 355.702 396.75 354.11V331.11C396.75 329.519 396.118 327.993 394.993 326.868C393.868 325.743 392.342 325.11 390.75 325.11H213.11Z" fill="white"/>
      <path d="M350.38 298H253.48C252.792 298 252.132 297.727 251.645 297.242C251.158 296.756 250.883 296.098 250.88 295.41V293.51C250.883 292.822 251.158 292.163 251.645 291.678C252.132 291.192 252.792 290.92 253.48 290.92H350.38C351.068 290.92 351.728 291.192 352.215 291.678C352.702 292.163 352.977 292.822 352.98 293.51V295.41C352.977 296.098 352.702 296.756 352.215 297.242C351.728 297.727 351.068 298 350.38 298ZM253.48 293C253.323 293.002 253.174 293.065 253.062 293.175C252.95 293.285 252.885 293.433 252.88 293.59V295.49C252.885 295.646 252.95 295.795 253.062 295.905C253.174 296.015 253.323 296.077 253.48 296.08H350.38C350.537 296.08 350.688 296.018 350.801 295.908C350.913 295.797 350.977 295.647 350.98 295.49V293.59C350.977 293.433 350.913 293.282 350.801 293.172C350.688 293.062 350.537 293 350.38 293H253.48Z" fill="white"/>
      <path d="M350.38 346.19H253.48C252.792 346.19 252.132 345.918 251.645 345.432C251.158 344.947 250.883 344.288 250.88 343.6V341.7C250.883 341.013 251.158 340.354 251.645 339.868C252.132 339.383 252.792 339.11 253.48 339.11H350.38C351.068 339.11 351.728 339.383 352.215 339.868C352.702 340.354 352.977 341.013 352.98 341.7V343.6C352.977 344.288 352.702 344.947 352.215 345.432C351.728 345.918 351.068 346.19 350.38 346.19ZM253.48 341.11C253.323 341.113 253.174 341.175 253.062 341.285C252.95 341.395 252.885 341.544 252.88 341.7V343.6C252.885 343.757 252.95 343.906 253.062 344.015C253.174 344.125 253.323 344.188 253.48 344.19H350.38C350.537 344.19 350.688 344.129 350.801 344.018C350.913 343.908 350.977 343.758 350.98 343.6V341.7C350.977 341.543 350.913 341.393 350.801 341.283C350.688 341.172 350.537 341.11 350.38 341.11H253.48Z" fill="white"/>
      <path d="M245 427.56C352.083 427.56 438.89 422.492 438.89 416.24C438.89 409.988 352.083 404.92 245 404.92C137.918 404.92 51.1104 409.988 51.1104 416.24C51.1104 422.492 137.918 427.56 245 427.56Z" fill="#F5F5F5"/>
      <path d="M374.791 167.753L374.41 173.641L375.408 173.705L375.789 167.817L374.791 167.753Z" fill="#006CFB"/>
      <path d="M376.308 143.84L375.061 163.189L376.058 163.254L377.306 143.904L376.308 143.84Z" fill="#006CFB"/>
      <path d="M364.44 115.74H246.89C243.872 115.793 240.981 116.968 238.783 119.037C236.585 121.106 235.236 123.92 235 126.93L219.81 361.43C219.699 362.867 219.888 364.312 220.365 365.672C220.843 367.033 221.597 368.279 222.582 369.332C223.567 370.386 224.759 371.223 226.084 371.79C227.41 372.358 228.839 372.644 230.28 372.63H347.81C350.834 372.58 353.731 371.404 355.935 369.333C358.139 367.263 359.492 364.445 359.73 361.43L374.91 126.93C375.021 125.493 374.832 124.049 374.355 122.689C373.878 121.329 373.123 120.083 372.138 119.031C371.153 117.978 369.96 117.142 368.635 116.576C367.31 116.009 365.881 115.724 364.44 115.74Z" fill="#006CFB"/>
      <path opacity="0.7" d="M360.71 115.74H246.89C243.872 115.793 240.981 116.968 238.783 119.037C236.585 121.106 235.236 123.92 235 126.93L219.81 361.43C219.699 362.867 219.888 364.312 220.365 365.672C220.843 367.033 221.597 368.279 222.582 369.332C223.567 370.386 224.759 371.223 226.084 371.79C227.41 372.358 228.839 372.644 230.28 372.63H344.08C347.104 372.58 350.001 371.404 352.205 369.333C354.409 367.263 355.762 364.445 356 361.43L371.18 126.93C371.291 125.493 371.102 124.049 370.625 122.689C370.148 121.329 369.393 120.083 368.408 119.031C367.423 117.978 366.23 117.142 364.905 116.576C363.58 116.009 362.151 115.724 360.71 115.74Z" fill="white"/>
      <path d="M351.11 355.3H225.91C225.442 355.301 224.979 355.206 224.549 355.02C224.119 354.835 223.732 354.563 223.411 354.222C223.09 353.88 222.843 353.477 222.685 353.036C222.527 352.596 222.46 352.127 222.49 351.66L236.23 139.46C236.286 138.593 236.67 137.78 237.303 137.186C237.936 136.592 238.772 136.261 239.64 136.26H364.85C365.319 136.26 365.783 136.357 366.213 136.543C366.643 136.73 367.03 137.003 367.35 137.345C367.671 137.687 367.918 138.091 368.076 138.532C368.234 138.973 368.301 139.442 368.27 139.91L354.53 352.1C354.475 352.968 354.09 353.783 353.454 354.377C352.819 354.972 351.981 355.302 351.11 355.3Z" fill="white"/>
      <path d="M323.29 129.32H277.78C277.546 129.322 277.314 129.275 277.099 129.183C276.884 129.09 276.69 128.954 276.53 128.783C276.37 128.612 276.248 128.41 276.17 128.189C276.093 127.968 276.062 127.733 276.08 127.5C276.126 127.015 276.348 126.563 276.703 126.23C277.058 125.897 277.523 125.705 278.01 125.69H323.52C323.754 125.686 323.986 125.731 324.201 125.823C324.416 125.914 324.61 126.049 324.77 126.219C324.93 126.39 325.053 126.592 325.13 126.812C325.208 127.032 325.238 127.267 325.22 127.5C325.176 127.986 324.956 128.44 324.6 128.776C324.245 129.111 323.779 129.305 323.29 129.32Z" fill="white"/>
      <path d="M287.77 364.21C287.697 365.409 287.172 366.535 286.302 367.362C285.432 368.19 284.281 368.657 283.08 368.67C282.503 368.675 281.932 368.562 281.401 368.336C280.87 368.111 280.391 367.779 279.994 367.36C279.598 366.942 279.291 366.446 279.094 365.904C278.897 365.362 278.814 364.786 278.85 364.21C278.926 363.013 279.451 361.889 280.321 361.064C281.191 360.239 282.341 359.773 283.54 359.76C284.116 359.753 284.687 359.866 285.218 360.09C285.749 360.314 286.228 360.645 286.625 361.063C287.022 361.481 287.328 361.976 287.525 362.517C287.722 363.059 287.806 363.635 287.77 364.21Z" fill="white"/>
      <path opacity="0.2" d="M337.47 195H230.56L223.76 299.9H337.47C340.437 299.895 343.281 298.714 345.38 296.617C347.479 294.52 348.662 291.677 348.67 288.71V206.23C348.672 203.256 347.494 200.403 345.394 198.297C343.294 196.191 340.444 195.005 337.47 195Z" fill="#006CFB"/>
      <path d="M327.51 185.71H196.49C190.304 185.71 185.29 190.724 185.29 196.91V279.42C185.29 285.606 190.304 290.62 196.49 290.62H327.51C333.696 290.62 338.71 285.606 338.71 279.42V196.91C338.71 190.724 333.696 185.71 327.51 185.71Z" fill="#006CFB"/>
      <path d="M181.71 184.499C181.448 184.498 181.196 184.394 181.01 184.209L179.21 182.419C179.022 182.231 178.916 181.975 178.916 181.709C178.916 181.443 179.022 181.187 179.21 180.999C179.398 180.811 179.654 180.705 179.92 180.705C180.186 180.705 180.442 180.811 180.63 180.999L182.42 182.799C182.606 182.987 182.711 183.24 182.711 183.504C182.711 183.768 182.606 184.022 182.42 184.209C182.327 184.302 182.216 184.375 182.094 184.425C181.972 184.475 181.842 184.5 181.71 184.499Z" fill="#006CFB"/>
      <path d="M179 190.779H176.47C176.205 190.779 175.95 190.674 175.763 190.486C175.575 190.299 175.47 190.045 175.47 189.779C175.47 189.514 175.575 189.26 175.763 189.072C175.95 188.885 176.205 188.779 176.47 188.779H179C179.265 188.779 179.519 188.885 179.707 189.072C179.894 189.26 180 189.514 180 189.779C180 190.045 179.894 190.299 179.707 190.486C179.519 190.674 179.265 190.779 179 190.779Z" fill="#006CFB"/>
      <path d="M188.08 181.8C187.827 181.8 187.582 181.704 187.397 181.532C187.211 181.359 187.098 181.122 187.08 180.87L186.9 178.34C186.891 178.208 186.907 178.076 186.949 177.951C186.99 177.826 187.056 177.711 187.143 177.611C187.229 177.512 187.334 177.43 187.452 177.372C187.57 177.313 187.699 177.278 187.83 177.27C188.094 177.253 188.353 177.342 188.552 177.516C188.751 177.69 188.872 177.936 188.89 178.2L189.08 180.73C189.09 180.861 189.073 180.993 189.031 181.118C188.99 181.243 188.924 181.358 188.837 181.458C188.751 181.557 188.646 181.639 188.528 181.697C188.41 181.756 188.282 181.791 188.15 181.8H188.08Z" fill="#006CFB"/>
      <path d="M273.189 279.829L313.419 320.049V279.829H273.189Z" fill="#006CFB"/>
      <path d="M211.869 247.999C211.622 248.001 211.377 247.941 211.159 247.824C210.941 247.707 210.755 247.537 210.619 247.329C205.896 240.253 204.178 231.591 205.841 223.247C207.504 214.904 212.414 207.563 219.489 202.839C219.821 202.619 220.226 202.54 220.616 202.619C221.007 202.697 221.349 202.928 221.569 203.259C221.79 203.591 221.869 203.996 221.79 204.386C221.711 204.776 221.481 205.119 221.149 205.339C214.744 209.629 210.304 216.287 208.804 223.849C207.304 231.411 208.867 239.259 213.149 245.669C213.371 246 213.452 246.404 213.375 246.794C213.298 247.184 213.069 247.528 212.739 247.749C212.482 247.919 212.178 248.006 211.869 247.999Z" fill="white"/>
      <path d="M218.22 243.74C217.974 243.738 217.732 243.677 217.516 243.56C217.3 243.443 217.116 243.275 216.98 243.07C213.385 237.68 212.077 231.083 213.342 224.728C214.608 218.374 218.344 212.782 223.73 209.18C223.894 209.07 224.078 208.994 224.272 208.956C224.465 208.917 224.665 208.917 224.858 208.956C225.052 208.994 225.236 209.071 225.4 209.18C225.564 209.29 225.705 209.431 225.815 209.595C225.924 209.759 226.001 209.943 226.039 210.137C226.078 210.331 226.078 210.53 226.039 210.723C226.001 210.917 225.924 211.101 225.815 211.265C225.705 211.429 225.564 211.57 225.4 211.68C220.672 214.835 217.392 219.738 216.28 225.312C215.168 230.885 216.315 236.672 219.47 241.4C219.691 241.73 219.772 242.135 219.695 242.525C219.618 242.915 219.39 243.258 219.06 243.48C218.811 243.646 218.519 243.737 218.22 243.74Z" fill="white"/>
      <path d="M298.35 269.09C298.015 269.089 297.689 268.976 297.426 268.768C297.162 268.561 296.976 268.271 296.896 267.945C296.817 267.619 296.849 267.276 296.987 266.971C297.126 266.665 297.363 266.415 297.66 266.26C304.511 262.72 309.677 256.604 312.021 249.257C314.364 241.91 313.695 233.933 310.16 227.08C310.052 226.904 309.982 226.708 309.954 226.503C309.926 226.299 309.94 226.091 309.996 225.893C310.051 225.694 310.147 225.509 310.278 225.35C310.408 225.19 310.57 225.059 310.753 224.964C310.936 224.87 311.137 224.814 311.343 224.801C311.549 224.788 311.755 224.817 311.949 224.887C312.143 224.957 312.32 225.066 312.47 225.208C312.62 225.349 312.739 225.52 312.82 225.71C314.751 229.455 315.925 233.544 316.276 237.743C316.627 241.942 316.147 246.169 314.863 250.183C313.58 254.196 311.518 257.918 308.797 261.134C306.075 264.351 302.746 267 299 268.93C298.797 269.029 298.576 269.083 298.35 269.09Z" fill="white"/>
      <path d="M294.84 262.299C294.505 262.298 294.179 262.185 293.916 261.978C293.652 261.77 293.466 261.48 293.386 261.155C293.307 260.829 293.339 260.486 293.477 260.18C293.616 259.875 293.853 259.624 294.15 259.469C296.653 258.179 298.876 256.408 300.694 254.258C302.511 252.108 303.887 249.621 304.742 246.939C305.598 244.256 305.916 241.432 305.679 238.626C305.441 235.821 304.654 233.09 303.36 230.589C303.177 230.235 303.142 229.823 303.263 229.443C303.384 229.063 303.651 228.747 304.005 228.564C304.359 228.381 304.771 228.346 305.151 228.467C305.531 228.588 305.847 228.855 306.03 229.209C307.504 232.06 308.402 235.173 308.672 238.371C308.942 241.569 308.579 244.788 307.603 247.846C306.628 250.903 305.06 253.738 302.988 256.189C300.917 258.64 298.382 260.658 295.53 262.129C295.318 262.243 295.081 262.301 294.84 262.299Z" fill="white"/>
      <path d="M203.36 125.85H195.72C195.521 125.855 195.331 125.93 195.183 126.062C195.035 126.193 194.938 126.373 194.91 126.57L193.31 141.81C193.298 141.902 193.307 141.995 193.334 142.083C193.362 142.172 193.408 142.253 193.469 142.322C193.531 142.391 193.606 142.446 193.691 142.484C193.775 142.521 193.867 142.54 193.96 142.54H201.6C201.799 142.534 201.991 142.458 202.139 142.324C202.288 142.19 202.384 142.008 202.41 141.81L204.01 126.57C204.021 126.478 204.013 126.386 203.985 126.298C203.958 126.21 203.911 126.129 203.85 126.061C203.788 125.992 203.712 125.938 203.628 125.902C203.543 125.865 203.452 125.848 203.36 125.85Z" fill="#006CFB"/>
      <path opacity="0.4" d="M202.67 125.85H195.72C195.521 125.855 195.331 125.93 195.183 126.062C195.035 126.194 194.938 126.374 194.91 126.57L193.31 141.81C193.298 141.902 193.307 141.995 193.334 142.084C193.362 142.172 193.408 142.253 193.469 142.323C193.531 142.392 193.606 142.447 193.691 142.484C193.775 142.522 193.867 142.541 193.96 142.54H200.96C201.158 142.535 201.348 142.458 201.495 142.324C201.642 142.19 201.736 142.008 201.76 141.81L203.36 126.57C203.374 126.475 203.366 126.378 203.337 126.286C203.308 126.194 203.258 126.11 203.191 126.041C203.125 125.971 203.043 125.918 202.953 125.885C202.862 125.852 202.765 125.84 202.67 125.85Z" fill="white"/>
      <path d="M165.07 407.65C166.07 407.65 167.07 407.5 167.42 407.04C167.505 406.917 167.551 406.77 167.551 406.62C167.551 406.47 167.505 406.324 167.42 406.2C167.274 405.976 167.049 405.815 166.79 405.75C165.43 405.38 162.67 407 162.55 407.07C162.519 407.09 162.494 407.119 162.48 407.153C162.466 407.187 162.462 407.224 162.47 407.26C162.477 407.3 162.496 407.336 162.525 407.365C162.554 407.394 162.59 407.413 162.63 407.42C163.435 407.565 164.251 407.642 165.07 407.65ZM166.29 406.08C166.413 406.064 166.537 406.064 166.66 406.08C166.819 406.119 166.958 406.215 167.05 406.35C167.19 406.59 167.15 406.71 167.05 406.77C166.7 407.3 164.65 407.31 163.14 407.1C164.11 406.55 165.181 406.203 166.29 406.08Z" fill="#006CFB"/>
      <path d="M162.61 407.42H162.69C163.69 406.98 165.51 405.23 165.32 404.31C165.32 404.1 165.11 403.83 164.58 403.78C164.393 403.759 164.203 403.777 164.022 403.832C163.842 403.887 163.674 403.978 163.53 404.1C162.53 404.95 162.42 407.1 162.41 407.22C162.409 407.255 162.417 407.29 162.432 407.321C162.448 407.353 162.471 407.38 162.5 407.4C162.534 407.417 162.572 407.424 162.61 407.42ZM164.45 404.14H164.56C164.91 404.14 164.94 404.31 164.94 404.35C165.06 404.9 163.8 406.28 162.83 406.88C162.892 405.962 163.242 405.087 163.83 404.38C164.004 404.232 164.222 404.147 164.45 404.14Z" fill="#006CFB"/>
      <path d="M161.79 388.479H153.69V407.229H161.79V388.479Z" fill="#FF8B7B"/>
      <path d="M94.9502 398.29L103.04 399.17L108.31 380.97L100.22 380.09L94.9502 398.29Z" fill="#FF8B7B"/>
      <path d="M106.8 401C107.8 401.26 108.8 401.37 109.25 401C109.36 400.898 109.435 400.764 109.463 400.616C109.492 400.469 109.473 400.316 109.41 400.18C109.369 400.056 109.304 399.94 109.218 399.841C109.132 399.741 109.027 399.659 108.91 399.6C107.66 398.91 104.56 399.78 104.43 399.81C104.392 399.821 104.358 399.843 104.333 399.873C104.308 399.903 104.293 399.941 104.29 399.98C104.29 400.02 104.301 400.059 104.322 400.093C104.344 400.126 104.374 400.153 104.41 400.17C105.182 400.514 105.981 400.792 106.8 401ZM108.38 399.8C108.501 399.83 108.619 399.873 108.73 399.93C108.878 400.001 108.993 400.126 109.05 400.28C109.13 400.55 109.05 400.65 109.05 400.71C108.57 401.12 106.56 400.63 105.13 400.05C106.187 399.759 107.291 399.678 108.38 399.81V399.8Z" fill="#006CFB"/>
      <path d="M104.45 400.19H104.53C105.53 400 107.83 398.78 107.87 397.86C107.87 397.64 107.79 397.34 107.28 397.16C107.1 397.095 106.909 397.067 106.719 397.077C106.528 397.088 106.342 397.136 106.17 397.22C104.99 397.79 104.33 399.85 104.3 399.93C104.283 399.958 104.274 399.991 104.274 400.025C104.274 400.058 104.283 400.091 104.3 400.12C104.317 400.143 104.34 400.162 104.366 400.174C104.392 400.186 104.421 400.192 104.45 400.19ZM107.05 397.5H107.17C107.5 397.62 107.49 397.76 107.49 397.8C107.49 398.36 105.89 399.37 104.79 399.7C105.067 398.835 105.614 398.081 106.35 397.55C106.57 397.449 106.818 397.431 107.05 397.5Z" fill="#006CFB"/>
      <path d="M103.47 398.42L94.6199 395.88C94.4679 395.835 94.3046 395.844 94.1585 395.906C94.0124 395.968 93.8926 396.079 93.8199 396.22L90.5599 402.69C90.4838 402.854 90.4447 403.033 90.4453 403.213C90.446 403.394 90.4864 403.572 90.5637 403.735C90.641 403.899 90.7534 404.043 90.8927 404.158C91.032 404.273 91.195 404.355 91.3699 404.4C94.4699 405.23 96.0199 405.49 99.91 406.6C102.3 407.29 107.17 408.95 110.48 409.9C113.79 410.85 115.01 407.74 113.73 407.06C107.99 404.01 106.15 401.55 104.63 399.27C104.357 398.857 103.946 398.556 103.47 398.42Z" fill="#263238"/>
      <path d="M161.7 406.29H152.86C152.701 406.288 152.546 406.344 152.425 406.447C152.304 406.55 152.224 406.693 152.2 406.85L150.91 414C150.881 414.178 150.891 414.361 150.939 414.535C150.988 414.709 151.073 414.871 151.19 415.009C151.307 415.147 151.453 415.257 151.617 415.334C151.78 415.41 151.959 415.45 152.14 415.45C155.33 415.39 159.93 415.2 163.94 415.2C168.63 415.2 171.19 415.46 176.69 415.46C180.01 415.46 180.79 412.1 179.38 411.79C172.99 410.41 169.3 410.26 163.68 406.88C163.083 406.513 162.4 406.31 161.7 406.29Z" fill="#263238"/>
      <path opacity="0.2" d="M161.79 388.479H153.69V398.149H161.79V388.479Z" fill="black"/>
      <path opacity="0.2" d="M100.22 380.1L108.31 380.97L105.38 391.07L97.5 389.48L100.22 380.1Z" fill="black"/>
      <path d="M148.469 202C148.469 202 143.999 263.14 139.999 286.32C135.589 311.71 109.929 389.45 109.929 389.45L95.2295 386.76C95.2295 386.76 113.759 313.76 115.809 288.62C118.039 261.34 120.719 205.11 120.719 205.11L148.469 202Z" fill="#006CFB"/>
      <path opacity="0.3" d="M140.94 227.4C141.74 224.04 143.58 228.27 145.72 235.6C144.1 253.74 141.99 274.75 139.97 286.33C139.52 288.92 138.84 292.06 137.97 295.62C138.74 269.74 138.77 236.49 140.94 227.4Z" fill="black"/>
      <path d="M163.74 200.34C163.74 200.34 169.61 261.8 169.81 286.49C170.02 312.18 164.73 396.28 164.73 396.28H151C151 396.28 146.88 313.68 145.17 288.45C143.3 260.95 135.43 203.45 135.43 203.45L163.74 200.34Z" fill="#006CFB"/>
      <path d="M113 384.5C113.06 384.5 110.69 389.83 110.69 389.83L94.1504 386.83L95.1504 381.88L113 384.5Z" fill="#263238"/>
      <path d="M167.13 390.81C167.19 390.81 166.6 396.51 166.6 396.51H149.92L149.24 391.43L167.13 390.81Z" fill="#263238"/>
      <path d="M121.12 145.56C120.02 147.69 118.81 149.93 117.71 152.14L114.35 158.8C113.26 161.02 112.19 163.25 111.16 165.48C110.13 167.71 109.16 169.93 108.33 172.15L108.25 168.65C108.835 170.447 109.536 172.204 110.35 173.91C110.74 174.79 111.24 175.63 111.67 176.5C112.1 177.37 112.67 178.2 113.18 179.02C114.241 180.715 115.389 182.353 116.62 183.93C117.24 184.72 117.86 185.53 118.52 186.29C119.18 187.05 119.9 187.84 120.52 188.49L120.72 188.71C121.181 189.215 121.446 189.869 121.469 190.553C121.492 191.237 121.271 191.906 120.845 192.442C120.419 192.977 119.816 193.343 119.145 193.475C118.473 193.606 117.777 193.495 117.18 193.16C116.147 192.577 115.145 191.94 114.18 191.25C113.18 190.59 112.3 189.9 111.4 189.17C109.599 187.692 107.907 186.084 106.34 184.36C104.74 182.607 103.286 180.728 101.99 178.74C100.659 176.714 99.5017 174.58 98.5302 172.36C98.1023 171.417 98.0277 170.352 98.3202 169.36L98.4602 168.89C99.2202 166.28 100.07 163.75 101.01 161.27C101.95 158.79 102.95 156.36 104.01 153.97C105.07 151.58 106.18 149.22 107.35 146.89C108.52 144.56 109.7 142.28 111.05 139.95C111.796 138.616 113.038 137.631 114.507 137.208C115.975 136.785 117.551 136.959 118.892 137.691C120.233 138.424 121.231 139.656 121.669 141.12C122.106 142.584 121.949 144.161 121.23 145.51L121.12 145.56Z" fill="#FF8B7B"/>
      <path d="M126.86 142C127.26 149.63 118.6 163.64 118.6 163.64L100.77 154.73C102.936 148.41 106.29 142.562 110.65 137.5C120.08 127.08 126.49 135 126.86 142Z" fill="#263238"/>
      <path d="M114.78 191.73L118.37 197.73L125.84 192.95C124.536 190.612 122.492 188.773 120.03 187.72C119.461 187.538 118.845 187.566 118.295 187.799C117.745 188.032 117.296 188.455 117.03 188.99L114.78 191.73Z" fill="#FF8B7B"/>
      <path d="M121.36 200.29L124.83 199.64C125.122 199.586 125.401 199.475 125.65 199.312C125.898 199.15 126.113 198.94 126.28 198.695C126.447 198.45 126.565 198.173 126.625 197.882C126.685 197.592 126.687 197.292 126.63 197L125.84 193L118.37 197.78L118.87 198.99C119.075 199.458 119.433 199.842 119.886 200.079C120.339 200.315 120.859 200.39 121.36 200.29Z" fill="#FF8B7B"/>
      <path d="M142.43 103.659C142.5 104.309 142.91 104.819 143.33 104.779C143.75 104.739 144.03 104.189 143.95 103.539C143.87 102.889 143.47 102.379 143.05 102.409C142.63 102.439 142.35 102.999 142.43 103.659Z" fill="#263238"/>
      <path d="M142.85 104.82C143.861 106.763 145.122 108.564 146.6 110.18C145.6 111.35 143.6 111.01 143.6 111.01L142.85 104.82Z" fill="#FF5652"/>
      <path d="M139.63 100.21C139.682 100.218 139.735 100.214 139.785 100.198C139.835 100.183 139.881 100.156 139.92 100.12C140.267 99.7954 140.688 99.5609 141.146 99.4371C141.605 99.3133 142.087 99.304 142.55 99.4101C142.597 99.4252 142.647 99.4304 142.696 99.4254C142.746 99.4203 142.794 99.4051 142.837 99.3806C142.88 99.3562 142.918 99.3232 142.947 99.2835C142.977 99.2438 142.999 99.1984 143.01 99.1501C143.035 99.0508 143.021 98.9456 142.971 98.8563C142.92 98.7669 142.838 98.7003 142.74 98.6701C142.155 98.5306 141.545 98.536 140.963 98.6859C140.381 98.8358 139.845 99.1256 139.4 99.5301C139.363 99.5656 139.333 99.6083 139.313 99.6556C139.293 99.7028 139.282 99.7537 139.282 99.8051C139.282 99.8565 139.293 99.9074 139.313 99.9547C139.333 100.002 139.363 100.045 139.4 100.08C139.428 100.116 139.462 100.146 139.502 100.168C139.541 100.191 139.585 100.205 139.63 100.21Z" fill="#263238"/>
      <path d="M122.27 110.9C124.18 116.69 126.72 127.36 123.1 131.64C123.1 131.64 125.48 137.47 136.44 136.54C148.51 135.54 141.44 130.09 141.44 130.09C134.66 129.09 134.2 124.2 134.76 119.53L122.27 110.9Z" fill="#FF8B7B"/>
      <path d="M120.51 133.75C119.38 131.94 118.12 129.87 120.26 129.1C122.61 128.25 135.81 126.87 140.51 128.03C145.21 129.19 144.25 133.03 144.25 133.03L120.51 133.75Z" fill="#006CFB"/>
      <path d="M159.16 136.19C160.88 137.77 162.74 139.52 164.59 141.12C166.44 142.72 168.31 144.36 170.19 145.94C172.07 147.52 173.99 149.01 175.92 150.46C177.85 151.91 179.81 153.23 181.77 154.46L177.77 154.08C179.432 153.636 181.005 152.909 182.42 151.93C184.073 150.774 185.607 149.458 187 148C188.501 146.464 189.907 144.837 191.21 143.13C191.88 142.26 192.55 141.38 193.21 140.47C193.87 139.56 194.47 138.6 195.02 137.76L195.13 137.6C195.509 137.02 196.085 136.595 196.752 136.405C197.418 136.214 198.131 136.269 198.761 136.56C199.39 136.851 199.894 137.358 200.18 137.99C200.466 138.621 200.516 139.335 200.32 140C199.94 141.3 199.54 142.41 199.08 143.57C198.62 144.73 198.14 145.87 197.6 146.99C196.505 149.274 195.224 151.465 193.77 153.54C192.243 155.74 190.464 157.753 188.47 159.54C186.262 161.511 183.7 163.045 180.92 164.06C180.323 164.27 179.69 164.36 179.059 164.324C178.427 164.288 177.809 164.127 177.24 163.85L176.91 163.68C174.427 162.459 172.016 161.097 169.69 159.6C167.42 158.13 165.22 156.6 163.1 154.96C160.98 153.32 158.93 151.66 156.93 149.96C154.93 148.26 153.01 146.48 151.06 144.49C150.022 143.407 149.444 141.965 149.446 140.466C149.449 138.967 150.032 137.527 151.073 136.448C152.114 135.369 153.533 134.735 155.031 134.68C156.53 134.624 157.991 135.151 159.11 136.15L159.16 136.19Z" fill="#FF8B7B"/>
      <path d="M153.05 131C153.05 131 163.13 140.78 164.3 201.27C148.18 205.18 132.67 206.62 120.69 205.1C120.12 197.51 119.27 171.17 111.57 143.4C111.239 142.231 111.155 141.005 111.324 139.801C111.492 138.597 111.908 137.442 112.547 136.407C113.185 135.373 114.031 134.483 115.032 133.793C116.033 133.103 117.166 132.629 118.36 132.4C119.89 132.1 121.5 131.83 123.1 131.62C129.179 130.751 135.302 130.233 141.44 130.07C145.327 130.102 149.207 130.413 153.05 131Z" fill="#263238"/>
      <path d="M145.23 141.539C146.23 149.109 159.23 163.659 159.23 163.659L174.66 147.659C170.077 142.847 165.121 138.404 159.84 134.369C148.53 125.919 144.33 134.519 145.23 141.539Z" fill="#263238"/>
      <path d="M199.32 142.66L204.48 137.93L198.3 131.58C196.255 133.321 194.848 135.692 194.3 138.32C194.239 138.921 194.398 139.523 194.748 140.016C195.097 140.509 195.613 140.858 196.2 141L199.32 142.66Z" fill="#FF8B7B"/>
      <path d="M206.38 134.49L205.05 131.22C204.937 130.939 204.769 130.684 204.556 130.47C204.343 130.255 204.09 130.085 203.81 129.971C203.53 129.856 203.23 129.799 202.928 129.802C202.626 129.805 202.327 129.869 202.05 129.99L198.26 131.58L204.44 137.93L205.52 137.2C205.953 136.916 206.275 136.492 206.432 135.998C206.589 135.504 206.57 134.972 206.38 134.49Z" fill="#FF8B7B"/>
      <path d="M120.73 104C122.18 112 122.62 116.74 127.12 120.55C133.89 126.28 143.23 121.82 144.12 113.65C144.96 106.29 142.45 94.4402 134.31 91.8602C126.17 89.2802 119.28 96.0002 120.73 104Z" fill="#FF8B7B"/>
      <path d="M136.5 106.88C139.31 107.52 141.5 104.27 141.8 103.35C140.43 103.48 138 102.78 137.52 102.16C139.02 102.29 140.47 100.44 140.65 99.7299C139.059 99.4226 137.649 98.5081 136.72 97.1799C139.15 97.1299 142.07 92.8499 139.4 89.0199C136.73 85.1899 125.57 88.3999 124 85.6699C121.66 87.0299 123.74 88.8399 123.74 88.8399C123.74 88.8399 107.95 91.8399 115.23 97.9299C109.89 98.4099 109.14 104.72 113.59 107.93C118.04 111.14 118.27 113.26 116.5 113C117.21 114.36 120.38 114.54 120.56 113.22C121.31 115.99 123.47 119.74 128.45 119.13C133.43 118.52 138.31 111.55 136.5 106.88Z" fill="#263238"/>
      <path d="M116.61 97.0003C112.85 97.5203 111.14 95.4003 112.08 93.5303C107.42 95.9103 112.51 101.17 116.61 97.0003Z" fill="#263238"/>
      <path d="M132.61 105.66C133.257 107.336 134.401 108.774 135.89 109.78C137.89 111.1 139.7 109.78 139.36 107.78C139.05 105.94 137.64 103.03 135.56 102.51C135.129 102.369 134.667 102.354 134.227 102.466C133.788 102.578 133.39 102.814 133.08 103.145C132.77 103.476 132.561 103.888 132.478 104.334C132.395 104.779 132.44 105.24 132.61 105.66Z" fill="#FF8B7B"/>
      <path d="M386.78 387.519C379.38 370.059 376.39 349.519 384.86 350.369C393.55 351.209 392.53 382.169 393.94 385.569C391.23 387.899 386.78 387.519 386.78 387.519Z" fill="#006CFB"/>
      <path d="M389.18 354.39C387.36 361.05 385.43 371.34 383.96 380.06C384.8 382.56 385.75 385.06 386.78 387.52C386.78 387.52 391.22 387.9 393.95 385.57C392.83 382.89 393.23 363.05 389.18 354.39Z" fill="#263238"/>
      <path d="M389.22 384.32C386.9 347.22 393.22 312.83 409.86 314.38C424.14 315.72 403.32 378.18 403.72 385.38C397.18 387.92 389.22 384.32 389.22 384.32Z" fill="#006CFB"/>
      <path d="M400 384.08C402.61 357.41 416.68 326.29 422.72 336.69C430.39 349.89 410.72 381.33 410.19 386.5C405.27 387.56 400 384.08 400 384.08Z" fill="#006CFB"/>
      <path d="M397.369 324.29C395.679 324.01 394.74 322.96 394.85 321.47C394.97 319.79 396.35 318.12 397.63 318.1C398.25 318.1 399.289 318.42 399.549 320.73L398.549 320.85C398.429 319.75 398.1 319.1 397.66 319.1C396.98 319.1 395.939 320.34 395.859 321.54C395.789 322.54 396.35 323.1 397.54 323.3L397.369 324.29Z" fill="#006CFB"/>
      <path d="M415.329 330.999C414.603 330.941 413.908 330.673 413.329 330.229L413.869 329.399C414.799 329.999 415.519 330.159 415.799 329.819C416.219 329.279 415.929 327.699 415.059 326.869C414.359 326.199 413.539 326.249 412.629 327.049L411.979 326.289C412.214 326.026 412.499 325.812 412.817 325.662C413.136 325.511 413.482 325.427 413.835 325.414C414.187 325.401 414.538 325.459 414.867 325.586C415.196 325.712 415.497 325.904 415.749 326.149C416.969 327.309 417.379 329.429 416.579 330.439C416.432 330.627 416.241 330.775 416.023 330.873C415.806 330.97 415.568 331.014 415.329 330.999Z" fill="#006CFB"/>
      <path d="M390.82 346.1C390.214 346.143 389.614 345.964 389.131 345.596C388.649 345.227 388.318 344.695 388.2 344.1C387.8 342.47 388.6 340.46 389.82 340.04C390.39 339.85 391.49 339.83 392.45 341.96L391.53 342.38C391.08 341.38 390.53 340.84 390.14 340.99C389.5 341.21 388.89 342.7 389.14 343.86C389.37 344.86 390.14 345.2 391.28 345.02L391.43 346.02C391.229 346.064 391.025 346.091 390.82 346.1Z" fill="#006CFB"/>
      <path d="M380.169 366.81C379.682 366.821 379.203 366.685 378.794 366.419C378.385 366.153 378.067 365.77 377.879 365.32C377.179 363.79 377.589 361.66 378.699 361.02C379.229 360.72 380.309 360.5 381.649 362.42L380.829 362.99C380.189 362.08 379.579 361.67 379.199 361.89C378.609 362.23 378.289 363.81 378.789 364.89C379.199 365.78 379.979 366.02 381.109 365.62L381.439 366.57C381.033 366.723 380.603 366.804 380.169 366.81Z" fill="#006CFB"/>
      <path d="M397.89 336.62C397.786 336.619 397.685 336.588 397.6 336.53C396.979 336.186 396.503 335.629 396.26 334.962C396.017 334.295 396.024 333.562 396.28 332.9C396.569 332.236 397.065 331.682 397.694 331.322C398.322 330.962 399.05 330.814 399.77 330.9C400.39 331.01 401.84 331.56 401.94 334.22C401.94 334.352 401.887 334.48 401.793 334.573C401.7 334.667 401.572 334.72 401.44 334.72C401.307 334.72 401.18 334.667 401.086 334.573C400.992 334.48 400.94 334.352 400.94 334.22C400.88 332.83 400.41 331.99 399.59 331.84C399.092 331.8 398.594 331.916 398.166 332.173C397.738 332.429 397.4 332.813 397.2 333.27C396.84 334.12 397.2 334.98 398.2 335.69C398.287 335.751 398.353 335.838 398.387 335.94C398.421 336.041 398.422 336.15 398.39 336.252C398.358 336.353 398.294 336.442 398.207 336.505C398.121 336.567 398.016 336.601 397.91 336.6L397.89 336.62Z" fill="white"/>
      <path d="M409.739 363.609C409.625 363.611 409.513 363.571 409.425 363.498C409.337 363.425 409.278 363.322 409.259 363.209C409.245 363.145 409.243 363.079 409.254 363.015C409.265 362.951 409.289 362.889 409.325 362.834C409.36 362.779 409.406 362.732 409.46 362.695C409.514 362.658 409.575 362.632 409.639 362.619C410.999 362.339 411.749 361.729 411.769 360.899C411.724 360.402 411.526 359.931 411.202 359.551C410.878 359.171 410.443 358.901 409.959 358.779C409.059 358.559 408.259 359.049 407.729 360.149C407.7 360.208 407.66 360.261 407.611 360.305C407.561 360.348 407.504 360.382 407.442 360.403C407.38 360.424 407.314 360.433 407.248 360.429C407.183 360.425 407.118 360.408 407.059 360.379C407 360.35 406.947 360.31 406.904 360.261C406.86 360.211 406.827 360.154 406.805 360.092C406.784 360.03 406.775 359.964 406.779 359.898C406.783 359.832 406.8 359.768 406.829 359.709C407.074 359.046 407.546 358.49 408.161 358.141C408.775 357.791 409.494 357.67 410.189 357.799C410.895 357.973 411.527 358.37 411.991 358.929C412.456 359.489 412.728 360.183 412.769 360.909C412.769 361.539 412.449 363.059 409.849 363.599L409.739 363.609Z" fill="white"/>
      <path d="M423.8 355.179C423.22 355.179 422.45 354.839 421.59 353.549L422.42 352.989C423.04 353.909 423.63 354.329 424.02 354.119C424.62 353.799 425.02 352.219 424.49 351.119C424.11 350.229 423.33 349.969 422.19 350.339L421.88 349.389C423.5 348.859 424.82 349.389 425.41 350.729C426.08 352.269 425.63 354.389 424.5 354.999C424.286 355.12 424.045 355.182 423.8 355.179Z" fill="#006CFB"/>
      <path d="M420.82 393.869C420.827 388.533 418.913 383.372 415.43 379.329C415.772 379.279 416.085 379.109 416.312 378.848C416.539 378.588 416.666 378.255 416.67 377.909C416.667 377.519 416.51 377.145 416.233 376.869C415.955 376.594 415.58 376.439 415.19 376.439H381.71C381.345 376.444 380.996 376.586 380.73 376.835C380.464 377.085 380.301 377.425 380.274 377.789C380.246 378.152 380.356 378.513 380.581 378.8C380.806 379.087 381.13 379.28 381.49 379.339C379.3 381.883 377.712 384.887 376.844 388.129C375.976 391.37 375.85 394.766 376.475 398.064C377.101 401.361 378.462 404.474 380.457 407.173C382.452 409.871 385.031 412.085 388 413.649C387.676 413.816 387.418 414.087 387.267 414.418C387.117 414.749 387.082 415.122 387.168 415.475C387.255 415.829 387.458 416.143 387.745 416.366C388.032 416.59 388.386 416.711 388.75 416.709H408.14C408.569 416.707 408.979 416.535 409.282 416.232C409.586 415.929 409.757 415.518 409.76 415.089C409.758 414.793 409.677 414.503 409.523 414.249C409.37 413.996 409.151 413.789 408.89 413.649C412.49 411.751 415.504 408.905 417.607 405.419C419.709 401.934 420.82 397.94 420.82 393.869Z" fill="#263238"/>
      <path d="M262 215C248.745 215 238 224.062 238 235.24C238 241.437 241.311 246.976 246.513 250.69C246.646 253.516 246.207 257.418 242.294 259.218L242.287 259.221C242.112 259.287 241.962 259.405 241.856 259.558C241.75 259.712 241.693 259.894 241.692 260.08C241.692 260.324 241.79 260.558 241.963 260.731C242.136 260.903 242.371 261 242.615 261C242.637 261 242.658 260.997 242.68 260.996C242.685 260.996 242.69 260.996 242.695 260.996C247.188 260.968 251.003 258.547 253.635 255.99C254.469 255.179 255.632 254.78 256.779 254.995C258.459 255.313 260.206 255.48 262 255.48C275.255 255.48 286 246.418 286 235.24C286 224.062 275.255 215 262 215Z" fill="white"/>
    </svg>
  )
}

export default ChatNoMessagesImage;
