import { FC } from "react";

import { ISvgProps } from "../../types/svg";

const CheckIcon: FC<ISvgProps & { strokeWidth?: string }> = ({ height, width, color, opacity, strokeWidth = 1.5, className }) => {

  return (
    <svg
      width={width || 16}
      height={height || 16}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color || "#465FF1"}
      strokeOpacity={opacity || 1}
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );
}

export default CheckIcon;
