import { create } from "zustand";

interface IError {
  id: string;
  message: string;
}

interface IGlobalErrorStore {
  errors: IError[];
  pinError: boolean;
  setError: (error: string | string[]) => void;
  removeError: (id: string) => void;
  setPinError: (newPinError: boolean) => void;
}

const generateId = () => Math.random().toString(36).substr(2, 9);

const useGlobalErrorStore = create<IGlobalErrorStore>((set) => ({
  errors: [],
  pinError: false,
  setError: (error) => set((prevState) => {
    const newErrors = Array.isArray(error)
      ? error.map((message) => ({ id: generateId(), message }))
      : [{ id: generateId(), message: error }];
    return { errors: [...prevState.errors, ...newErrors] };
  }),
  removeError: (id) => set((prevState) => ({
    errors: prevState.errors.filter((error) => error.id !== id)
  })),
  setPinError: (newPinError) =>
    set((prevState) => ({
      ...prevState,
      pinError: newPinError,
    })),
}));

export default useGlobalErrorStore;
