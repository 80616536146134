import {create} from "zustand";

interface IGlobalLoaderStore {
  loader: boolean;
  setLoader: (loader: boolean) => void;
}

const useGlobalLoaderStore = create<IGlobalLoaderStore>((set) => ({
  loader: false,
  setLoader: (loader) => set((prevState) => ({
    ...prevState,
    loader,
  }))
}));

export default useGlobalLoaderStore;
