import { useTranslation } from "react-i18next";

import DashboardPropertyStatistics from "../../features/dashboard/components/DashboardPropertyStatistics";
import DashboardActiveProperties from "../../features/dashboard/components/DashboardActiveProperties";

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <h1 className="text-[#11142d] text-[18px] font-semibold mb-5">{t('dashboard')}</h1>
      <div className="mb-6">
        <DashboardPropertyStatistics />
      </div>
      <div>
        <DashboardActiveProperties />
      </div>
    </div>
  )
}

export default DashboardPage;
