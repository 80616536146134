import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";
import { TPostAdminSettings } from "./types";

export const getAdminSettings = async () => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get('/setting');
  return response.data.data;
}

export const postAdminSettings = async (payload: TPostAdminSettings) => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.post('/setting', payload);
  return response.data.data;
}