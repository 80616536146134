import React from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod"

import { changePasswordFormSchema } from "../validations/schema";

import { postAuthPasswordChange } from "../../../api/services/auth/requests";
import { IPostAuthPasswordChangePayload } from "../../../api/services/auth/types";

import { IChangePassword } from "../types/form";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";
import useGlobalModalStore from "../../../common/stores/useGlobalModalStore";

import ChangePasswordSuccessModal from "../components/modals/ChangePasswordSuccessModal/ChangePasswordSuccessModal";

const useFormChangePassword = () => {
  const { setLoader } = useGlobalLoaderStore((state) => state);
  const { setError } = useGlobalErrorStore();
  const { setIsOpen, setChildren } = useGlobalModalStore();

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  const {
    control,
    handleSubmit,
    formState: {
      isValid,
    },
    watch,
  } = useForm<IChangePassword>({
    mode: 'onBlur',
    defaultValues,
    resolver: zodResolver(changePasswordFormSchema),
  });

  const newPassword = watch("newPassword");

  const onSubmit = async (value: IChangePassword) => {
    setLoader(true);

    const payload: IPostAuthPasswordChangePayload = {
      password: value.currentPassword,
      newPassword: value.newPassword,
    }

    try {
      setLoader(true)
      await postAuthPasswordChange(payload);
      setChildren(React.createElement(ChangePasswordSuccessModal));
      setIsOpen(true)
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoader(false)
    }
  }

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    newPassword,
    isValid,
  };
}

export default useFormChangePassword;
