import React from 'react';

import { capitalizeFirstLetter } from '../../utils/helpers';

import useGlobalModalStore from '../../stores/useGlobalModalStore';

import CrossIcon from '../../assets/icons/CrossIconSite';

interface IModalTemplateProps {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  body?: React.ReactNode;
  width?: number;
}

const ModalTemplate: React.FC<IModalTemplateProps> = ({
  icon,
  title,
  description,
  body,
  // width = 670,
}) => {
  const { onClose } = useGlobalModalStore();

  return (
    <div
      className="w-full md:w-[670px] flex flex-col gap-[20px] md:gap-[15px] pt-[15px] pb-[15px] px-[15px] md:pt-[25px] md:pb-[50px] md:px-[50px]"
    >
      <button onClick={onClose} className="flex justify-end">
        <CrossIcon width={18} height={18} color="#D4D4D4" strokeWidth="2" />
      </button>
      <div className="flex flex-col gap-[20px]">
        {!!icon && <div className="flex-center w-[45px] h-[45px]">{icon}</div>}
        <div className="flex flex-col gap-[10px]">
          {!!title && (
            <h5 className="text-heading-5 text-heading">
              {capitalizeFirstLetter(title)}
            </h5>
          )}
          {!!description && <div className="text-body-default text-body">{capitalizeFirstLetter(description)}</div>}
        </div>
        {!!body && <div>{body}</div>}
      </div>
    </div>
  );
};

export default ModalTemplate;
