import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getChatRooms } from "../../api/services/messages/requests";

import { TChatList } from "../../features/messages/types/chat";

import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";

import useWebsocketChatStore from "../../features/messages/stores/useWebsocketChatStore";

import useWebSocketChat from '../../features/messages/hooks/useWebsocketChat';

import ChatsList from "../../features/messages/components/ChatsList";
import ChatRoom from "../../features/messages/components/ChatRoom";

const MessagesPage = () => {
  const [loader, setLoader] = useState(false);

  const { t } = useTranslation();

  const { setError } = useGlobalErrorStore();

  const { rooms, setRooms, setRoomId, resetStore, refresh, setRefresh, openSupport, setOpenSupport } = useWebsocketChatStore();

  const { connect, disconnect, sendMessage, handleMessageRead } = useWebSocketChat();

  useQuery(["chatRooms", refresh], async () => {
    setRefresh(false)
    setLoader(true);
    const data: TChatList = await getChatRooms();
    setLoader(false);
    setRooms(data)
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response.data.message);
      setLoader(false);
    },
    enabled: refresh
  });

  useEffect(() => {
    connect();

    return () => {
      disconnect();
      resetStore();
    };
  }, []);

  useEffect(() => {
    if(openSupport && rooms.length > 0) {
      setOpenSupport(false);
      const supports = rooms.filter(room => room.isSupport);
      if(supports.length > 0) {
        setRoomId(supports[0].id);
      }
    }
  }, [openSupport, rooms])

  return (
    <div className="p-[18px_25px_26px_25px] h-[calc(100vh-80px)]">
      {rooms.length ? (<div
        className="h-full grid grid-cols-[auto_1fr] gap-[2px] overflow-x-auto bg-transparent p-0 h-[calc(100vh-124px)] min-h-[538px]">
        <ChatsList />
        <ChatRoom sendMessage={sendMessage} handleMessageRead={handleMessageRead}/>
      </div>) : !loader && (
        <div className="flex-center w-full h-full">{t('no_chat_rooms')}</div>
      )}
  </div>
  )
}

export default MessagesPage;
