import { useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  patchPropertyById,
  putPropertyByIdGallery
} from "../../../api/services/property/requests";

import { IPhotosAndDescriptionPropertyForm } from "../types/form";
import { IProperty } from "../../../common/types/dto/property";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";

import usePropertyImagesStore from "../stores/usePropertyImagesStore";

const defaultDescription: Record<string, string> = {
  en: "",
  cz: "",
}

const usePhotosAndDescriptionPropertyForm = (property?: IProperty) => {
  const { setLoader } = useGlobalLoaderStore();

  const {
    gallery,
    setGallery,
    mainImage,
    setMainImage,
    resetStore,
  } = usePropertyImagesStore();

  const {
    control,
    handleSubmit,
    reset,
    watch,
  } = useForm<IPhotosAndDescriptionPropertyForm>({
    mode: "onBlur",
    defaultValues: {
      description: property?.description || defaultDescription,
    },
  })

  const watchedDescription = watch('description');

  const galleryCount = gallery?.length || 0;
  const requiredFieldsPhotos = (!!mainImage ? 0 : 1) + Math.max(0, 3 - galleryCount);
  const requiredFieldDescription = Object.values(watchedDescription).some((value) => (value as string).length >= 50) ? 0 : 1;

  const onSubmit = async (values: IPhotosAndDescriptionPropertyForm) => {
    const mainImageId = mainImage?.id || gallery[0]?.id || null;
    const collateralImages = gallery.filter(image => image.id !== mainImageId);
    const imagePayload: {
      mainImage: string | undefined;
      images: { id: string }[] | undefined;
    } = {
      mainImage: mainImageId,
      images: collateralImages.map((image) => ({
        id: image.id
      }))
    };

    if(property) {
      let patchPropertyError;

      setLoader(true);

      try {
        const descriptionObject = {
          en: values.description?.en || '',
          cz: values.description?.cz || '',
        }
        const descriptionArray = Object.entries(descriptionObject).map(([language, value]) => ({
          language: language as "en" | "cz",
          value
        }));
        const payload = {
          ...values,
          description: descriptionArray,
        }
        await patchPropertyById(property.id, payload);
      } catch (error) {
        patchPropertyError = error;
      }

      try {
        await putPropertyByIdGallery(property.id, imagePayload);
      } catch (error) {
        throw error;
      } finally {
        setLoader(false);
      }

      if (patchPropertyError) {
        throw patchPropertyError;
      }
    }
  };

  useEffect(() => {
    if (property) {
      resetStore();
      const galleryImages = property.gallery?.map(g => g.image);
      setGallery(galleryImages || []);
      setMainImage(property.mainImage);
    }
  }, [property]);

  useEffect(() => {
    property && reset({
      description: property.description || defaultDescription,
    });
  }, [property, reset]);

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    requiredFieldsCount: requiredFieldsPhotos + requiredFieldDescription,
  }
}

export default usePhotosAndDescriptionPropertyForm;
