import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const ChevronIcon: FC<ISvgProps> = ({height = 19, width = 19, color = "#006CFB", opacity = 1, ...props}) => {

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill={color}
      opacity={opacity}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0227 3.85225C12.2224 4.05195 12.2406 4.36445 12.0772 4.58466L12.0227 4.64775L7.17075 9.5L12.0227 14.3523C12.2224 14.552 12.2406 14.8645 12.0772 15.0847L12.0227 15.1477C11.823 15.3474 11.5105 15.3656 11.2903 15.2022L11.2273 15.1477L5.97725 9.89775C5.77755 9.69805 5.7594 9.38555 5.92279 9.16534L5.97725 9.10225L11.2273 3.85225C11.4469 3.63258 11.8031 3.63258 12.0227 3.85225Z"
        fill={color}/>
    </svg>
  );
}

export default ChevronIcon;
