import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex-center flex-col">
      <div className="font-bold text-[40px]">404</div>
      <div className="text-[22px]">{t('page_not_found')}</div>
    </div>
  )
}

export default NotFoundPage;
