import { FC } from "react";

import { ISvgProps } from "../../types/svg";

const CrossIcon: FC<ISvgProps> = ({ height = 20, width = 20, color = "black" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.447 2.91986H14.4973V1.5028C14.4973 1.24149 14.2661 0.990242 13.9948 1.00029C13.7234 1.01034 13.4923 1.22139 13.4923 1.5028V2.91986H6.4572V1.5028C6.4572 1.24149 6.22605 0.990242 5.95469 1.00029C5.68334 1.01034 5.45219 1.22139 5.45219 1.5028V2.91986H3.51252C2.84616 2.91986 2.20709 3.18457 1.7359 3.65576C1.26471 4.12695 1 4.76602 1 5.43238V16.4875C1 17.1538 1.26471 17.7929 1.7359 18.2641C2.20709 18.7353 2.84616 19 3.51252 19H16.447C17.1133 19 17.7524 18.7353 18.2236 18.2641C18.6948 17.7929 18.9595 17.1538 18.9595 16.4875V5.43238C18.9595 4.76602 18.6948 4.12695 18.2236 3.65576C17.7524 3.18457 17.1133 2.91986 16.447 2.91986ZM17.9545 16.4875C17.9545 17.3216 17.2811 17.995 16.447 17.995H3.51252C2.67836 17.995 2.00501 17.3216 2.00501 16.4875V8.0253H17.9545V16.4875ZM17.9545 7.02029H2.00501V5.43238C2.00501 4.59822 2.67836 3.92487 3.51252 3.92487H5.45219V4.51782C5.45219 4.77912 5.68334 5.03038 5.95469 5.02033C6.22605 5.01028 6.4572 4.79922 6.4572 4.51782V3.92487H13.4923V4.51782C13.4923 4.77912 13.7234 5.03038 13.9948 5.02033C14.2661 5.01028 14.4973 4.79922 14.4973 4.51782V3.92487H16.447C17.2811 3.92487 17.9545 4.59822 17.9545 5.43238V7.02029Z"
        fill={color} />
      <path
        d="M9.81667 13.89V11.0067C9.81666 10.9956 9.8137 10.9847 9.80809 10.9751C9.80248 10.9656 9.79443 10.9576 9.78476 10.9522C9.77509 10.9468 9.76415 10.944 9.75306 10.9442C9.74198 10.9444 9.73114 10.9476 9.72167 10.9533L9.19 11.28C9.17105 11.2915 9.14938 11.2978 9.12721 11.2982C9.10503 11.2986 9.08315 11.2931 9.06381 11.2822C9.04447 11.2714 9.02836 11.2556 9.01715 11.2364C9.00594 11.2173 9.00002 11.1955 9 11.1733V10.6308C9 10.5433 9.04583 10.4617 9.12167 10.4167L9.75833 10.0358C9.79708 10.0125 9.84144 10.0001 9.88667 10H10.4083C10.4412 10 10.4737 10.0065 10.504 10.019C10.5343 10.0316 10.5619 10.05 10.5851 10.0732C10.6083 10.0964 10.6267 10.124 10.6393 10.1543C10.6519 10.1847 10.6583 10.2172 10.6583 10.25V13.8892C10.6583 13.9555 10.632 14.0191 10.5851 14.0659C10.5382 14.1128 10.4746 14.1392 10.4083 14.1392H10.0675C10.0347 14.1393 10.0021 14.1329 9.97177 14.1205C9.94139 14.108 9.91377 14.0897 9.89048 14.0665C9.86719 14.0434 9.84868 14.0159 9.83602 13.9856C9.82335 13.9553 9.81678 13.9228 9.81667 13.89Z"
        fill={color} />
    </svg>
  );
}

export default CrossIcon;
