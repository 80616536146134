import { ReactNode } from "react";
import classNames from "classnames";

import CrossIcon from "../../assets/icons/CrossIcon";

interface IDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode;
}

const Dialog = ({open, setOpen, title, children, actions}: IDialogProps) => {
  return (
    <div className={classNames(
      "fixed top-0 left-0 justify-center items-center w-dvw h-dvh z-[99999] bg-[#00000040] font-Manrope",
      {
        "flex": open,
        "hidden": !open,
      }
    )}>
      <div className="bg-white rounded-[16px] p-5 w-[300px]">
        <div className="flex justify-end">
          <div className="cursor-pointer" onClick={() => setOpen(false)}>
            <CrossIcon />
          </div>
        </div>
        <div className="text-[18px] font-semibold text-center">{title}</div>
        <div className="text-[14px] mt-[20px] text-center">{children}</div>
        <div className="mt-[38px]">
          {actions}
        </div>
      </div>
    </div>
  );
}

export default Dialog;
