import axios from "axios";
import i18n from 'i18next';

import { API_URL } from "../common/constants/env";

import useAuthStore from "../common/stores/useAuthStore";
import useGlobalErrorStore from "../common/stores/useGlobalErrorStore";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
});

const refreshJWToken = (refreshToken?: string | null) => {
  return new Promise((resolve, reject) => {
    if(!refreshToken) {
      useAuthStore.getState().logout();
      window.location.href = "/";
      return reject(true);
    }
    axios
      .post(`${API_URL}/auth/refresh-token`, {
        refreshToken,
      })
      .then(response => {
        const data = response.data.data;

        useAuthStore.getState().setRefreshToken(data.refreshToken);
        useAuthStore.getState().setAccessToken(data.accessToken);

        resolve(false);
      })
      .catch(() => {
        useAuthStore.getState().logout();
        window.location.href = "/";
        reject(true);
      });
  });
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = useAuthStore.getState().accessToken;
    const impersonationToken = useAuthStore.getState().impersonationToken;

    config.headers["locale"] = i18n.language;

    if(impersonationToken) {
      config.headers["Authorization"] = `Bearer ${impersonationToken}`;
    } else if(accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401){
      if (error.response.data?.data?.name === 'refreshTokenIsExpired') {
        originalRequest._retry = false;
        useAuthStore.getState().logout();
        return Promise.reject(error);
      }

      const refreshToken = useAuthStore.getState().refreshToken;
      if (refreshToken) {
        const isError = await refreshJWToken(refreshToken);

        if (!isError) {
          originalRequest._retry = true;
          originalRequest.headers.Authorization = `Bearer ${useAuthStore.getState().accessToken}`;

          return axios(originalRequest);
        }
      }

      useAuthStore.getState().logout();
      //window.location.href = "/";
    }

    throw error;
  }
);

export default axiosInstance;
