import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";

import Input from "../../../common/components/Input/Input";

import PropertyEditPhotos from "./PropertyEditPhotos";
import PropertyDescriptionInput from "./PropertyDescriptionInput";

interface IPropertyPhotosAndDescriptionProps {
  control: Control<any>;
  propertyId?: string;
}

const PropertyPhotosAndDescription: FC<IPropertyPhotosAndDescriptionProps> = ({
                                                                                control,
                                                                                propertyId,
                                                                              }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-[35px] ">
      <div className="text-[20px] text-[#333] font-semibold">{t('photos_and_description')}</div>
      <div>
        <div className="text-[#666] mb-[15px]">
          {t('upload_min_photos')}
          <span className="text-[#C73434]">*</span>
        </div>
        <PropertyEditPhotos propertyId={propertyId} />
      </div>
      {/*<div className="grid grid-cols-3 gap-x-[40px] gap-y-[18px]">*/}
      {/*  <Input*/}
      {/*    label={t('link_to_YT_video')}*/}
      {/*    name="youtubeUrl"*/}
      {/*    control={control}*/}
      {/*    placeholder="https://"*/}
      {/*  />*/}
      {/*</div>*/}
      <div>
        <PropertyDescriptionInput
          name="description"
          control={control}
          label={t('description')}
          placeholder={t('description_placeholder')}
          required
        />
      </div>
    </div>
  );
}

export default PropertyPhotosAndDescription;
