import { FC } from "react";
import { ISvgProps } from "../../types/svg";

const PhoneIcon: FC<ISvgProps> = ({ color = 'black'}) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0V2.25H16V0H0ZM0 11.25H4.57143V18H11.4286V11.25H16L8 3.375L0 11.25Z" fill={color} />
    </svg>
  );
}

export default PhoneIcon;
