import { ComponentType, useMemo } from "react";

import { EUserRole } from "../../../common/types/dto/user";
import { ISvgProps } from "../../../common/types/svg";

import useAuthStore from "../../../common/stores/useAuthStore";

import DashboardIcon from "../../../common/assets/icons/menu/DashboardIcon";
import PropertyIcon from "../../../common/assets/icons/menu/PropertyIcon";
import BillingIcon from "../../../common/assets/icons/menu/BillingIcon";
import ProductsIcon from "../../../common/assets/icons/menu/ProductsIcon";
import MessagesIcon from "../../../common/assets/icons/menu/MessagesIcon";
import AccountIcon from "../../../common/assets/icons/menu/AccountIcon";
import TransactionsIcon from "../../../common/assets/icons/TransactionsIcon";
// import SupportIcon from "../../../common/assets/icons/menu/SupportIcon";
import SettingsIcon from "../../../common/assets/icons/menu/SettingsIcon";

interface IMenuItemProps {
  icon: ComponentType<ISvgProps>;
  label: string;
  href: string;
}

const menuItemsAdmin: IMenuItemProps[] = [
  {
    icon: DashboardIcon,
    label: "dashboard",
    href: "/"
  },
  {
    icon: PropertyIcon,
    label: "property",
    href: "/properties"
  },
  {
    icon: MessagesIcon,
    label: "messages",
    href: "/messages"
  },
  {
    icon: AccountIcon,
    label: "agents",
    href: "/agents"
  },
  {
    icon: AccountIcon,
    label: "users",
    href: "/users"
  },
  {
    icon: TransactionsIcon,
    label: "transactions",
    href: "/transactions"
  },
  // {
  //   icon: SupportIcon,
  //   label: "support",
  //   href: "/support"
  // },
  {
    icon: SettingsIcon,
    label: "settings",
    href: "/settings"
  },
];

const menuItemsAgent: IMenuItemProps[] = [
  {
    icon: DashboardIcon,
    label: "dashboard",
    href: "/"
  },
  {
    icon: PropertyIcon,
    label: "property",
    href: "/properties"
  },
  {
    icon: ProductsIcon,
    label: "products",
    href: "/products"
  },
  {
    icon: BillingIcon,
    label: "balance",
    href: "/balance"
  },
  {
    icon: MessagesIcon,
    label: "messages",
    href: "/messages"
  },
  {
    icon: AccountIcon,
    label: "account",
    href: "/account"
  },
  // {
  //   icon: SupportIcon,
  //   label: "support",
  //   href: "/support"
  // },
];

const useMenuItems = () => {
  const { user, impersonationToken } = useAuthStore();

  const menuItems = useMemo(() => {
    if(!user) return [];
    return user.role === EUserRole.Admin && !impersonationToken ? menuItemsAdmin : menuItemsAgent;
  }, [user, impersonationToken]);

  return {
    menuItems,
  }
}

export default useMenuItems;
