import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const QuestionIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 22}
      height={height || 22}
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      fill={color || "#006CFB"}
      opacity={opacity || 1}
    >
      <path
        d="M11.321 3.12891C7.036 3.12891 3.55951 6.6054 3.55951 10.8904C3.55951 15.1753 7.036 18.6518 11.321 18.6518C15.606 18.6518 19.0824 15.1753 19.0824 10.8904C19.0824 6.6054 15.606 3.12891 11.321 3.12891ZM11.0784 15.4179C10.9185 15.4179 10.7622 15.3705 10.6293 15.2816C10.4963 15.1928 10.3927 15.0665 10.3315 14.9188C10.2703 14.7711 10.2543 14.6085 10.2855 14.4517C10.3167 14.2949 10.3937 14.1508 10.5067 14.0377C10.6198 13.9247 10.7639 13.8477 10.9207 13.8165C11.0775 13.7853 11.2401 13.8013 11.3878 13.8625C11.5356 13.9237 11.6618 14.0273 11.7507 14.1602C11.8395 14.2932 11.8869 14.4495 11.8869 14.6094C11.8869 14.8238 11.8017 15.0295 11.6501 15.1811C11.4985 15.3327 11.2929 15.4179 11.0784 15.4179ZM12.4302 11.2946C11.7749 11.7344 11.6848 12.1375 11.6848 12.5073C11.6848 12.6574 11.6252 12.8014 11.519 12.9075C11.4129 13.0137 11.269 13.0733 11.1189 13.0733C10.9688 13.0733 10.8248 13.0137 10.7187 12.9075C10.6125 12.8014 10.5529 12.6574 10.5529 12.5073C10.5529 11.6216 10.9604 10.9175 11.7988 10.3543C12.5782 9.83126 13.0188 9.49978 13.0188 8.77093C13.0188 8.27532 12.7358 7.89897 12.1501 7.62045C12.0122 7.55496 11.7054 7.49109 11.3278 7.49554C10.8541 7.5016 10.4862 7.61479 10.2028 7.84278C9.66843 8.2729 9.62316 8.74101 9.62316 8.74788C9.61957 8.82221 9.60139 8.89509 9.56963 8.96238C9.53788 9.02968 9.49319 9.09005 9.4381 9.14007C9.38301 9.19009 9.31862 9.22877 9.24858 9.2539C9.17855 9.27903 9.10425 9.29012 9.02993 9.28654C8.95561 9.28296 8.88272 9.26477 8.81543 9.23302C8.74814 9.20127 8.68776 9.15657 8.63774 9.10148C8.58772 9.0464 8.54904 8.982 8.52391 8.91196C8.49878 8.84193 8.48769 8.76763 8.49127 8.69331C8.49572 8.59508 8.56404 7.71019 9.49258 6.96315C9.97404 6.57589 10.5865 6.37457 11.3117 6.36568C11.8251 6.35962 12.3073 6.44653 12.6344 6.60095C13.613 7.06381 14.1507 7.83551 14.1507 8.77093C14.1507 10.1385 13.2367 10.7525 12.4302 11.2946Z"
      />
    </svg>
  );
}

export default QuestionIcon;
