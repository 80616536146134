import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const CheckIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 21}
      height={height || 21}
      viewBox="0 0 21 21"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9638 3.96375C18.0797 3.83935 18.1428 3.67481 18.1398 3.5048C18.1368 3.33478 18.0679 3.17257 17.9477 3.05233C17.8274 2.9321 17.6652 2.86322 17.4952 2.86022C17.3252 2.85723 17.1607 2.92033 17.0363 3.03625L3.03626 17.0363C2.97179 17.0963 2.92007 17.1688 2.8842 17.2493C2.84834 17.3298 2.82905 17.4167 2.82749 17.5048C2.82594 17.5929 2.84215 17.6804 2.87515 17.7622C2.90816 17.8439 2.95729 17.9181 3.0196 17.9804C3.08192 18.0427 3.15615 18.0919 3.23786 18.1249C3.31958 18.1579 3.4071 18.1741 3.49522 18.1725C3.58333 18.171 3.67023 18.1517 3.75073 18.1158C3.83123 18.0799 3.90368 18.0282 3.96376 17.9638L6.61939 15.3081C7.77264 15.8401 9.09301 16.1875 10.5 16.1875C12.7908 16.1875 14.8505 15.2679 16.3345 14.1286C17.0783 13.5581 17.6881 12.9246 18.1151 12.3086C18.5351 11.7031 18.8125 11.0679 18.8125 10.5C18.8125 9.93213 18.5351 9.29688 18.1151 8.69225C17.6881 8.07538 17.0783 7.44188 16.3345 6.87225C16.0983 6.69025 15.8463 6.5135 15.582 6.34638L17.9638 3.96375ZM13.2388 8.6905L12.2763 9.65125C12.4517 10.0186 12.509 10.4314 12.4401 10.8326C12.3713 11.2339 12.1797 11.6039 11.8918 11.8918C11.604 12.1797 11.2339 12.3713 10.8326 12.4401C10.4314 12.509 10.0186 12.4517 9.65126 12.2763L8.68876 13.2388C9.31975 13.6565 10.0758 13.8434 10.8287 13.7676C11.5817 13.6919 12.2853 13.3582 12.8204 12.823C13.3555 12.2879 13.6892 11.5843 13.765 10.8313C13.8408 10.0784 13.6539 9.32237 13.2361 8.69138"
      />
      <path
        d="M11.0863 7.27125C11.1222 7.27799 11.1593 7.27595 11.1944 7.26528C11.2294 7.25462 11.2614 7.23565 11.2875 7.21L12.9938 5.50375C13.0203 5.47766 13.0395 5.44507 13.0495 5.40923C13.0595 5.3734 13.0599 5.33556 13.0507 5.29952C13.0414 5.26347 13.0229 5.23048 12.997 5.20382C12.971 5.17717 12.9385 5.15779 12.9028 5.14762C12.1208 4.92723 11.3124 4.81448 10.5 4.8125C8.20925 4.8125 6.1495 5.73213 4.6655 6.87138C3.92175 7.44188 3.31187 8.07537 2.884 8.69138C2.464 9.29688 2.1875 9.93213 2.1875 10.5C2.1875 11.0679 2.46488 11.7031 2.884 12.3078C3.30038 12.8921 3.79232 13.4188 4.347 13.874C4.38851 13.9083 4.44136 13.9258 4.49514 13.9231C4.54892 13.9203 4.59972 13.8975 4.6375 13.8591L7.21 11.2866C7.23565 11.2605 7.25462 11.2285 7.26528 11.1935C7.27595 11.1585 7.27799 11.1214 7.27125 11.0854C7.17643 10.5635 7.20952 10.0265 7.36769 9.52023C7.52587 9.01397 7.8044 8.55361 8.17944 8.17857C8.55448 7.80353 9.01485 7.52499 9.5211 7.36682C10.0274 7.20865 10.5644 7.17555 11.0863 7.27038"
      />
    </svg>
  );
}

export default CheckIcon;
