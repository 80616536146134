import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const NotificationIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 25}
      height={height || 24}
      viewBox="0 0 25 24"
      fill={color || "#808191"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.55554 16.5959C2.55554 16.2151 2.71025 15.8506 2.98418 15.586L4.01313 14.5922C4.40483 14.2139 4.62532 13.6922 4.62368 13.1476L4.61422 9.9946C4.60097 5.58319 8.17343 2 12.5849 2C16.9869 2 20.5555 5.56859 20.5555 9.97067L20.5555 13.1716C20.5555 13.702 20.7663 14.2107 21.1413 14.5858L22.1413 15.5858C22.4065 15.851 22.5555 16.2107 22.5555 16.5858C22.5555 17.3668 21.9224 18 21.1413 18H16.5555C16.5555 20.2091 14.7647 22 12.5555 22C10.3464 22 8.55554 20.2091 8.55554 18H3.95962C3.18417 18 2.55554 17.3714 2.55554 16.5959ZM10.5555 18C10.5555 19.1046 11.451 20 12.5555 20C13.6601 20 14.5555 19.1046 14.5555 18H10.5555ZM18.5555 13.1716C18.5555 14.2324 18.977 15.2499 19.7271 16L5.43405 16C6.19776 15.246 6.6269 14.2161 6.62367 13.1416L6.61421 9.9886C6.60429 6.6841 9.28034 4 12.5849 4C15.8824 4 18.5555 6.67316 18.5555 9.97067L18.5555 13.1716Z"
      />
    </svg>
  );
}

export default NotificationIcon;
