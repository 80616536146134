import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const EditIcon: FC<ISvgProps> = ({height = 31, width = 41, color = "#152242" }) => {

  return (
    <svg width={width} height={height} viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M39.25 5.8866V24.6366C39.25 25.8798 38.7561 27.0721 37.8771 27.9512C36.998 28.8302 35.8057 29.3241 34.5625 29.3241H6.4375C5.1943 29.3241 4.00201 28.8302 3.12294 27.9512C2.24386 27.0721 1.75 25.8798 1.75 24.6366V5.8866"
        stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path
        d="M39.25 5.8866C39.25 4.64339 38.7561 3.45111 37.8771 2.57203C36.998 1.69296 35.8057 1.1991 34.5625 1.1991H6.4375C5.1943 1.1991 4.00201 1.69296 3.12294 2.57203C2.24386 3.45111 1.75 4.64339 1.75 5.8866L18.0156 16.0428C18.7606 16.5085 19.6215 16.7554 20.5 16.7554C21.3785 16.7554 22.2394 16.5085 22.9844 16.0428L39.25 5.8866Z"
        stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default EditIcon;
