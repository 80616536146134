import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import { getPaymentInternalTransactions } from "../../../api/services/payment/requests";

import { IGetPaymentInternalTransactions } from "../../../api/services/payment/types";

import useGlobalLoaderStore from "../../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../../common/stores/useGlobalErrorStore";

import Pagination from "../../../common/components/Pagination/Pagination";
import {addPriceDelimiters} from "../../../common/utils/helpers";

const LIMIT = 10;

const BalanceHistory = () => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const { data = {} } = useQuery(["balance_history", page], async () => {
    const payload: IGetPaymentInternalTransactions = {
      page,
      limit: LIMIT,
    };

    setLoader(true);
    const data = await getPaymentInternalTransactions(payload);
    setLoader(false);
    return data;
  }, {
    onError: (error: any) => {
      setError(error.response?.data?.message);
      setLoader(false);
    }
  });

  const transactionsMap = data.items?.map((item: any) => {
    const { id, product, paymentSession, createdAt, amount } = item;

    const title = product?.title ? product?.title : paymentSession ? t('account_top_up') : t('system_balance_adjustment');
    const formattedDate = new Intl.DateTimeFormat('default', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(createdAt));

    return (
      <React.Fragment key={id}>
        <div className="text-[#333]">{title}</div>
        <div className="text-[10px] text-[#A0AEC0] font-bold text-center">{id}</div>
        <div className="text-[#666666] text-center">{formattedDate}</div>
        <div className="text-[#006CFB] text-right">{addPriceDelimiters(amount)} Kč</div>
      </React.Fragment>
    )
  });

  const totalPages = !!data.total && data.total > data.limit && Math.ceil(data.total / data.limit);

  return (
    <div>
      <div className="font-medium mb-6">{t('balance_history')}</div>
      <div className="grid grid-cols-[1fr_1fr_1fr_1fr] justify-between items-center text-[10px] text-[#A0AEC0] font-bold pb-[6px] border-b border-[#E2E8F0] mb-4">
        <div>{t('product')}</div>
        <div className="text-center">{t('transaction_id')}</div>
        <div className="text-center">{t('date')}</div>
        <div className="text-right">{t('sum')}</div>
      </div>
      <div className="grid grid-cols-[1fr_1fr_1fr_1fr] justify-between items-center gap-y-4">
        {transactionsMap}
      </div>
      {totalPages && <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)}/>}
    </div>
  )
}

export default BalanceHistory;
