import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getAddressString } from "../../../common/utils/address";
import {addPriceDelimiters, formatDate, formatPhoneNumber} from "../../../common/utils/helpers";

import { IProperty } from "../../../common/types/dto/property";
import { ICategorySchema } from "../../../common/types/dto/category";

import usePropertyFieldsFromSchema from "../hooks/usePropertyFieldsFromSchema";

import PropertyFieldWrapper from "./PropertyFieldWrapper";
import PropertyCarousel from "./PropertyCarousel";
import PropertyFieldsColumn from "./PropertyFieldsColumn";

import NoImageIcon from "../../../common/assets/icons/NoImageIcon";
import LocationIcon from "../../../common/assets/icons/LocationIcon";
import CalendarIcon from "../../../common/assets/icons/CalendarIcon";

import ParametersIcon from "../../../common/assets/icons/property-fields/ParametersIcon";
import PriceIcon from "../../../common/assets/icons/property-fields/PriceIcon";
import ContactsIcon from "../../../common/assets/icons/property-fields/ContactsIcon";
import DescriptionIcon from "../../../common/assets/icons/property-fields/DescriptionIcon";
import FeatureIcon from "../../../common/assets/icons/property-fields/FeatureIcon";

interface IPropertyPreviewProps {
  property?: IProperty;
  categorySchema?: ICategorySchema;
}

const propertyShowMoreLength = 200;

const PropertyPreview: FC<IPropertyPreviewProps> = ({property, categorySchema}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const [descriptionShowMore, setDescriptionShowMore] = useState<boolean>(false);

  const propertyFields = usePropertyFieldsFromSchema(property?.fields, categorySchema?.groups?.find((g) => g.name === "parameters"));
  const priceAndRentalTermsFields = usePropertyFieldsFromSchema(property?.fields, categorySchema?.groups?.find((g) => g.name === "priceAndRentalTerms"));
  const featuresFields = usePropertyFieldsFromSchema(property?.fields, categorySchema?.groups?.find((g) => g.name === "features"));

  const contactFields = useMemo(() => {
    if (!property) return null;
    return [
      {
        label: t('email'),
        value: property.owner.email,
      },
      ...(property.contactPhone ? [{
        label: t('contact_phone_number'),
        value: formatPhoneNumber(property.contactPhone),
      }] : []),
      ...(property.additionalPhone ? [{
        label: t('additional_phone_number'),
        value: formatPhoneNumber(property.additionalPhone),
      }] : []),
    ]
  }, [property]);

  const propertyDescription = useMemo(() => {
    if (!property?.description) return null;
    const descValue = property?.description[language];
    if (!descValue) return null;

    if (propertyShowMoreLength > descValue.length) return descValue;
    return descriptionShowMore ?
        descValue :
      `${descValue.slice(0, propertyShowMoreLength)}...`;
  }, [property, descriptionShowMore, language]);

  const allImages = useMemo(() => {
    if(!property) return null;

    const mainImage = property.mainImage;
    const collateralImages = property.gallery?.map(img => img.image) || []

    return mainImage ? [...[mainImage], ...collateralImages ] : collateralImages;
  }, [property]);

  const publishEnd = new Date(property?.publishEnd || '');
  const today = new Date();
  const daysLeft = (publishEnd.getTime() - today.getTime()) / 86400000;

  const listingItems: {
    label: string;
    value: string;
  }[] = [
    {
      label: t('listing_start_date'),
      value: formatDate(property?.publishStart || '') || '',
    },
    {
      label: t('listing_end_date'),
      value: formatDate(property?.publishEnd || '') || '',
    },
    {
      label: t('days_to_expiration'),
      value: String(daysLeft.toFixed()),
    }
  ]

  return (
    <div className="flex gap-[30px]">
      <div className="flex-1 w-0">
        <div>
          {allImages && allImages.length > 0 ? (
            <PropertyCarousel items={allImages}/>
          ) : (
            <div className="flex-center aspect-[2] bg-[#F8F8F8] rounded-[10px]">
              <NoImageIcon/>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mt-[8px] font-Manrope">
          <div className="flex items-center gap-[6px]">
            <LocationIcon width={24} height={24}/>
            <div className="text-[18px] text-[#808191]">
              {getAddressString(property?.address)}
            </div>
          </div>
          <div>
            <div
              className="px-[9px] py-[7px] min-w-[120px] rounded-[4px] text-[#333333] text-[24px] font-semibold bg-[#006cfb0d] h-[44px] leading-[1.2] flex justify-center items-baseline whitespace-nowrap">
              {property?.price && `Kč ${addPriceDelimiters(property?.price)}`}
              <span className="text-[16px]">{property?.priceType === 'PER_MONTH' ? `/${t('mo')}` : property?.priceType === 'PER_DAY' ? `/${t('day')}` : ''}</span>
            </div>
          </div>
        </div>
        {!!propertyFields?.length && <div className="mt-[23px]">
          <PropertyFieldWrapper
            label={t('parameters')}
            icon={<ParametersIcon/>}
          >
            <div className="mt-[16px]">
              <PropertyFieldsColumn items={propertyFields}/>
            </div>
          </PropertyFieldWrapper>
        </div>}
        {!!propertyDescription?.length && (
          <div className="relative mt-[23px]">
            <PropertyFieldWrapper
              label={t('description')}
              icon={<DescriptionIcon/>}
            >
              <div className="mt-[6px] text-[#666] leading-[1.6]">
                {propertyDescription}

                {propertyDescription.length > propertyShowMoreLength && (
                  <>
                    {" "}
                    <span
                      className="cursor-pointer text-blue"
                      onClick={() => setDescriptionShowMore((s) => !s)}
                    >
                    {descriptionShowMore ?  t('show_less') : t('show_more')}
                  </span>
                  </>
                )}
              </div>
            </PropertyFieldWrapper>
          </div>
        )}
        {!!featuresFields?.length && <div className="mt-[23px]">
          <PropertyFieldWrapper
            label={t('features')}
            icon={<FeatureIcon/>}
          >
            <div className="mt-[18px] line-clamp-3">
              <PropertyFieldsColumn items={featuresFields} />
            </div>
          </PropertyFieldWrapper>
        </div>}
      </div>
      <div className="w-[330px]">
        <div className="flex flex-col gap-[40px]">
          {property?.status === 'ACTIVE' && <PropertyFieldWrapper
            label={t('listing_details')}
            icon={<CalendarIcon />}
          >
            <div className="mt-[18px]">
              <PropertyFieldsColumn items={listingItems}/>
            </div>
          </PropertyFieldWrapper>}
          {!!priceAndRentalTermsFields?.length && <PropertyFieldWrapper
            label={t('price_and_rental_terms')}
            icon={<PriceIcon/>}
          >
            <div className="mt-[18px]">
              <PropertyFieldsColumn items={priceAndRentalTermsFields}/>
            </div>
          </PropertyFieldWrapper>}
          <PropertyFieldWrapper
            label={t('contacts')}
            icon={<ContactsIcon/>}
          >
            <div className="mt-[18px]">
              <div className="flex items-center gap-[10px]">
                {!property?.owner?.avatar ? (
                  <div
                    className="flex-center rounded-[15px] w-[36px] h-[36px] bg-blue text-white">{property?.owner?.name.charAt(0)}</div>
                ) : (
                  <img
                    className="rounded-[15px] w-[36px] h-[36px]"
                    src={property.owner.avatar.url}
                    alt="photo"
                    width={40}
                    height={40}
                  />
                )}
                <div className="font-bold">{property?.owner.name}</div>
              </div>
              {!!contactFields?.length && <div className="mt-[20px]">
                <PropertyFieldsColumn items={contactFields}/>
              </div>}
            </div>
          </PropertyFieldWrapper>
        </div>
      </div>
    </div>
  );
}

export default PropertyPreview;
