import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";

export const getProduct = async () => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get('/product');
  return response.data.data;
}

export const getProductAvailableCount = async () => {
  const response: AxiosResponse<IResponse<any>> = await axiosInstance.get('/product/available/count');
  return response.data.data;
}
