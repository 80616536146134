import { create } from "zustand";

interface IGlobalModalStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: any;
  setChildren: (children: any) => void;
  onClose: () => void;
}

const useGlobalModalStore = create<IGlobalModalStore>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({isOpen})),
  children: null,
  setChildren: (children: any) => set(() => ({children})),
  onClose: () => set(() => ({
    isOpen: false,
    children: null,
  }))
}));

export default useGlobalModalStore;
