import { AxiosResponse } from "axios";

import axiosInstance from "../../axios";

import { IResponse } from "../../../common/types/response";

import { IGetStatisticsProperty } from "./types";

export const getStatisticProperty = async () => {
  const response: AxiosResponse<IResponse<IGetStatisticsProperty>> = await axiosInstance.get('/statistic/property');
  return response.data.data;
}