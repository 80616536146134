import { useTranslation } from 'react-i18next';

import useGlobalModalStore from '../../../../common/stores/useGlobalModalStore';

import ModalTemplate from '../../../../common/components/Modal/ModalTemplate';
import Button from '../../../../common/components/Button/ButtonSite';

import SuccessIcon from '../../../../common/assets/icons/ThumbUpIcon';

const ModalBody = () => {
  const { t } = useTranslation();

  const { onClose } = useGlobalModalStore();

  const handleLoginRedirect = () => {
    onClose();
  };

  return (
    <div className="flex justify-end pt-[20px]">
      <Button onClick={handleLoginRedirect} variant="primary-master" size="small" className="w-[260px]">
        {t('sign_in')}
      </Button>
    </div>
  );
};

const ForgotPasswordSuccessModal = () => {
  const { t } = useTranslation();

  return (
    <ModalTemplate
      icon={<SuccessIcon />}
      title={t('reset_password_success')}
      description={t('reset_password_success_description')}
      body={<ModalBody />}
    />
  );
};

export default ForgotPasswordSuccessModal;
