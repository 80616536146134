import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";

import { ICategorySchemaGroupItem } from "../../../common/types/dto/category";
import { IPropertyFieldSchemaRadio } from "../../../common/types/dto/property";

import Input from "../../../common/components/Input/Input";
import CounterInput from "../../../common/components/CounterInput/CounterInput";
import NumericInput from "../../../common/components/NumericInput/Input";
import PhoneInput from "../../../common/components/PhoneInput/PhoneInput";
import Select from "../../../common/components/Select/Select";
import ButtonsRadio from "../../../common/components/ButtonsRadio/ButtonsRadio";
import Calendar from "../../../common/components/Calendar/Calendar";

import PropertyTagSelect from "./PropertyTagSelect";

interface IPropertyFieldsFormProps {
  schema?: ICategorySchemaGroupItem,
  control?: Control,
  priceType?: string | undefined;
}

const PropertyFieldsForm: FC<IPropertyFieldsFormProps> = ({
                                                            schema,
                                                            control,
                                                            priceType,
                                                          }) => {
  const { t } = useTranslation();

  const radioFields = useMemo(() => schema?.fields?.filter((field) => field.type === "RADIO"), [schema]) as IPropertyFieldSchemaRadio[] | undefined;
  const selectFields = useMemo(() => schema?.fields?.filter((field) => field.type === "SELECT"), [schema]) as IPropertyFieldSchemaRadio[] | undefined;
  const tagSelectFields = useMemo(() => schema?.fields?.filter((field) => field.type === "TAG_SELECT"), [schema]);
  const tagMultiSelectFields = useMemo(() => schema?.fields?.filter((field) => field.type === "TAG_MULTI_SELECT"), [schema]);
  const numberFields = useMemo(() => schema?.fields?.filter((field) => field.type === "NUMBER" && !field.meta?.isCounter), [schema]);
  const counterFields = useMemo(() => schema?.fields?.filter((field) => field.type === "NUMBER" && field.meta?.isCounter), [schema]);
  const stringFields = useMemo(() => schema?.fields?.filter((field) => field.type === "STRING"), [schema]);
  const dateFields = useMemo(() => schema?.fields?.filter((field) => field.type === "DATE"), [schema]);
  const priceLabel = useMemo(() => {
    switch (priceType) {
      case 'PER_MONTH':
        return t('rent');
      case 'PER_DAY':
        return t('rent_daily');
        case 'FULL_PRICE':
          return t('sale');
        default:
          return t('price');
    }
  }, [priceType, t]);

  if (!control) return null;

  return (
    <div className="flex flex-col gap-[35px]">
      <div className="text-[20px] text-[#333] font-semibold">{schema?.label}</div>

      {radioFields && radioFields.length > 0 && (
        <div className="flex flex-col gap-[35px]">
          {radioFields.map((field) => (
            <div key={field.id}>
              <div className="mb-[15px] text-[#666]">{field.label}{!!field.meta?.isRequired && <span className="text-[#C73434]">*</span>}</div>
              <ButtonsRadio control={control} name={field.name} options={field.options}/>
            </div>
          ))}
        </div>
      )}

      {selectFields && selectFields.length > 0 && (
        <div className="grid grid-cols-3 gap-x-[40px] gap-y-[18px]">
          {selectFields.map((field) => (
            <div key={field.id}>
              <div className="text-sm text-[#333] mb-1.5">{field.label}{!!field.meta?.isRequired && <span className="text-[#C73434]">*</span>}</div>
              <Select classNames={{
                container: () => 'w-[540px] h-[50px] rounded-[15px] border border-[#E2E8F0] leading-[18px]',
                control: () => 'w-full h-[50px] p-4',
                menu: () => 'bg-white p-4 pr-1 mt-1 rounded-[15px] border border-[#E2E8F0] ',
                menuList: () => 'max-h-[300px] overflow-auto text-[#666] text-[14px] leading-6',
                singleValue: () => 'text-[#666] text-[14px]'
              }} control={control} name={field.name} options={field.options} unstyled />
            </div>
          ))}
        </div>
      )}

      {counterFields && counterFields.length > 0 && (
        <div className="grid grid-cols-3 gap-x-[80px] gap-y-[18px]">
          {counterFields?.map((field) => (
            <div key={field.id} className="grid grid-cols-[1fr_auto] items-center gap-[20px]">
              <div className="text-[14px] text-[#666] font-medium">{field.label}{!!field.meta?.isRequired && <span className="text-[#C73434]">*</span>}</div>
              <div className="w-[100px]">
                <CounterInput
                  control={control}
                  name={field.name}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {tagSelectFields && tagSelectFields.length > 0 && (
        <div className="flex flex-col gap-[35px]">
          {tagSelectFields.map((field) => (
            <div key={field.id}>
              <div className="mb-[15px] text-[#666]">{field.label}{!!field.meta?.isRequired && <span className="text-[#C73434]">*</span>}</div>
              <PropertyTagSelect control={control} name={field.name} options={field.tags}/>
            </div>
          ))}
        </div>
      )}

      {tagMultiSelectFields && tagMultiSelectFields.length > 0 && (
        <div className="flex flex-col gap-[35px]">
          {tagMultiSelectFields.map((field) => (
            <div key={field.id}>
              <div className="mb-[15px] text-[#666]">{field.label}{!!field.meta?.isRequired && <span className="text-[#C73434]">*</span>}</div>
              <PropertyTagSelect control={control} name={field.name} options={field.tags} multi/>
            </div>
          ))}
        </div>
      )}

      {numberFields && numberFields.length > 0 && (
        <div className="grid grid-cols-3 gap-x-[20px] gap-y-[35px]">
          {numberFields?.map((field) => {
            const isNumeric = field?.meta?.isPrice || !!field?.meta?.unit;
            const Component = isNumeric ? NumericInput : Input;
            return (
              <div key={field.id}>
                <Component
                  type="number"
                  control={control}
                  name={field.name}
                  label={field.label}
                  endUnit={field.meta?.unit}
                  required={!!field.meta?.isRequired}
                />
              </div>
            )
          })}
        </div>
      )}

      {stringFields && stringFields.length > 0 && (
        <div className="grid grid-cols-2 gap-x-[20px] gap-y-[35px]">
          {stringFields?.map((field) => {
            return (
                <div key={field.id}>
                  <Input
                    control={control}
                    name={field.name}
                    label={field.label}
                    required={!!field.meta?.isRequired}
                  />
              </div>
            )
          })}
        </div>
      )}

      {dateFields && dateFields.length > 0 && (
        <div className="grid grid-cols-3 gap-x-[20px] gap-y-[35px]">
          {dateFields.map((field) => (
            <div key={field.id}>
              <div className="mb-[15px] text-[#666]">{field.label}{!!field.meta?.isRequired && <span className="text-[#C73434]">*</span>}</div>
              <div>
                <Calendar
                  control={control}
                  name={field.name}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {schema?.name === 'priceAndRentalTerms' && (
        <>
          <div className="grid grid-cols-3 gap-x-[20px] gap-y-[35px]">
            <NumericInput
              type="number"
              control={control}
              name="price"
              label={priceLabel}
              endUnit="Kč"
              required
            />
          </div>
          <div className="grid grid-cols-3 gap-x-[20px] gap-y-[35px]">
            <PhoneInput
              control={control}
              name="contactPhone"
              label={t('contact_phone_number')}
              defaultCountry="CZ"
              required
            />
            <PhoneInput
              control={control}
              name="additionalPhone"
              label={t('additional_phone_number')}
              defaultCountry="CZ"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PropertyFieldsForm;
