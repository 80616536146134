import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import CheckIcon from "../../../common/assets/icons/CheckIcon";

interface IPasswordStrengthProps {
  password: string;
  setValidPassword?: (validPassword: boolean) => void;
}

const PasswordStrength = ({password, setValidPassword}: IPasswordStrengthProps) => {
  const { t } = useTranslation();

  const checkEightCharacters = useMemo(() => password.length >= 8, [password]);
  const checkOneNumber = useMemo(() => /\d/.test(password), [password]);
  const checkOneUppercase = useMemo(() => /[A-Z]/.test(password), [password]);
  const checkOneLowercase = useMemo(() => /[a-z]/.test(password), [password]);

  const getIconStyles = (check: boolean) => check ? {opacity: 1, color: "#03c15b"} : {opacity: 0.25, color: "#465FF1"};

  useEffect(() => {
    setValidPassword?.(checkEightCharacters && checkEightCharacters && checkOneUppercase && checkOneLowercase);
  }, [setValidPassword, checkEightCharacters, checkOneNumber, checkOneUppercase, checkOneLowercase]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        <CheckIcon
          {...getIconStyles(checkEightCharacters)}
        />
        <div className="text-xs">{t('eight_characters')}</div>
      </div>
      <div className="flex gap-2">
        <CheckIcon
          {...getIconStyles(checkOneNumber)}
        />
        <div className="text-xs">{t('one_digit')}</div>
      </div>
      <div className="flex gap-2">
        <CheckIcon
          {...getIconStyles(checkOneUppercase)}
        />
        <div className="text-xs">{t('one_uppercase_letter')}</div>
      </div>
      <div className="flex gap-2">
        <CheckIcon
          {...getIconStyles(checkOneLowercase)}
        />
        <div className="text-xs">{t('one_lowercase_letter')}</div>
      </div>
    </div>
  );
}

export default PasswordStrength;
