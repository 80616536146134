import React, { FC, useState } from "react";
import { Control, Controller } from "react-hook-form";

interface IPropertyDescriptionInputProps {
  control: Control;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

const PropertyDescriptionInput: FC<IPropertyDescriptionInputProps> = ({ control, name, label, placeholder, required }) => {
  const [language, setLanguage] = useState<"en" | "cz">("cz");

  const getValue = (value: any, language: string) => {
    return value?.[language] ?? "";
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({field}) => (
        <div className='relative flex flex-col'>
          <div className="flex flex-row-reverse justify-between items-center mb-1.5">
            <div className="flex items-center gap-2.5">
              <div
                className={`cursor-pointer text-[14px] font-bold ${language === "cz" ? 'text-[#333]' : 'text-[#006CFB]'}`}
                onClick={() => setLanguage("cz")}
              >
                CZ
              </div>
              <div
                className={`cursor-pointer text-[14px] font-bold ${language === "en" ? 'text-[#333]' : 'text-[#006CFB]'}`}
                onClick={() => setLanguage("en")}
              >
                EN
              </div>
            </div>
            {label && (
              <label htmlFor={name} className="text-sm text-[#333]">
                {label}{!!required && <span className="text-[#C73434]">*</span>}
              </label>
            )}
          </div>
          <textarea
            value={getValue(field.value, language)}
            name={name}
            className="bg-[#f5f5f5] rounded-[8px] px-[16px] pt-[16px] pb-[12px] outline-none text-[14px] h-[162px] resize-none"
            onChange={(event) => {
              const value = field.value;
              if(!value) return;
              value[language] = event.currentTarget.value;
              field.onChange(value);
            }}
            placeholder={placeholder}
          />
        </div>
      )}
    />
  )
}

export default PropertyDescriptionInput;
