import {FC} from "react";
import {ISvgProps} from "../../types/svg";

const CrossIcon: FC<ISvgProps> = ({height, width, color, opacity}) => {

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill={color || "#666666"}
      opacity={opacity || 1}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20499 5.20474C5.41592 4.99407 5.70186 4.87573 5.99999 4.87573C6.29811 4.87573 6.58405 4.99407 6.79499 5.20474L12 10.4097L17.205 5.20474C17.308 5.09421 17.4322 5.00556 17.5702 4.94407C17.7082 4.88258 17.8571 4.84952 18.0082 4.84685C18.1593 4.84419 18.3093 4.87198 18.4494 4.92856C18.5895 4.98514 18.7167 5.06936 18.8235 5.17618C18.9304 5.28301 19.0146 5.41026 19.0712 5.55035C19.1278 5.69043 19.1555 5.84047 19.1529 5.99153C19.1502 6.14258 19.1171 6.29155 19.0557 6.42955C18.9942 6.56755 18.9055 6.69175 18.795 6.79474L13.59 11.9997L18.795 17.2047C18.9055 17.3077 18.9942 17.4319 19.0557 17.5699C19.1171 17.7079 19.1502 17.8569 19.1529 18.008C19.1555 18.159 19.1278 18.3091 19.0712 18.4491C19.0146 18.5892 18.9304 18.7165 18.8235 18.8233C18.7167 18.9301 18.5895 19.0143 18.4494 19.0709C18.3093 19.1275 18.1593 19.1553 18.0082 19.1526C17.8571 19.15 17.7082 19.1169 17.5702 19.0554C17.4322 18.9939 17.308 18.9053 17.205 18.7947L12 13.5897L6.79499 18.7947C6.58172 18.9935 6.29965 19.1016 6.0082 19.0965C5.71675 19.0914 5.43867 18.9733 5.23255 18.7672C5.02643 18.5611 4.90837 18.283 4.90322 17.9915C4.89808 17.7001 5.00627 17.418 5.20499 17.2047L10.41 11.9997L5.20499 6.79474C4.99431 6.5838 4.87598 6.29787 4.87598 5.99974C4.87598 5.70162 4.99431 5.41568 5.20499 5.20474Z"
      />
    </svg>
  );
}

export default CrossIcon;
