import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";

import { getPropertyOwned } from "../../api/services/property/requests";

import { EUserRole } from "../../common/types/dto/user";
import { TPropertyStatus } from "../../common/types/dto/property";
import { ISelectValue } from "../../common/types/select";

import useGlobalLoaderStore from "../../common/stores/useGlobalLoaderStore";
import useGlobalErrorStore from "../../common/stores/useGlobalErrorStore";
import useAuthStore from "../../common/stores/useAuthStore";

import CardWrapper from "../../common/components/CardWrapper/CardWrapper";
import PropertyCard from "../../common/components/PropertyCard/PropertyCard";
import Pagination from "../../common/components/Pagination/Pagination";

import PropertyFilters from "../../features/property/components/PropertyFilters";
import NoProperties from "../../features/property/components/NoProperties";

const PROPERTIES_PER_PAGE = 21;

//todo: refactor pagination (total to component)
const PropertiesPage = () => {
  const [page, setPage] = useState(1);

  const { t } = useTranslation();

  const { user, impersonationToken } = useAuthStore();
  const { setLoader } = useGlobalLoaderStore();
  const { setError } = useGlobalErrorStore();

  const methods = useForm<{
    categoriesIds: string[] | null
    status: ISelectValue<TPropertyStatus> | null
    city: {
      label: string;
      value: string;
    } | null;
  }>({
    defaultValues: {
      categoriesIds: null,
      status: null,
      city: null,
    }
  });

  const { watch } = methods
  const categoriesIds = watch("categoriesIds");
  const status = watch("status");
  const city = watch("city");

  const { data: properties } = useQuery([
    "properties",
      user,
      status,
      city,
      categoriesIds,
      // search.length > 2 ? search : '',
      page
    ], async () => {
      setLoader(true);

      const payload = {
        page: page,
        limit: PROPERTIES_PER_PAGE,
        // ...(search.length > 2 && {search}),
        ...(!!status?.value && {status: status.value}),
        ...(!!city?.value && {city: city.value}),
        ...(!!categoriesIds && {categories: categoriesIds}),
      }

      let data = await getPropertyOwned(payload);

      setLoader(false);
      return data;
    },
    {
      enabled: !!user,
      onError: (error: any) => {
        setError(error.response.data.message);
        setLoader(false);
      }
    });

  useEffect(() => {
    setPage(1);
  }, [status, city, categoriesIds]);

  const totalPages = !!properties?.total && properties.total > properties.limit && Math.ceil(properties.total / PROPERTIES_PER_PAGE);

  return (
    <div className="px-[25px] py-[18px] font-Manrope">
      <FormProvider {...methods}>
        <PropertyFilters properties={properties?.items} />
      </FormProvider>
      <div className="mt-[25px]">
        <CardWrapper>
          {!!properties?.items?.length && properties.items.length > 0 && <div className="flex items-center gap-[10px] mb-[20px]">
            <div className="text-[#11142d] text-[18px] font-semibold">{t("properties")}</div>
            {(user?.role === EUserRole.Agent || impersonationToken) && (
              <Link to="/property/create">
                <div
                  className="flex-center h-[24px] w-[24px] bg-blue rounded-full text-white text-[18px] font-semibold cursor-pointer"
                  title={t("add_property")}>
                  +
                </div>
              </Link>
            )}
          </div>}
          <div>
            {!!properties?.items.length && <div className="grid grid-cols-3 gap-x-[56px] gap-y-[10px]">
              {properties?.items.map((property) => (
                <div key={property.id}>
                  <Link to={`/property/${property.id}`}>
                    <PropertyCard property={property}/>
                  </Link>
                </div>
              ))}
            </div>}
            {properties?.items.length === 0 && <NoProperties />}
          </div>
          {totalPages && <Pagination currentPage={page} totalPages={totalPages} onPageChange={(page) => setPage(page)} />}
        </CardWrapper>
      </div>
    </div>
  );
}

export default PropertiesPage;
